import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useState } from 'react';
import {Form, Input, Button, Badge, Card } from 'antd';
import {GrLounge} from 'react-icons/gr';
import {FaBath} from 'react-icons/fa';
import {IoIosBed} from 'react-icons/io';
import {MdKitchen} from 'react-icons/md';
import Table from 'react-bootstrap/Table'
import GoogleApiWrapper from '../../googlemap/googleMaps'
import { toast, Zoom, Bounce} from 'react-toastify'
import { BarLoader, BeatLoader} from 'react-spinners'
import AOS from 'aos';
import moment from 'moment';
import { Select } from 'antd';


const { Option } = Select;
toast.configure()
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
class RequestParcelPackages extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      data:[],
      items:[],
      price:'',
      loading:false,
      driver:[],
      driverId:'',
      role:'',
      id:''
    }
    this.handleChange = this.handleChange.bind(this);
  }
  submit =(e) =>
  {

    console.log(e.price)
    fetch("/addPrice",{
      method:"post",
      headers:{
        "content-Type":"application/json"
      },
      body:JSON.stringify({
          '_id':this.props.location.state.item._id,
          'price':e.price

      })
    }).then(res=>res.json())
    .then(data=>{  
      console.log(data)
       
      toast.success("Quotation send Successfully",{
        draggable: true,
        hideProgressBar:true,
        transition: Zoom,
        autoClose:7000,
        position: toast.POSITION.TOP_CENTER  
      })
      this.props.history.push('/newRequest')
    })
  }
  boiler =async() =>
  {
    const data1 = await JSON.parse(localStorage.getItem("getmoversuser"))
    console.log(data1.role)
    this.setState({role:data1.role, id:data1._id})
    console.log(this.props.location.state.item.id)
    fetch("/getParcel",{
      method:"post",
      headers:{
        "content-Type":"application/json"
      },
      body:JSON.stringify({
          '_id':this.props.location.state.item.id,

      })
    }).then(res=>res.json())
    .then(data=>{  
      this.setState({
        data:data,
        items:data.items
        
      })
    })
  }

  driverJob=(e) =>
  {

    console.log(e.price)
    fetch("/donejob",{
      method:"post",
      headers:{
        "content-Type":"application/json"
      },
      body:JSON.stringify({
          '_id':this.props.location.state.item._id,
          'status':"Completed"

      })
    }).then(res=>res.json())
    .then(data=>{  
      console.log(data)
      toast.success("Job Status Changed Successfully",{
        draggable: true,
        hideProgressBar:true,
        transition: Zoom,
        autoClose:7000,
        position: toast.POSITION.TOP_CENTER  
      })
      this.props.history.push('/driverNewRequests')
    })
  }
  handleChange(value,key) {
    console.log(`selected ${value}`);
    console.log("Key Value",key.key)
    this.setState({driverId:key.key})
  }
  assignDriver =() =>
  {
    fetch("/assignedToDriver",{
      method:"post",
      headers:{
        "content-Type":"application/json"
      },
      body:JSON.stringify({
        '_id':this.props.location.state.item._id,
          'driverId':this.state.driverId,
          'status':"Assigned"
      })
    }).then((data)=>{
      console.log(data)
      this.setState({loading:true})
      toast.success("Request Assign Successfully",{
        draggable: true,
        hideProgressBar:true,
        transition: Zoom,
        autoClose:7000,
        position: toast.POSITION.TOP_CENTER  
      })
      this.props.history.push('/newRequest')
    })
    .catch((err)=>{
      console.log(err)
    })
  }
  selectDriver=()=>
  {
      fetch('/getApprovedDrivers')
      .then(res=>res.json())
      .then(res2=>{
          this.setState({driver:res2})
          console.log(res2)
      })
  }

  componentDidMount()
  {
    this.boiler()
    this.selectDriver()
  }

  driverApprovedJob=(e) =>
  {

    console.log(e.price)
    fetch("/acceptjob",{
      method:"post",
      headers:{
        "content-Type":"application/json"
      },
      body:JSON.stringify({
          '_id':this.props.location.state.item._id,
          id:this.state.id,
          'status':"Assigned"

      })
    }).then(res=>res.json())
    .then(data=>{  
      console.log(data)
      toast.success("Job Status Changed Successfully",{
        draggable: true,
        hideProgressBar:true,
        transition: Zoom,
        autoClose:7000,
        position: toast.POSITION.TOP_CENTER  
      })
      this.props.history.push('/driverNewRequests')
    })
  }
  
  render ()
      {
        const eightyFivePercentPrice = (this.props.location.state.item.price * 0.80).toFixed(2);
        const displayDriver = this.state.driver.map(item=>{
          return(     
                  <Option key={item._id} value={item.firstName} >{item.firstName}</Option>
          )
        })
        if(this.state.loading===true)
        {
          return(
            <div className="loading">
                <BeatLoader loading
                  size={20}
                  color="#f26c4f" />
                <BarLoader loading 
                css={{display:'block',margin:0}}
                size={40}
                color="#f26c4f"
                />
            </div>
            )
        }
        if(this.state.loading===false)
        {
        const itemsData = this.state.items.map((item,index)=>{
         return(
           <tr class="d-flex">
             <td class="col-1">{index+1}</td>
             <td class="col-2">{item.item}</td>
             <td class="col-1">{item.quantity}</td>
             <td class="col-1">{item.weight}</td>
             <td class="col-1">{item.width}</td>
             <td class="col-1">{item.height}</td>
             <td class="col-1">{item.depth}</td>
             <td class="col-2">{item.lengthIn}</td>
             <td class="col-2">{item.weightIn}</td>
             
           
           </tr> 
         )
        })
        return(
            <>
            <Row className='admin-forms text-6e6e6e'>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <h1 className='page-title'>{this.state.data.category} DETAILS</h1>
                  <hr/>
                </Col>
                
                <Col xs={6} className='request-title pt-3' data-aos="fade-up" data-aos-duration="2000">
                  <h5><span className='font-weight-bold text-orange'>Pickup Location: </span></h5>
                  <p>{this.state.data.pickupLoc}</p>
                  <h5><span className='font-weight-bold text-orange'>Dropoff Location: </span></h5>
                  <p>{this.state.data.dropoffLoc}</p>
                  <h5><span className='font-weight-bold text-orange'>Email: </span></h5>
                  <p>{this.state.data.email}</p>
                  {this.props.location.state.item.status ==="confirm" &&
                  <>
                  <h5><span className='font-weight-bold text-orange'>Request Date: </span></h5>
                    <p>{moment(this.state.data.date).format('DD MMMM YYYY')}</p>
                  </>
                  }
                  <h5><span className='font-weight-bold text-orange'>Category:</span></h5>
                  <p>{this.state.data.category}</p>
                  {this.props.location.state.item.status !=="new" && this.props.location.state.item.status !=="confrim" &&
                      <>
                        <h5><span className='font-weight-bold text-orange'>Price:</span></h5>
                        <p>{eightyFivePercentPrice}</p>
                      </>
                      }
                    {this.props.location.state.item.status ==="Assigned" && this.state.role ==="admin" &&
                      <>
                        <h5><span className='font-weight-bold text-orange'>Assigned To:</span></h5>
                        <p>{this.props.location.state.item.assignedTo.firstName}</p>
                      </>
                      }
                      
                </Col>
                <Col xs={6} className='p-3'>
                  <div className='ok2'>
                    <GoogleApiWrapper/>
                  </div>
                </Col>
                
              </Row>
              
                
              <Row className='p-2' >
                <Col>
                  <br/>
                  <Table hover size="sm" className='mt-3 text-left' className="table admin-table">
                    <thead className='bg-orange text-white'>
                      <tr class="d-flex">
                        <th class="col-1">Sr. No</th>
                        <th class="col-2">Item</th>
                        <th class="col-1">Qty</th>
                        <th class="col-1">Weight</th>
                        <th class="col-1">Width</th>
                        <th class="col-1">Height</th>
                        <th class="col-1">Depth</th>
                        <th class="col-2">Length In</th>
                        <th class="col-2">Weight In</th>
                        
                       
                      </tr>
                    </thead>      
                    <tbody>
                      {itemsData}
                    </tbody>
                  </Table> 

                  {this.props.location.state.item.status ==="confirm" &&
                  <Form className="pt-5 pb-5 pr-3 pl-3" {...layout} onFinish={this.submit} >
                    <Col xs={6} className='request-title pt-3' data-aos="fade-up" data-aos-duration="2000">
                      <h5><span className='font-weight-bold text-orange'>Send Quotation or Final Cost Of Removal: </span></h5>
                      <Form.Item
                      name='price'>
                        <Input style={{width:230,height:50}} />
                      </Form.Item>
                    </Col>
                    <Form.Item >
                      <br/>
                      <Button className="float-right driver-reg-btn" htmlType="submit">Send</Button>
                    </Form.Item> 
                  </Form>
                  }
                  {this.state.role ==="admin" && this.props.location.state.item.status ==="Paid" &&
                        <Form className="pt-5 pb-5 pr-3 pl-3" {...layout} onFinish={this.assignDriver} >
                          <Col xs={6} className='request-title pt-3' data-aos="fade-up" data-aos-duration="2000">
                            <h5><span className='font-weight-bold text-orange'>Choose Driver: </span></h5>
                            <Select defaultValue="Driver" style={{ width: 170, marginLeft:0 }} onChange={this.handleChange}>
                              {displayDriver}
                              </Select>
                          </Col>
                          <Form.Item >
                            <br/>
                            <Button className="float-right driver-reg-btn" htmlType="submit">Send</Button>
                          </Form.Item> 
                        </Form>
                      }
                      {this.state.role !=="admin" && this.props.location.state.item.status ==="Paid" &&
                       <Form className="pt-5 pb-5 pr-3 pl-3" {...layout} onFinish={this.driverApprovedJob} >
                       <Form.Item >
                         <br/>
                         <Button className="float-right driver-reg-btn" htmlType="submit">Accept</Button>
                       </Form.Item> 
                     </Form>
                      }
                       {this.state.role !=="admin" && this.props.location.state.item.status ==="Assigned" &&
                        <Form className="pt-5 pb-5 pr-3 pl-3" {...layout} onFinish={this.driverJob} >
                          <Form.Item >
                            <br/>
                            <Button className="float-right driver-reg-btn" htmlType="submit">Done</Button>
                          </Form.Item> 
                        </Form>
                      }
                </Col>
              </Row>
              
            </>
          )
      }
    }

}

export default RequestParcelPackages;
