import React from 'react';
import HeaderNavbar from './navbar/navbar';
import {BrowserRouter,Route,Router} from 'react-router-dom';
import Home from './home';
import Categories from './categories';
import Furniture from './furniture';
import HomeRemovals from './homeRemovals';
import Vehicle from './vehicles';
import Motorbike from './motorbike';
import Piano from './piano';
import ParcelAndPackages from './parcelAndPackages';
import OfficeRemovals from './officeRemovals';
import VehicleParts from './vehicleParts';
import SpecialistAndAntiques from './specialistAndAntiques';
import EmailPrices from './emailPrices';
import Index from './admin-panel/index' 
import Blog from './blog/blog'
import SinglePost from './blog/single-post'
import AdviceCenter from './advice-center/advice-center'
import AdviceCenterPost from './advice-center/advice-center-post'
import { Switch } from "react-router";
import Login from './admin-panel/login/login'
import AboutUs from './pages/about-us'
import ContactUs from './pages/contact-us'
import DriverRegistration from './driver-registration'
import Footer from './footer';
import GetLocation from './getLocation';
import SurveyPerforma from './surveyPerforma';
import Payment from './payment'
import LoginAdmin from './admin-panel/login/loginAdmin'
import 'antd/dist/antd.css';
class Layout extends React.Component {
  constructor(props) {
    super(props);
    
  }
  
  render ()
    {
      return (
        <>
        <BrowserRouter>
          
            <HeaderNavbar/>
            <Route exact path="/" component={Home}/>
            <Route exact path="/categories" component={Categories}/>
            <Route exact path="/furniture" component={Furniture}/>
            <Route exact path="/home-removals" component={HomeRemovals}/>
            <Route exact path="/vehicle" component={Vehicle}/>
            <Route exact path="/motorbike" component={Motorbike}/>
            <Route exact path="/piano" component={Piano}/>

            <Route exact path="/parcelandpackages" component={ParcelAndPackages}/>
            <Route exact path="/specialist-antiques" component={SpecialistAndAntiques}/>
            <Route exact path="/vehicle-parts" component={VehicleParts}/>
            <Route exact path="/office-removals" component={OfficeRemovals}/>

            <Route exact path="/email-prices" component={EmailPrices} /> 
            <Route exact path="/blog" component={Blog}/>
            <Route exact path="/single-post" component={SinglePost}/>
            <Route exact path="/advice-center" component={AdviceCenter}/>
            <Route exact path="/advice-center-post" component={AdviceCenterPost}/>
            <Route exact path="/driver-registration" component={DriverRegistration}/>
            <Route exact path="/surveyPerforma/:token" component={SurveyPerforma}/>
            <Route exact path='/admin' component={LoginAdmin}/> 

            <Route exact path="/get-location" component={GetLocation}/>
            <Route exact path="/about-us" component={AboutUs}/>
            <Route exact path="/contact-us" component={ContactUs}/>

            <Route exact path="/login" component={Login}/>
            <Route exact path="/newRequest" component={Index}/>
            <Route exact path="/payment/:token" component={Payment}/>
            <Footer/>
          </BrowserRouter>
          
        </>
       );
    }
}

export default Layout;

