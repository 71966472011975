import {Row,Col,Image} from 'react-bootstrap';
import React, { useState } from 'react';
import {Table,Button} from 'antd';
import {FiEye} from 'react-icons/fi';
import {MdDateRange} from 'react-icons/md';
import {FaPhoneAlt,FaPassport} from 'react-icons/fa';
// import Button from 'react-bootstrap/Button'
import {AiOutlineMail} from 'react-icons/ai';
import {IoMdTransgender} from 'react-icons/io';
import moment from 'moment';
import userImage from '../../../img/dummy-license.jpg';
import { toast, Zoom, Bounce} from 'react-toastify'
import { BarLoader, BeatLoader} from 'react-spinners'


toast.configure()
class DriverRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      data:[],
      loading:false
    }
  }
  
  // boiler =() =>
  // {
  //   console.log(this.props.location.state.item.id)
  //   fetch("/getVehicle",{
  //     method:"post",
  //     headers:{
  //       "content-Type":"application/json"
  //     },
  //     body:JSON.stringify({
  //         '_id':this.props.location.state.item.id,

  //     })
  //   }).then(res=>res.json())
  //   .then(data=>{  
  //     this.setState({
  //       data:data,
        
  //     })
  //   })
  // }
  componentDidMount()
  {
    // this.boiler()
    console.log(this.props.location.state.item)
  }
  sendReview(res)
  {
    this.setState({
      loading:true
    })
    fetch("/driverApproval",{
          method:"post",
          headers:{
            "content-Type":"application/json"
          },
          body:JSON.stringify({
              '_id':this.props.location.state.item._id,
              status:res
    
          })
        }).then(res=>res.json())
        .then(data=>{  
         console.log(data)
         toast.success("Status updated Successfully",{
          draggable: true,
          hideProgressBar:true,
          transition: Zoom,
          autoClose:7000,
          position: toast.POSITION.TOP_CENTER  
        })
        this.props.history.push('/newRequest')
        })
  }
  
    render ()
      {
        if(this.state.loading===true)
        {
          return(
            <div className="loading">
                <BeatLoader loading
                  size={20}
                  color="#f26c4f" />
                <BarLoader loading 
                css={{display:'block',margin:0}}
                size={40}
                color="#f26c4f"
                />
            </div>
            )
        }
        if(this.state.loading===false)
        {
        return(
            <>
              <Row className='admin-forms text-6e6e6e'>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <h1 className='page-title'>Driver DETAILS</h1>
                  <hr/>
                  <br/>
                </Col>
                
                <Col xs={12} className='request-title pt-3'>
                <Col xs={6}>
                  <Image className='pointer' src={this.props.location.state.item.driverImage} width='70%' thumbnail/>
                </Col>
                  <br/><br/> <br/>
                  <h5><span className='font-weight-bold text-orange'> {this.props.location.state.item.firstName +" " + this.props.location.state.item.middleName+" " + this.props.location.state.item.surName }</span></h5>
                 
                  {/* email */}
                  <p><AiOutlineMail className='text-orange'/> {this.props.location.state.item.email}</p>
                  {/* gender */}
                  <p><IoMdTransgender className='text-orange'/> {this.props.location.state.item.gender}</p>
                  {/* phone */}
                  <p><FaPhoneAlt className='text-orange'/> {this.props.location.state.item.phoneNo}</p>
                  {/* date of birth */}
                  <p><MdDateRange className='text-orange'/> {moment(this.props.location.state.item.dob).format('DD MMMM YYYY')}</p>
                  
                  
                </Col>
                <Col xs={6} className='request-title'>
                <br/>
                  <h5><span className='font-weight-bold text-orange'>Cnic/Passport No.: </span></h5>
                  <p>{this.props.location.state.item.cnic}</p>
                  <h5><span className='font-weight-bold text-orange'>Legal Identity: </span></h5>
                  <p>{this.props.location.state.item.legalIdentity}</p>
                  {this.props.location.state.item.legalIdentityNum !== null && 
                  <>
                  <h5><span className='font-weight-bold text-orange'>Registration Number: </span></h5>
                  <p>{this.props.location.state.item.legalIdentityNum}</p>
                  </>
                  }
                  <h5><span className='font-weight-bold text-orange'>Address: </span></h5>
                  <p>{this.props.location.state.item.DriverAddress}</p>
                  <h5><span className='font-weight-bold text-orange'>House Name: </span></h5>
                  <p>{this.props.location.state.item.houseName}</p>
                  <h5><span className='font-weight-bold text-orange'>House Number: </span></h5>
                  <p>{this.props.location.state.item.houseNumber}</p>
                  <h5><span className='font-weight-bold text-orange'>First Line Address: </span></h5>
                  <p>{this.props.location.state.item.firstLineAddress}</p>
                  <h5><span className='font-weight-bold text-orange'>city: </span></h5>
                  <p>{this.props.location.state.item.city}</p>
                  <h2 className="driver_registration_title1" style={{marginTop:70,marginBottom:10}}>Company Details:</h2>
                  <h5><span className='font-weight-bold text-orange'>Company Name: </span></h5>
                  <p>{this.props.location.state.item.CompanyName}</p>
                  <h5><span className='font-weight-bold text-orange'>Company Registration: </span></h5>
                  <p>{this.props.location.state.item.CompanyReg}</p>
                  <h5><span className='font-weight-bold text-orange'>Vat Registration: </span></h5>
                  <p>{this.props.location.state.item.VatReg}</p>
                  <h2 className="driver_registration_title1" style={{marginTop:70,marginBottom:10}}>Driving license Details:</h2>
                  <h5><span className='font-weight-bold text-orange'>Driving Liscence Image: </span></h5>
                  <Image className='pointer' src={this.props.location.state.item.licenseImage} width='70%' thumbnail/>
                  <br/><br/> <br/>
                  <h5><span className='font-weight-bold text-orange'>Driving Liscence No.: </span></h5>
                  <p>{this.props.location.state.item.drivingLicesnseNo}</p>
                  <h5><span className='font-weight-bold text-orange'>Liscence Issued Date: </span></h5>
                  <p>{moment(this.props.location.state.item.licenceIssuedDate).format('DD MMMM YYYY')}</p>
                  <h5><span className='font-weight-bold text-orange'>Liscence Expiry Date: </span></h5>
                  <p>{moment(this.props.location.state.item.licenseExpiryDate).format('DD MMMM YYYY')}</p>
                  <h2 className="driver_registration_title1" style={{marginTop:70,marginBottom:10}}>Vehicle Insurance Details:</h2>
                  <h5><span className='font-weight-bold text-orange'>Vehicle Insurance Image: </span></h5>
                  <Image className='pointer' src={this.props.location.state.item.vehicleInsuranceImage} width='70%' thumbnail/>
                  <br/><br/> <br/>
                  <h5><span className='font-weight-bold text-orange'>Insurance Company: </span></h5>
                  <p>{this.props.location.state.item.InsuranceCompany}</p>
                  <h5><span className='font-weight-bold text-orange'>Goods in Transit: </span></h5>
                  <p>{this.props.location.state.item.goodsinTransit}</p>
                  <h5><span className='font-weight-bold text-orange'>Public Liability: </span></h5>
                  <p>{this.props.location.state.item.publicLiability}</p>
                  <h5><span className='font-weight-bold text-orange'>Motor Trade Insurance: </span></h5>
                  <p>{this.props.location.state.item.motorTradeInsurance}</p>
                  <h5><span className='font-weight-bold text-orange'>CMR: </span></h5>
                  <p>{this.props.location.state.item.CMR}</p>
                  <h5><span className='font-weight-bold text-orange'>Insurance Valid From: </span></h5>
                  <p>{moment(this.props.location.state.item.InsuranceValidFrom).format('DD MMMM YYYY')}</p>
                  <h5><span className='font-weight-bold text-orange'>Insurance Expiry Date: </span></h5>
                  <p>{moment(this.props.location.state.item.InsuranceExpiryDate).format('DD MMMM YYYY')}</p>
                  <h2 className="driver_registration_title1" style={{marginTop:70,marginBottom:10}}>MOT Details:</h2>
                  <h5><span className='font-weight-bold text-orange'>MOT Image: </span></h5>
                  <Image className='pointer' src={this.props.location.state.item.motImage} width='70%' thumbnail/>
                  <br/><br/> <br/> 
                  <h5><span className='font-weight-bold text-orange'>MOT Issued Date: </span></h5>
                  <p>{moment(this.props.location.state.item.motIssuedDate).format('DD MMMM YYYY')}</p>
                  <h5><span className='font-weight-bold text-orange'>MOT Expiry Date: </span></h5>
                  <p>{moment(this.props.location.state.item.motExpiryDate).format('DD MMMM YYYY')}</p>
                  <h2 className="driver_registration_title1" style={{marginTop:70,marginBottom:10}}>Bank Details:</h2>
                  <h5><span className='font-weight-bold text-orange'>Bank Name: </span></h5>
                  <p>{this.props.location.state.item.BankName}</p>
                  <h5><span className='font-weight-bold text-orange'>Account Ttile: </span></h5>
                  <p>{this.props.location.state.item.AccountTtile}</p>
                  <h5><span className='font-weight-bold text-orange'>Sort Code: </span></h5>
                  <p>{this.props.location.state.item.SortCode}</p>
                  <h5><span className='font-weight-bold text-orange'>Account Number: </span></h5>
                  <p>{this.props.location.state.item.AccountNumber}</p>

                  
                </Col>
              </Row>
            
              <Row style={{justifyContent:'center'}}>
                <Button onClick={()=>this.sendReview("Approved")} className="float-right driver-reg-btn" style={{height:45,backgroundColor:'#5cb85c',borderRadius:22, fontWeight:'bold'}} >Approved</Button>
                <Button  onClick={()=>this.sendReview("Not Approved")} className="float-right driver-reg-btn" style={{marginLeft:20,height:45,borderRadius:22, backgroundColor:'#d9534f', fontWeight:'bold'}} >Not Approved</Button>
              </Row>
            </>
          )
        }
      }

}

export default DriverRequest;
