import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button'
import 'react-toastify/dist/ReactToastify.css';
import Table from 'react-bootstrap/Table'
import AOS from 'aos';

//import FurnitureData from '../../json/furnitureData';
import { Form, Input} from 'antd';

class SubCategoriesRemovals extends React.Component {
  constructor(props) {
    super(props);
    
   
  }
  
    render ()
      { 
       

      
        return(
          <>
          <Row className='admin-forms'>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h1 className='page-title'>Home Removals</h1>
              <hr/> 
            </Col>
            <Col className='pt-3' >
                <Table hover size="sm" className='text-left' className="table admin-table" data-aos="fade-up" data-aos-duration="2000">
                  <thead className='bg-orange text-white'>
                    <tr class="d-flex">
                      <th class="col-1">Sr. No</th>
                     
                      <th class="col-9">Sub Category</th>
                      
                      <th class="col-2">Action</th>
                    </tr>
                  </thead>      
                
                <tbody>
                <tr class="d-flex">
                    <td class="col-1">9</td>
                    <td class="col-9">Man</td>
                    <td className="col-2 d-flex justify-content-center"><Button  className='table-view-btn' onClick={()=>this.props.history.push('/homeremovals-items',{cat:'man'})}>View</Button></td>
                  </tr>
                  <tr class="d-flex">
                    <td class="col-1">1</td>
                    <td class="col-9">Bedroom</td>
                    <td className="col-2 d-flex justify-content-center"><Button  className='table-view-btn' onClick={()=>this.props.history.push('/homeremovals-items',{cat:'bedroom'})}>View</Button></td>
                  </tr>
                 
                  <tr class="d-flex">
                    <td class="col-1">2</td>
                    <td class="col-9">Kitchen</td>
                    <td className="col-2 d-flex justify-content-center"><Button  className='table-view-btn' onClick={()=>this.props.history.push('/homeremovals-items',{cat:'kitchen'})}>View</Button></td>
                  </tr>
                  <tr class="d-flex">
                    <td class="col-1">3</td>
                    <td class="col-9">Tv Lounge</td>
                    <td className="col-2 d-flex justify-content-center"><Button  className='table-view-btn' onClick={()=>this.props.history.push('/homeremovals-items',{cat:'tvlounge'})}>View</Button></td>
                  </tr>
                  <tr class="d-flex">
                    <td class="col-1">4</td>
                    <td class="col-9">Bathroom</td>
                    <td className="col-2 d-flex justify-content-center"><Button  className='table-view-btn' onClick={()=>this.props.history.push('/homeremovals-items',{cat:'bathroom'})}>View</Button></td>
                  </tr>
                  <tr class="d-flex">
                    <td class="col-1">5</td>
                    <td class="col-9">Garden</td>
                    <td className="col-2 d-flex justify-content-center"><Button  className='table-view-btn' onClick={()=>this.props.history.push('/homeremovals-items',{cat:'garden'})}>View</Button></td>
                  </tr>
                  <tr class="d-flex">
                    <td class="col-1">6</td>
                    <td class="col-9">Dining</td>
                    <td className="col-2 d-flex justify-content-center"><Button  className='table-view-btn' onClick={()=>this.props.history.push('/homeremovals-items',{cat:'dining'})}>View</Button></td>
                  </tr>
                  <tr class="d-flex">
                    <td class="col-1">7</td>
                    <td class="col-9">Office</td>
                    <td className="col-2 d-flex justify-content-center"><Button  className='table-view-btn' onClick={()=>this.props.history.push('/homeremovals-items',{cat:'office'})}>View</Button></td>
                  </tr>
                  <tr class="d-flex">
                    <td class="col-1">8</td>
                    <td class="col-9">Box And Packing</td>
                    <td className="col-2 d-flex justify-content-center"><Button  className='table-view-btn' onClick={()=>this.props.history.push('/homeremovals-items',{cat:'box'})}>View</Button></td>
                  </tr>
                </tbody>
              </Table> 
            </Col>
            
          </Row>
        
        </>
        )
        
     
      
      }

}

export default SubCategoriesRemovals;
