import React, { useState } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { geocodeByAddress,  geocodeByPlaceId,  getLatLng,  } from 'react-places-autocomplete';

  class DropoffLocation extends React.Component
{
    constructor(props) {
        super(props);
        
      }
      state={
       latlng2:'',
       address2:this.props.dropoffLoc,
      
    }

    handleChange2 = address2 => 
    {
        this.setState({ address2 });
    };

    handleSelect2 = address2 =>
    {
        this.setState({ address2 })
        geocodeByAddress(address2)
            .then(results => getLatLng(results[0]))
                .then(latLng => {
                    this.setState({
                        latlng2:latLng
        })  
        console.log('Success',this.state.latlng2)})
        .catch(error => console.error('Error', error));
    };
    componentDidMount()
    {
        console.log("address Display",this.props.address2)
        this.setState({
            address2:this.props.address2
        })
    }
    searchOptions = {
    componentRestrictions: { country: ['uk'] },
   // types: ['address']
    }

    
    render()
    {
       
        return(
            <PlacesAutocomplete 

                value={this.state.address2}
                onChange={this.handleChange2}
                onSelect={this.handleSelect2}
                searchOptions={this.searchOptions}>
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                            <input  {...getInputProps({ placeholder: 'Dropoff Location or Postcode', className: 'location-search-input', })}/>
                                <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                        {suggestions.map(suggestion => 
                                        {
                                            const className = suggestion.active
                                                ? 'suggestion-item--active': 'suggestion-item';
                                                    // inline style for demonstration purpose
                                                    const style = suggestion.active
                                                    ? { backgroundColor: '#ed2232',color:'#fff', cursor: 'pointer' }
                                                    : { backgroundColor: '#00acdd',color:'#fff', cursor: 'pointer' };
                                                    return (
                                                                <div
                                                                    {...getSuggestionItemProps(suggestion, {
                                                                        className, style, })
                                                                    }>
                                                                    <div>{suggestion.description}</div>
                                                                </div>
                                                            );
                                        })}
                                </div>
                        </div>
                    )}
            </PlacesAutocomplete>
        )
    }
}
export default DropoffLocation;