import React from 'react';

 const EventsDetails=[
    { id:'1', date:'2021-04-13', event:'Event'},
    { id:'2', date:'2021-04-15', event:'Event'},
    { id:'3', date:'2021-04-20', event:'Event'},
    { id:'4', date:'2021-05-25', event:'Event'},
    { id:'5', date:'2021-05-27', event:'Event'},
    { id:'6', date:'2021-06-05', event:'Event'},
    { id:'7', date:'2021-07-10', event:'Event'},
  ]
  export default EventsDetails; 