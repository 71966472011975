import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import img from '../img/caroline2.png'
import Image from 'react-bootstrap/Image'
import React, {Component } from 'react';
import { Form, Input, Button, Select} from 'antd';
import {RiArrowRightSLine} from 'react-icons/ri';
import GoogleApiWrapper from './googlemap/googleMaps'
import Modal from 'react-bootstrap/Modal';
import PickupLocation from './googlemap/pickupLocation';
import DropoffLocation from './googlemap/dropoffLocation';

class Vehicle extends Component
{
    constructor(props) 
    {
        super(props);
        this.submit = this.submit.bind(this);
    }
    state=
    {
       
        mileage:"",
        products:[],
        VModels:[],
        brands:[],
        models:[],
        price:'',
        distanceCost:0,
    }
    componentDidMount(){
        fetch('/getDistanceCost')
        .then(res=>res.json())
        .then((data)=>{
            this.setState({
                distanceCost:data.cost
            })
            console.log("Distance Data is ",data.cost)
        })
        fetch('/getCarBrand')
             .then(res=>res.json())
             .then((brands)=>{
                 this.setState({
                     brands:brands
                 })
               
             })
         
       }
    handleShow=()=>this.setState({show:true});

 //array of all car brands
 VehicleBrands =
 [
     {   value:'SelectMakeBrand', label:'Select Make / Brand'  },
     {   value:'Abarth', label:'Abarth'  },
     {   value:'AC', label:'AC'  },
     {   value:'Aixam', label:'Aixam'  },
     {   value:'AlfaRomeo', label:'Alfa Romeo'  },
     {   value:'Ariel', label:'Ariel'  },
     {   value:'ARO', label:'ARO'  },
     {   value:'Asia', label:'Asia'  },
     {   value:'AstonMartin', label:'Aston Martin'  },
     {   value:'Audi', label:'Audi'  },
     {   value:'Austin', label:'Austin'  },
     {   value:'Beaufor', label:'Beaufor'  },
     {   value:'Bently', label:'Bently'  },
     {   value:'BMW', label:'BMW'  },
     {   value:'Bowler', label:'Bowler'  },
     {   value:'Bugatti', label:'Bugatti'  },
     {   value:'Buick', label:'Buick'  },
     {   value:'Cadillac', label:'Cadillac'  },
     {   value:'Caterham', label:'Caterham'  },
     {   value:'Chevrolet', label:'Chevrolet'  },
     {   value:'Chrysler', label:'Chrysler'  },
     {   value:'Citroën', label:'Citroën'  },
     {   value:'Corvette', label:'Corvette'  },
     {   value:'Dacia', label:'Dacia'  },
     {   value:'Daewoo', label:'Daewoo'  },
     {   value:'DAF', label:'DAF'  },
     {   value:'Daihatsu', label:'Daihatsu'  },
     {   value:'Daimler', label:'Daimler'  },
     {   value:'Datsun', label:'Datsun'  },
     {   value:'DeTomaso', label:'De Tomaso'  },
     {   value:'Delorean', label:'Delorean'  },
     {   value:'DFSK', label:'DFSK'  },
     {   value:'Dodge', label:'Dodge'  },
     {   value:'DS', label:'DS'  },
     {   value:'Farbio', label:'Farbio'  },
     {   value:'Ferrari', label:'Ferrari'  },
     {   value:'Fiat', label:'Fiat'  },
     {   value:'Ford', label:'Ford'  },
     {   value:'FSO', label:'FSO'  },
     {   value:'GBS', label:'GBS'  },
     {   value:'Ginetta', label:'Ginetta'  },
     {   value:'GMC', label:'GMC'  },
     {   value:'GreatWall', label:'Great Wall'  },
     {   value:'Hillman', label:'Hillman'  },
     {   value:'Holden', label:'Holden'  },
     {   value:'Honda', label:'Honda'  },
     {   value:'Hummer', label:'Hummer'  },
     {   value:'Hyundai', label:'Hyundai'  },
     {   value:'Infiniti', label:'Infiniti'  },
     {   value:'Innocenti', label:'Innocenti'  },
     {   value:'Isuzu', label:'Isuzu'  },
     {   value:'Iveco', label:'Iveco'  },
     {   value:'Jaguar', label:'Jaguar'  },
     {   value:'Jeep', label:'Jeep'  },
     {   value:'Kia', label:'Kia'  },
     {   value:'Koenigsegg', label:'Koenigsegg'  },
     {   value:'KTM', label:'KTM'  },
     {   value:'Lada', label:'Lada'  },
     {   value:'Lancia', label:'Lancia'  },
     {   value:'LandRover', label:'Land Rover'  },
     {   value:'LDV', label:'LDV'  },
     {   value:'Lexus', label:'Lexus'  },
     {   value:'Lincoln', label:'Lincoln'  },
     {   value:'Lotus', label:'Lotus'  },
     {   value:'MAN', label:'MAN'  },
     {   value:'Marcos', label:'Marcos'  },
     {   value:'Maserati', label:'Maserati'  },
     {   value:'Maybach', label:'Maybach'  },
     {   value:'Mazda', label:'Mazda'  },
     {   value:'McLaren', label:'McLaren'  },
     {   value:'MercedesBenz', label:'Mercedes-Benz'  },
     {   value:'Mercury', label:'Mercury'  },
     {   value:'Merlin', label:'Merlin'  },
     {   value:'MEV', label:'MEV'  },
     {   value:'MG', label:'MG'  },
     {   value:'MGRover', label:'MG Rover'  },
     {   value:'Microcar', label:'Microcar'  },
     {   value:'Mini', label:'Mini'  },
     {   value:'Mitsubishi', label:'Mitsubishi'  },
     {   value:'Mitsuoka', label:'Mitsuoka'  },
     {   value:'Morgan', label:'Morgan'  },
     {   value:'Morris', label:'Morris'  },
     {   value:'Moskwitch', label:'Moskwitch'  },
     {   value:'NG', label:'NG'  },
     {   value:'Nissan', label:'Nissan'  },
     {   value:'Noble', label:'Noble'  },
     {   value:'Opel', label:'Opel'  },
     {   value:'Pagani', label:'Pagani'  },
     {   value:'Peugeot', label:'Peugeot'  },
     {   value:'Piaggio', label:'Piaggio'  },
     {   value:'Pilgrim', label:'Pilgrim'  },
     {   value:'Plymouth', label:'Plymouth'  },
     {   value:'Polaris', label:'Polaris'  },
     {   value:'Pontiac', label:'Pontiac'  },
     {   value:'Porsche', label:'Porsche'  },
     {   value:'RangeRover', label:'Range Rover'  },
     {   value:'Proton', label:'Proton'  },
     {   value:'Reliant', label:'Reliant'  },
     {   value:'Reva', label:'Reva'  },
     {   value:'Rover', label:'Rover'  },
     {   value:'RollsRoyce', label:'Rolls Royce'  },
     {   value:'Saab', label:'Saab'  },
     {   value:'Samsung', label:'Samsung'  },
     {   value:'Santana', label:'Santana'  },
     {   value:'Seat', label:'Seat'  },
     {   value:'Sebring', label:'Sebring'  },
     {   value:'Skoda', label:'Skoda'  },
     {   value:'Smart', label:'Smart'  },
     {   value:'SsangYong', label:'SsangYong'  },
     {   value:'Studebaker', label:'Studebaker'  },
     {   value:'Subaru', label:'Subaru'  },
     {   value:'Suzuki', label:'Suzuki'  },
     {   value:'Talbot', label:'SkoTalbotda'  },
     {   value:'Tesla', label:'Tesla'  },
     {   value:'Tiger', label:'Tiger'  },
     {   value:'Toyota', label:'Toyota'  },
     {   value:'Triumph', label:'Triumph'  },
     {   value:'TVR', label:'TVR'  },
     {   value:'Ultima', label:'Ultima'  },
     {   value:'Vauxhall', label:'Vauxhall'  },
     {   value:'Volkswagen', label:'Volkswagen'  },
     {   value:'Volvo', label:'Volvo'  },
     {   value:'Weismann', label:'Weismann'  },
     {   value:'Westfield', label:'Westfield'  },
     {   value:'ZAZ', label:'ZAZ'  },
     {   value:'Zenos', label:'Zenos'  },
     {   value:'Skoda', label:'Skoda'  },
     {   value:'Skoda', label:'Skoda'  },
 ]
 /*###########################################################
         arrays of car models w.r.t to brand selection
 ############################################################*/
  Abarth =[
     {   label:'124' , value:'124'  },
     {   label:'500' , value:'500'  },
     {   label:'500C' , value:'500C'  },
     {   label:'Grande Punto' , value:'GranePunto'  },
     {   label:'Punto' , value:'Punto'  },
 ]
  AC =[
     {   label:'ACE' , value:'ACE'  },
     {   label:'COBRA' , value:'cobra'  }
 ]
  BMW =
 [
     {   label:'1-Series', value:'1Series'  },
     {   label:'1-Series Cabrio', value:'1SeriesCabrio'  },
     {   label:'1-Series Coupe', value:'1-SeriesCoupe'  },
     {   label:'2-Series Active Tourer', value:'2SeriesActiveTourer'  },
     {   label:'2-Series Coupe', value:'2SeriesCoupe'  },
     {   label:'2-Series Gran Tourer', value:'2SeriesGranTourer '  },
     {   label:'3-Series', value:'3Series'  },
     {   label:'3-Series Cabrio ', value:'3-riesCabrio'  },
     {   label:'3-Series Compact', value:'3SeriesCompact'  },
     {   label:'3-Series Coupe', value:'3SeriesCoupe'  },
     {   label:'3-Series Gran Turismo', value:'3SeriesGranTurismo'  },
     {   label:'3-Series Touring', value:'3SeriesTouring'  },
     {   label:'4-Series Cabrio', value:'4SeriesCabrio'  },
     {   label:'4-Series Coupe', value:'4SeriesCoupe'  },
     {   label:'4-Series Gran Coupe', value:'4SeriesGranCoupe'  },
     {   label:'5-Series', value:'5Series'  },
     {   label:'5-Series Gran Turismo', value:'5SeriesGranTurismo'  },
     {   label:'5-Series Touring', value:'5SeriesTouring'  },
     {   label:'6-Series', value:'6Series'  },
     {   label:'6-Series Cabrio', value:'6SeriesCabrio'  },
     {   label:'6-Series Coupe', value:'6SeriesCoupe'  },
     {   label:'6-Series Gran Coupe', value:'6Series GranCoupe'  },
     {   label:'7-Series', value:'7Series'  },
     {   label:'7-Series L', value:'7SeriesL'  },
     {   label:'8-Series', value:'8Series'  },
     {   label:'i3', value:'i3'  },
     {   label:'i8', value:'i8'  },
     {   label:'X1', value:'X1'  },
     {   label:'X3', value:'X3'  },
     {   label:'X4', value:'X4'  },
     {   label:'X5', value:'X5'  },
     {   label:'X6', value:'X6'  },
     {   label:'Z1', value:'Z1'  },
     {   label:'Z3 Coupe', value:'Z3Coupe'  },
     {   label:'Z3 Roadster', value:'Z3Roadster'  },
     {   label:'Z4', value:'Z4'  },
     {   label:'Z4 Coupe', value:'Z4Coupe'  },
     {   label:'Z4 Roadster', value:'Z4Roadster'  },
     {   label:'Z8', value:'Z8'  }
 ]
 /*###########################################################
         arrays of Enter Vehicle Manually
 ############################################################*/
  VehicleManually = [
     {   label:'Hatchback',value:'Hatchback'   },
     {   label:'Saloon',value:'Saloon'   },
     {   label:'Sports',value:'Sports'   },
     {   label:'Estate',value:'Estate'   },
     {   label:'4x4',value:'4x4'   },
     {   label:'People-Carrier',value:'PeopleCarrier'   },
     {   label:'Compact Van',value:'CompactVan'   },
     {   label:'Transit Van',value:'TransitVan'   },
     {   label:'LWB Transit',value:'LWBTransit'   },
     {   label:'Luton Van',value:'LutonVan'   },
     {   label:'Kit Car',value:'KitCar'   },
     {   label:'Large Saloon',value:'LargeSaloon'   },
     {   label:'Classic Car',value:'ClassicCar'   },
     {   label:'Ride on Lawn Mower',value:'RideOnLawnMower'   },
     {   label:'Golf Buggy',value:'GolfBuggy'   }
 ]

 //disable brand model dropdown if index[0] of make / brand is selected
 handleChange1=(item)=>{
    fetch("/getCar",{
        method:"post",
        headers:{
          "content-Type":"application/json"
        },
        body:JSON.stringify({
          name:item
        })
      }).then(res=>res.json())
      .then((model)=>{
         
          this.setState({
            models:model
          })
      })
 }

 handleChange=(item)=>{
    switch (item) {
        case 'SelectMakeBrand':
            this.setState({DisableModel:false});
            break;
        case 'Abarth':
            this.setState({VModels:this.Abarth});
            this.setState({DisableModel:false});
            break;
        case 'AC':
            this.setState({VModels:this.AC});
            this.setState({DisableModel:false});     
            break;
        case 'BMW':
           
            this.setState({BMW:this.BMW});
            this.setState({DisableModel:false});  
            break;
        default:
            this.setState({DisableModel:true});  
            break;
    }   
}
 

   

submit(e)
{
    const {brand,model}=e
    console.log(model)
    this.state.models.map((item)=>{
        if(item._id===model)
        {
            console.log(item.model)
            this.setState({
                model:item.model,
                price:item.price
            })
            
        }
    })
    this.props.history.push('/email-prices',
         {
            category:this.props.location.state.category,
            pickupLoc:this.props.location.state.pickupLoc, 
            dropoffLoc:this.props.location.state.dropoffLoc,
            lat1:this.props.location.state.lat1,
            lng1:this.props.location.state.lng1,
            lat2:this.props.location.state.lat2,
            lng2:this.props.location.state.lng2,
            brand:brand,
            model:this.state.model,
            price:this.state.price,
            mileage:this.state.mileage,
            distanceCost:this.state.distanceCost
         })
}
handleMile= (val) =>{
    const miles = val.replace("mi", "");
    this.setState({ 
        mileage:miles + " miles"
    })
    console.log("Value from Furni", val)
}
    render()
    {     
        const layout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
          };
          
        return(
                <>
                <Container className="mt-5 mb-5">
                   <br/>
                    <Row className="mt-5"> 
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <h2 className="title1 text-center">Cars & Vehicles</h2>
                        </Col>
                        <Col  xs={12} sm={12} md={8} lg={8} xl={8}>
                            <Form {...layout} onFinish={this.submit} >
                                <Row>
                                <Col className='pt-3' xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Image alt="bike"  src={require('../img/car1.jpg')} width="100%"/>
                                </Col>
                                    <Col  xs={12} sm={12} md={8} lg={8} xl={8}>
                                        <Form.Item 
                                            name="brand"
                                            label='Please Select Your Vahicle Brand to move'
                                            rules={[{ required: true, message: 'Please select Vehicle Brand' }]} >
                                                <Select placeholder="Select Make / Brand" onChange={this.handleChange1}>
                                                    {
                                                        this.state.brands.map(x=>
                                                        <Select.Option value={x.name} className="movingItemsDropdown">{x.name}</Select.Option>
                                                        )
                                                    }
                                                </Select>
                                            </Form.Item>
                                            <Form.Item 
                                                name="model"
                                                label='Please Select Your Vehicle Model to move'
                                                rules={[{ required: true, message: 'Please select Vehicle Model' }]} >
                                                <Select placeholder="Select Model" disabled={this.state.DisableModel}>
                                                    {
                                                    this.state.models.map(x=>
                                                    <Select.Option  value={x._id} className="movingItemsDropdown">{x.model}</Select.Option>
                                                        )
                                                    }
                                                </Select>
                                            </Form.Item>
                                        <Button type="submit" htmlType="submit" size="large" className="float-right driver-reg-btn" >Next</Button>
                                    </Col>
                                </Row>
                            </Form>
                            <div
                style={{ marginTop: "10px" }}
                className="p-2 text-center shadow-sm bg-orange"
              >
                {this.state.mileage && (
                  <h4 className="text-light">Distance: {this.state.mileage}</h4>
                )}
              </div>
                        </Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                <GoogleApiWrapper 
                                    lat1={this.props.location.state.lat1}
                                    lng1={this.props.location.state.lng1}
                                    lat2={this.props.location.state.lat2}
                                    lng2={this.props.location.state.lng2}
                                    handleMile={this.handleMile}
                                    />
                            </Col>
                    </Row>
                </Container>
                
                </>
        
        );
    }
}
export default Vehicle;

