import React, { useState } from "react";
import { Form, Input, Button,Select,DatePicker,Space  } from 'antd';
import {Row,Col,Container} from 'react-bootstrap';
import { Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { toast, Zoom, Bounce} from 'react-toastify'
import { BarLoader, BeatLoader} from 'react-spinners'
import AOS from 'aos';
import { storage } from '../components/config'
import PlacesAutocomplete from 'react-places-autocomplete';
import { geocodeByAddress,  geocodeByPlaceId,  getLatLng,  } from 'react-places-autocomplete';
import { Alert } from 'antd';

toast.configure()
class DriverRegistration extends React.Component {
  constructor(props) {
    super(props);
    AOS.init();
    
  }
  state = {
    loading: false,
    loading1: false,
    imageUrl:'',
    imageUrl1:'',
    imageUrl2:'',
    imageUrl3:'',
    disable:false,
    error:"",
    latlng1:'',
    address1:'',
    validAge:0,
    validLicense:0,
    expireLicense:0,
    expireMot:0,
    validMot:0,
    expireInsurance:0,
    validInsurance:0,
    legalIdCheck:''
    
  };

  handleChangeDriver1 = address1 => 
  {
      this.setState({ address1 });
  };

  handleSelect1 = address1 =>
  {
      this.setState({ address1 });
      geocodeByAddress(address1)
          .then(results => getLatLng(results[0]))
              .then(latLng => {
                  this.setState({
                      latlng1:latLng,
                       
      }) 
     })
      .catch(error => console.error('Error', error));
  };

  searchOptions = {
  componentRestrictions: { country: ['uk'] },
  //types: ['address']
  }

   getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }
   beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  handleChange = e => {
    if (e.file.originFileObj) {

      // console.log(type)
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          console.log(reader.result)
        }
      }
      reader.readAsDataURL(e.file.originFileObj)
      const image = e.file.originFileObj

      this.setState({loading:true})

      this.uploadToFirebase(image,"Lic")
    }
  }
  handleChangeVeh = e => {
    if (e.file.originFileObj) {

      // console.log(type)
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          console.log(reader.result)
        }
      }
      reader.readAsDataURL(e.file.originFileObj)
      const image = e.file.originFileObj

      this.setState({loading:true})

      this.uploadToFirebase(image, "Veh")
    }
  }

  handleChangeProfile = e => {
    if (e.file.originFileObj) {

      // console.log(type)
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          console.log(reader.result)
        }
      }
      reader.readAsDataURL(e.file.originFileObj)
      const image = e.file.originFileObj

      this.setState({loading:true})

      this.uploadToFirebase(image, "Profile")
    }
  }
  handleChangeMOT = e => {
    if (e.file.originFileObj) {

      // console.log(type)
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          console.log(reader.result)
        }
      }
      reader.readAsDataURL(e.file.originFileObj)
      const image = e.file.originFileObj

      this.setState({loading:true})

      this.uploadToFirebase(image,"MOT")
    }
  }
  uploadToFirebase = (imageUp,vari) => {

    const uploadTask = storage.ref(`images/${imageUp.name}`).put(imageUp);

    uploadTask.on(
      "state_changed",
      snapshot => { },
      error => {
        console.log(error)
      },
      () => {
        storage
          .ref("images")
          .child(imageUp.name)
          .getDownloadURL()
          .then(url => {
            console.log(url)
            if(vari==="Lic")
            {
            this.setState({
              imageUrl:url,
              loading:false,
              disable: false,
            })
            }
            if(vari==="Veh")
            {
              this.setState({
                imageUrl1:url,
                loading:false,
                disable: false,
              })
            }
            if(vari==="MOT")
            {
              this.setState({
                imageUrl2:url,
                loading:false,
                disable: false,
              })
            }
            if(vari==="Profile")
            {
              this.setState({
                imageUrl3:url,
                loading:false,
                disable: false,
              })
            }
          })
      }
    )

  };
  submit=(e)=>{
    // if(this.state.validAge===0 && this.state.expireMot===0 && this.state.validMot===0 && this.state.expireInsurance===0 && this.state.validInsurance===0 && this.state.expireLicense===0 && this.state.validLicense===0 && this.state.address1!=='')
    // {
      console.log("Idr ha data", e)
    this.setState({
      loading1:true
    })
      const {firstName,middleName,surName,houseName,houseNumber,firstLineAddress,city,gender,email,legalIdentity,SortCode,AccountNumber,InsuranceCompany,InsuranceValidFrom,BankName,AccountTtile,InsuranceExpiryDate,CompanyName,liscenceNumber,CompanyReg,VatReg,phone,cnicNumber,dob,liscenceIssuedDate,liscenceExpiryDate,motExpiryDate,motIssuedDate,goodsinTransit,publicLiability,motorTradeInsurance,CMR,legalIdentityNum,transitValidFrom,transitExpiryDate} = e;
      fetch("/addDriver",{
        method:"post",
        headers:{
          "content-Type":"application/json"
        },
        body:JSON.stringify({
            firstName,
            middleName,
            surName,
            houseName,
            houseNumber,
            firstLineAddress,
            city,
            email,
            cnic:cnicNumber,
            legalIdentity,
            gender,
            phoneNo:phone,
            drivingLicesnseNo:liscenceNumber,
            dob,
            licenceIssuedDate:liscenceIssuedDate,
            licenseExpiryDate:liscenceExpiryDate,
            licenseImage:this.state.imageUrl,
            motImage:this.state.imageUrl2,
            vehicleInsuranceImage:this.state.imageUrl1,
            DriverAddress:this.state.address1,
            // CompanyName,
            motIssuedDate,
            motExpiryDate,
            InsuranceCompany,
            InsuranceValidFrom,
            InsuranceExpiryDate,
            CompanyReg,
            BankName,
            AccountTtile,
            SortCode,
            VatReg,
            latlng:this.state.latlng1,
            AccountNumber,
            status:"verification",
            goodsinTransit,
            transitValidFrom,
            transitExpiryDate,
            // publicLiability,
            // motorTradeInsurance,
            // CMR,
            driverImage:this.state.imageUrl3,
            // legalIdentityNum
  
        })
      }).then(res=>res.json())
      .then(data=>{  
        console.log(data)
        this.props.history.push('/')
          toast.success("Request submitted",{
            draggable: true,
            hideProgressBar:true,
            transition: Zoom,
            autoClose:7000,
            position: toast.POSITION.TOP_CENTER  
          })
      })
      .catch((err)=>
      {
        console.log(err)
      })

  }

  handleChange1 = address1 => 
  {
    this.setState({ address1 });
  };

  calculate_age = (dob1) => {
    var today = new Date();
    var birthDate = new Date(dob1);  // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
    {
        age_now--;
    }
    console.log(age_now);
    if(age_now>17)
    {
      this.setState({
        validAge:0,
      })
    }
    else
    {
      this.setState({
      validAge:1
      })
    }
  }

  calculate_issueDate = (issueDate,type) =>{
    var today = new Date();
    var birthDate = new Date(issueDate);
    if(today.getTime() >= birthDate.getTime())
    {
      console.log("hello")
      if(type==="license")
      {
        this.setState({
          validLicense:0
          })
      }
      if(type==="mot")
      {
        this.setState({
          validMot:0
          })
      }
      if(type==="insurance")
      {
        this.setState({
          validInsurance:0
          })
      }
      
    }
    else
    {
      console.log("Bye")
      if(type==="license")
      {
        this.setState({
          validLicense:1
          })
      }
      if(type==="mot")
      {
        this.setState({
          validMot:1
          })
      }
      if(type==="insurance")
      {
        this.setState({
          validInsurance:1
          })
      }
    }
  }

  calculate_expireDate = (issueDate,type) =>{
    var today = new Date();
    var birthDate = new Date(issueDate);
    if(today.getTime() < birthDate.getTime())
    {
      console.log("hello")
      if(type==="license")
      {
        this.setState({
          expireLicense:0
          })
      }
      if(type==="mot")
      {
        this.setState({
          expireMot:0
          })
      }
      if(type==="insurance")
      {
        this.setState({
          expireInsurance:0
          })
      }
    }
    else
    {
      console.log("Bye")
      if(type==="license")
      {
        this.setState({
          expireLicense:1
          })
      }
      if(type==="mot")
      {
        this.setState({
          expireMot:1
          })
      }
      if(type==="insurance")
      {
        this.setState({
          expireInsurance:1
          })
      }
    }
  }
  legalIdentitycheck = (val) =>{
    this.setState({
      legalIdCheck:val
    })
  }

    render ()
      { 
        const layout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
          };
          const { Option } = Select;
          const { loading, imageUrl,imageUrl1,imageUrl2,imageUrl3 } = this.state;

          const uploadButton = (
            <div>
              {loading ? <LoadingOutlined /> : <PlusOutlined />}
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          );
          if(this.state.loading1===true)
          {
            return(
            <div className="loading">
                <BeatLoader loading
                  size={20}
                  color="#f26c4f" />
                <BarLoader loading 
                css={{display:'block',margin:0}}
                size={40}
                color="#f26c4f"
                />
            </div>
            )
          }
          if(this.state.loading1===false)
          {
          return(
            <>
                <Container className='pt-5 pb-5 driver-registration mt-5'>
                
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <h2 className="title1">Driver registration</h2><br/><br/>
                        </Col>
                        
                        <Col data-aos="fade-up" data-aos-duration="3000" xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Form {...layout} 
                           onFinish={this.submit}>
                                <Row>
                                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <h3 className="driver_registration_title">Personal Info:</h3>
                                    <hr/>
                                  </Col>
                                  <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Form.Item  name='firstName' label="Fist Name:" rules={[{ required: true, message: 'Please input your first name' }]}>
                                        <Input />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Form.Item  name='middleName' label="Middle Name:" rules={[{ required: false, message: 'Please input your middle name' }]}>
                                        <Input />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Form.Item  name='surName' label="Surname:" rules={[{ required: true, message: 'Please input your surname' }]}>
                                        <Input />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Form.Item name="gender" label="Gender" rules={[{ required: true }]}>
                                      <Select allowClear>
                                        <Option value="male" className="movingItemsDropdown">Male</Option>
                                        <Option value="female" className="movingItemsDropdown">Female</Option>
                                        <Option value="other" className="movingItemsDropdown">other</Option>
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                  <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Form.Item name="email" label="E-mail"
                                            rules={[
                                            {
                                                type: 'email',
                                                message: 'The input is not valid E-mail!',
                                            },
                                            {
                                                required: true,
                                                message: 'Please input your E-mail!',
                                            },
                                            ]}
                                        >
                                      <Input />
                                      </Form.Item>
                                  </Col>
                                  <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Form.Item name="phone" label="Phone Number" rules={[{ required: true, message: 'Please input your phone number!' }]}>
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Form.Item label="CNIC / Passport No." name='cnicNumber' rules={[{ required: true, message: 'Please input your CNIC / Passport No.' }]}>
                                      <Input />
                                    </Form.Item>
                                  </Col>
{/*                                   
                                  {this.state.legalIdCheck==="Limited Company" &&
                                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Form.Item label="Company Registration Number" name='legalIdentityNum' rules={[{ required: true, message: 'Please input your Legal Identity' }]}>
                                      <Input placeholder="Please enter your Legal Identity" />
                                    </Form.Item>
                                  </Col>
                                  } */}

                                  <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Form.Item  name='houseName' label="House Name/Number:" rules={[{ required: false, message: 'Please input your house name' }]}>
                                        <Input  />
                                    </Form.Item>
                                  </Col>

                                  <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                  <Form.Item label="First Line Of Address" name='DriverAddress'>
                                    <PlacesAutocomplete
                                        value={this.state.address1}
                                        onChange={this.handleChange1}
                                        onSelect={this.handleSelect1}
                                        searchOptions={this.searchOptions}>
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div>
                                                    <input {...getInputProps({   className: 'location-search-input', })} value={this.state.address1} required/>
                                                        <div className="autocomplete-dropdown-container">
                                                            {loading && <div>Loading...</div>}
                                                                {suggestions.map(suggestion => 
                                                                {
                                                                    const className = suggestion.active
                                                                        ? 'suggestion-item--active': 'suggestion-item';
                                                                            // inline style for demonstration purpose
                                                                            const style = suggestion.active
                                                                            ? { backgroundColor: '#f26c4f',color:'#fff', cursor: 'pointer' }
                                                                            : { backgroundColor: '#275981',color:'#fff', cursor: 'pointer' };
                                                                            return (
                                                                                        <div
                                                                                            {...getSuggestionItemProps(suggestion, {
                                                                                                className, style, })
                                                                                            }>
                                                                                                
                                                                                                <div>{suggestion.description}</div>
                                                                                        </div>
                                                                                    );
                                                                })}
                                                        </div>
                                                </div>
                                            )}
                                    </PlacesAutocomplete>
                                    </Form.Item>
                                  </Col>

                                  <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Form.Item  name='city' label="City:" rules={[{ required: true, message: 'Please input your city' }]}>
                                        <Input />
                                    </Form.Item>
                                  </Col>

                                  <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Form.Item  name='postalCode' label="Postal Code:" rules={[{ required: true, message: 'Please input your house number' }]}>
                                        <Input />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Form.Item  name='post Code' label="Post Code:" rules={[{ required: true, message: 'Please input your post code' }]}>
                                        <Input />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                                  <Space size={24}>
                                    <Form.Item label="Date of Birth" name='dob' rules={[{ required: true, message: 'Please input your Date of Birth' }]}>
                                      <DatePicker onChange={(val)=>{this.calculate_age(val)}} />
                                    </Form.Item>
                                    </Space>
                                  </Col>
                                 
                                </Row>
                                {this.state.validAge===1 && 
                                  <Alert message="Your age is under 18" type="error" showIcon />
                                }
                                 <Row>
                                <Col  xs={12} sm={6} md={4} lg={4} xl={4}>
                                    <Form.Item label="Your photo" name='yourPicture'
                                    rules={[{ required: true, message: 'Please upload your photo' }]}>
                                    <Upload 
                                        name="yourPicture"
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        name="file"
                                        accept="image/*"
                                        action={``}
                                        // beforeUpload={this.beforeUpload}
                                        onChange={this.handleChangeProfile}
                                        rules={[{ required: true, message: 'Please your image' }]}>
                                   
                                        {imageUrl3 ? <img src={imageUrl3} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                    </Upload>
                                    </Form.Item>
                                    </Col>
                                    </Row> 
                                 <Row>
                                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <br/>
                                    <h3 className="driver_registration_title">Company Details:</h3>
                                    <hr/>
                                  </Col>
                                  {/* <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Form.Item  name='CompanyName' label="Company / Partnership" rules={[{ required: true, message: 'Please input your Company / Partnership' }]}>
                                        <Input placeholder="Please enter your Company / Partnership" />
                                    </Form.Item>
                                  </Col> */}
                                  <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Form.Item label="Legal Identity" name='legalIdentity' rules={[{ required: true, message: 'Please input your Legal Identity' }]}>
                                      {/* <Input placeholder="Please enter your Legal Identity" /> */}
                                      <Select onChange={(val)=>{this.legalIdentitycheck(val)}} allowClear>
                                        <Option value="Sole Trader" className="movingItemsDropdown">Sole Trader</Option>
                                        <Option value="Limited Company" className="movingItemsDropdown">Limited Company</Option>
                                        <Option value="Partnership" className="movingItemsDropdown">Partnership</Option>
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                  <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Form.Item  name='CompanyReg' label="Company Registration #" rules={[{ required: true, message: 'Please input your Company Registration #' }]}>
                                        <Input maxLength={8}  />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Form.Item  name='VatReg' label="VAT Registration #" rules={[{ required: true, message: 'Please input your VAT Registration #' }]}>
                                        <Input maxLength={12} />
                                    </Form.Item>
                                  </Col>
                                </Row>  
                                <Row>
                                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <br/>
                                    <h3 className="driver_registration_title">Liscence Details:</h3>
                                    <hr/>
                                  </Col>
                                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Form.Item label="Driving Liscence No." name='liscenceNumber' 
                                            rules={[{ required: true, message: 'Please input your Driving Liscence No.' }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                  <Col xs={12} sm={6} md={2} lg={2} xl={2}>
                                        <Form.Item label="Liscence Issued Date" name='liscenceIssuedDate'
                                            rules={[{ required: true, message: 'Please input your Liscence Issued Date' }]}>
                                            <DatePicker onChange={(val)=>{this.calculate_issueDate(val,"license")}} />
                                        </Form.Item>
                                        {this.state.validLicense===1 && 
                                          <Alert message="Invalid issue date" type="error" showIcon />
                                        }
                                    </Col>
                                    <Col xs={12} sm={6} md={2} lg={2} xl={2}>
                                        <Form.Item label="Liscence Expiry Date" name='liscenceExpiryDate'
                                            rules={[{ required: true, message: 'Please input your Liscence Expiry Date' }]}>
                                            <DatePicker onChange={(val)=>{this.calculate_expireDate(val,"license")}} />
                                        </Form.Item>
                                        {this.state.expireLicense===1 && 
                                          <Alert message="Invalid expire date" type="error" showIcon />
                                        }
                                    </Col>
                                     <Col  xs={12} sm={6} md={2} lg={2} xl={2}>
                                    <Form.Item label="License photo" name='picture'
                                    rules={[{ required: true, message: 'Please upload your photo' }]}>
                                    <Upload 
                                        name="picture"
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        name="file"
                                        accept="image/*"
                                        action={``}
                                        // beforeUpload={this.beforeUpload}
                                        onChange={this.handleChange}
                                        rules={[{ required: true, message: 'Please your image' }]}>
                                   
                                        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                    </Upload>
                                    </Form.Item>
                                    </Col> 
                                    
                                </Row>

                                <Row>
                                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <br/>
                                    <h3 className="driver_registration_title">MOT:</h3>
                                    <hr/>
                                  </Col>
                                  
                                  <Col xs={12} sm={6} md={2} lg={2} xl={2}>
                                        <Form.Item label="MOT Issued Date" name='motIssuedDate'
                                            rules={[{ required: true, message: 'Please input your Liscence Issued Date' }]}>
                                            <DatePicker onChange={(val)=>{this.calculate_issueDate(val,"mot")}} />
                                        </Form.Item>
                                        {this.state.validMot===1 && 
                                          <Alert message="Invalid issue date" type="error" showIcon />
                                        }
                                    </Col>
                                    <Col xs={12} sm={6} md={2} lg={2} xl={2}>
                                        <Form.Item label="MOT Expiry Date" name='motExpiryDate'
                                            rules={[{ required: true, message: 'Please input your Liscence Expiry Date' }]}>
                                            <DatePicker onChange={(val)=>{this.calculate_expireDate(val,"mot")}} />
                                        </Form.Item>
                                        {this.state.expireMot===1 && 
                                          <Alert message="Invalid expire date" type="error" showIcon />
                                        }
                                    </Col>
                                     <Col  xs={12} sm={6} md={2} lg={2} xl={2}>
                                    <Form.Item label="MOT photo" name='mot-picture'
                                    rules={[{ required: true, message: 'Please upload your photo' }]}>
                                    <Upload 
                                        name="picture"
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        name="file"
                                        accept="image/*"
                                        action={``}
                                        // beforeUpload={this.beforeUpload}
                                        onChange={this.handleChangeMOT}
                                        rules={[{ required: true, message: 'Please your image' }]}>
                                   
                                        {imageUrl2 ? <img src={imageUrl2} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                    </Upload>
                                    </Form.Item>
                                    </Col> 
                                    
                                </Row>

                                <Row>
                                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    
                                    <h3 className="driver_registration_title">Vehicle Insurance:</h3>
                                    <hr/>
                                  </Col>
                                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Form.Item label="Insurance Company" name='InsuranceCompany' 
                                            rules={[{ required: true, message: 'Please input your Insurance Company' }]}>
                                            <Input />
                                        </Form.Item>
                                        
                                    </Col>
                                    
                                    {/* <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Form.Item label="Goods in Transit" name='goodsinTransit' 
                                            rules={[{ required: true, message: 'Invalid Data'}, 
                                            //validator:(rule, value, callback)=>{if(value<=10000){callback('Price is very low')}} }
                                            ({ getFieldValue }) => ({
                                              validator(_, value) {
                                                if (value>=10000) {
                                                  return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('Price is very low'));
                                              },
                                            }),
                                            ]}>
                                            <Input placeholder="Please enter your Goods in Transit" />
                                        </Form.Item>
                                        
                                    </Col> */}

                                    {/* <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Form.Item label="Public Liability" name='publicLiability' 
                                            rules={[{ required: true, message: 'Invalid Data'}, 
                                            //validator:(rule, value, callback)=>{if(value<=1000000){callback('Price is very low')}} 
                                            ({ getFieldValue }) => ({
                                              validator(_, value) {
                                                if (value>=1000000) {
                                                  return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('Price is very low'));
                                              },
                                            }),
                                            ]}>
                                            <Input placeholder="Please enter your Public Liability" />
                                        </Form.Item>
                                        
                                    </Col> */}

                                    {/* <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Form.Item label="Motor Trade Insurance" name='motorTradeInsurance'>
                                            <Input placeholder="Please enter your Motor Trade Insurance" />
                                        </Form.Item>
                                        
                                    </Col> */}

                                    {/* <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Form.Item label="CMR" name='CMR' 
                                            rules={[{ required: true, message: 'Invalid Value'},
                                            // , validator:(rule, value, callback)=>{if(value<=500000)
                                            ({ getFieldValue }) => ({
                                              validator(_, value) {
                                                if (value>=500000) {
                                                  return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('Price is very low'));
                                              },
                                            }),
                                            ]}>
                                            <Input placeholder="Please enter your CMR" />
                                        </Form.Item>
                                        
                                    </Col> */}

                                  <Col xs={12} sm={6} md={2} lg={2} xl={2}>
                                        <Form.Item label="Valid From" name='InsuranceValidFrom'
                                            rules={[{ required: true, message: 'Please Select Date Valid From' }]}>
                                            <DatePicker onChange={(val)=>{this.calculate_issueDate(val,"insurance")}} />
                                        </Form.Item>
                                        {this.state.validInsurance===1 && 
                                          <Alert message="Invalid issue date" type="error" showIcon />
                                        }
                                    </Col>
                                    <Col xs={12} sm={6} md={2} lg={2} xl={2}>
                                        <Form.Item label="Expiry Date" name='InsuranceExpiryDate'
                                            rules={[{ required: true, message: 'Please Select Expiry Date' }]}>
                                            <DatePicker onChange={(val)=>{this.calculate_expireDate(val,"insurance")}} />
                                        </Form.Item>
                                        {this.state.expireInsurance===1 && 
                                          <Alert message="Invalid expire date" type="error" showIcon />
                                        }
                                    </Col>

                                     <Col  xs={12} sm={6} md={2} lg={2} xl={2}>
                                    <Form.Item label="Vehicle insurance photo" name='vehicle-picture'
                                    rules={[{ required: true, message: 'Please upload your photo' }]}>
                                    <Upload 
                                        name="vehicle-picture"
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        name="file"
                                        accept="image/*"
                                        action={``}
                                        // beforeUpload={this.beforeUpload}
                                        onChange={this.handleChangeVeh}
                                        rules={[{ required: true, message: 'Please your image' }]}>
                                   
                                        {imageUrl1 ? <img src={imageUrl1} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                    </Upload>
                                    </Form.Item>
                                    </Col> 
                                    
                                </Row>
                                <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    
                                    <h3 className="driver_registration_title">Goods in Transit:</h3>
                                    <hr/>
                                  </Col>
                                    
                                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Form.Item label="Name" name='goodsinTransit' 
                                            rules={[{ required: true, message: 'Invalid Data'},
                                            ]}>
                                            <Input />
                                        </Form.Item>
                                        
                                    </Col>

                                    

                                  <Col xs={12} sm={6} md={2} lg={2} xl={2}>
                                        <Form.Item label="Valid From" name='transitValidFrom'
                                            rules={[{ required: true, message: 'Please Select Date Valid From' }]}>
                                            <DatePicker onChange={(val)=>{this.calculate_issueDate(val,"insurance")}} />
                                        </Form.Item>
                                        {this.state.validInsurance===1 && 
                                          <Alert message="Invalid issue date" type="error" showIcon />
                                        }
                                    </Col>
                                    <Col xs={12} sm={6} md={2} lg={2} xl={2}>
                                        <Form.Item label="Expiry Date" name='transitExpiryDate'
                                            rules={[{ required: true, message: 'Please Select Expiry Date' }]}>
                                            <DatePicker onChange={(val)=>{this.calculate_expireDate(val,"insurance")}} />
                                        </Form.Item>
                                        {this.state.expireInsurance===1 && 
                                          <Alert message="Invalid expire date" type="error" showIcon />
                                        }
                                    </Col>

                                     {/* <Col  xs={12} sm={6} md={2} lg={2} xl={2}>
                                    <Form.Item label="Vehicle insurance photo" name='transit-picture'
                                    rules={[{ required: true, message: 'Please upload your photo' }]}>
                                    <Upload 
                                        name="vehicle-picture"
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        name="file"
                                        accept="image/*"
                                        action={``}
                                        // beforeUpload={this.beforeUpload}
                                        onChange={this.handleChangeVeh}
                                        rules={[{ required: true, message: 'Please your image' }]}>
                                   
                                        {imageUrl1 ? <img src={imageUrl1} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                    </Upload>
                                    </Form.Item>
                                    </Col>  */}
                                    
                                </Row>
                                <Row>
                                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <br/>
                                    <h3 className="driver_registration_title">Bank Details:</h3>
                                    <hr/>
                                  </Col>
                                  <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                                    <Form.Item  name='BankName' label="Bank Name:" rules={[{ required: true, message: 'Please input your bank name' }]}>
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                                    <Form.Item  name='AccountTtile' label="Account Ttile:" rules={[{ required: true, message: 'Please input your account title' }]}>
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                                    <Form.Item  name='SortCode' label="Sort Code:" rules={[{ required: true, message: 'Please input your sort code' }]}>
                                      <Input  />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                                    <Form.Item  name='AccountNumber' label="Account Number:" rules={[{ required: true, message: 'Please input your account number' }]}>
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row> 
                                    
                                    <Col className="d-flex justify-content-center" xs={12} sm={12} md={12} lg={12} xl={12} >
                                    
                                        <Button disabled={this.state.disable} type="submit" htmlType="submit" size="large" className="driver-reg-btn mt-5 d-flex justify-content-center" >Register Now</Button>
                                   
                                    </Col>
                                </Row> 

                                
                                
                                
                                
                                    
                                    
                                    
                                    
                                    
                                
                            </Form>
                        </Col>
                    </Row>
                    
                </Container>
            </>
          )
        }
      }

}

export default DriverRegistration;
