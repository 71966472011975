import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button'
import 'react-toastify/dist/ReactToastify.css';
import Table from 'react-bootstrap/Table'
import AOS from 'aos';

class UserPaidRequests extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      data:[]
    }
  }
  
  componentDidMount()
  {
    fetch("/getPaidRequests",{
      method:"get",
      headers:{
        "content-Type":"application/json"
      },
    }).then(res=>res.json())
    .then(data=>
    {
      console.log(data)
     this.setState({
        data:data
      })
    }).catch(err=>{
      console.log(err)
    })
  }
  
  
    render ()
      { 
        const requests =this.state.data.map((item,index)=>{
          console.log(item)
          if(item.status==="Paid")
          {
          var link=''
          if(item.category==='Furniture and Other Items')
          {
            link='/request-furniture'
          }
          if(item.category==='Home Removals')
          {
            link='/request-home-removals'
          }
          if(item.category==='Pianos')
          {
            link='/request-piano'
          }
          if(item.category==='Cars and Vehicles')
          {
            link='/request-vehicles'
          }
          if(item.category==='Motorbikes')
          {
            link='/request-motorbikes'
          }
          if(item.category==='Parcels and Packages' || item.category==='Specialist and Antiques' || item.category==='Vehicle Parts' || item.category==='Office Removals')
          {
            link='/request-parcel-packages'
          } 
          return(
              <tr class="d-flex" key={item._id} >
              <td class="col-1">{index+1}</td>
              <td class="col-4">{item.email}</td>
              <td class="col-5">{item.category}</td>
              <td className="col-2 d-flex justify-content-center"><Button  className='table-view-btn' onClick={()=>this.props.history.push(link,{item})}>View</Button></td>
            </tr>
            
          )}
      })

      const requestss = () =>{
        if(this.state.data.length>0)
        {
          console.log(this.state.data.length)
        return(
          <>
       <Row className='admin-forms'>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <h1 className='page-title'>Paid Requests</h1>
                  <hr/>
                </Col>
                <Col className='pt-3' >
                    <Table hover size="sm" className='text-left' className="table admin-table" data-aos="fade-up" data-aos-duration="2000">
                      <thead className='bg-orange text-white'>
                        <tr class="d-flex">
                          <th class="col-1">Sr. No</th>
                          <th class="col-4">Email</th>
                          <th class="col-5">Category</th>
                          <th class="col-2 text-center">Action</th>
                        </tr>
                      </thead>      
                    
                    <tbody>
                        {requests}
                    </tbody>
                  </Table> 
                </Col>
              </Row>
          </>
        )
        }
        else
        {
          return(
          <h5><span className='font-weight-bold text-orange'>No Paid Request </span></h5>
          )
        }
      }
     
      return(
        <>
           {requestss()}
        </>
      )

      }

}

export default UserPaidRequests;
