import React,{useEffect}  from "react";
import { Row, Col, Container } from "react-bootstrap";
function Privacy() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Container className="pt-5 pb-5">
        <Col xs={12}>
          <br />
          <h2 className="title1">Privacy Policy</h2>
          <p>
            Last updated on 20th June, 2023 When you use GetMovers we treat your
            information in accordance with Data Protection Laws. Our privacy
            policy explains how we collect and process your data, how you can
            access it, and how you can opt out.
          </p>
          <h4 className="text-blue">PRIVACY POLICY</h4>
          <p>
            This Privacy Policy explains what personal information we collect,
            how we process, with whom we share it, and how you (the user of the
            Service) can prevent us from sharing certain information with
            certain parties. You should read this Privacy Policy in its
            entirety. It is important that you read this Privacy Policy together
            with any other privacy policy, including our (“Cookies Policy”), or
            fair processing notice we may provide on specific occasions when we
            are collecting or processing personal information about you, so that
            you are aware of how and why we are using your personal information.
            This Privacy Policy supplements our other policies and is not
            intended to override them. This Privacy Policy has been prepared in
            accordance with the Data Protection Act 2018 which implements the
            General Data Protection Regulation (or such UK legislation intended
            to replace or supersede it) (“Data Privacy Laws”). Defined terms
            used in the Privacy Policy have the meanings set out in the Data
            Privacy Laws. Our transport partners (“Transport Partners”) and
            third-party or sub-contracted drivers (“Drivers”) should also read
            the privacy notice by following the link at the end of this policy.
          </p>
          <h4 className="text-blue">Who we are and how to contact us</h4>
          <p>
            GetMovers is a digital product/asset of OneWorld Homes Ltd duly
            registered in England & Wales under company laws having registration
            number 10259644 and registered office at 124 City Road London, EC1V
            2NX, United Kingdom VAT No. 383022517. References to “we”, “our” or
            “us” means GetMovers. We are a delivery, transportation, and storage
            services company. We are the controller of your data. This means
            that we are responsible for deciding how we collect, store, and use
            personal information about you.
          </p>
          <h4 className="text-blue">What data we collect</h4>
          <p>
            Personal data is any information that tells us something about you
            whether directly or indirectly identified or identifiable as an
            individual. We may collect and use the following personal data about
            you.
          </p>
          <h6 className="text-blue"> ● Identity Data </h6>
          <p>
            includes first name, maiden name, last name, username or similar
            identifier, marital status, title, date of birth, your image (be
            that photographic or video) and vehicle registration/license plate.
          </p>
          <h6 className="text-blue"> ● Contact Data </h6>
          <p>
            includes residential addresses, property ownership information,
            relevant contact addresses, email address and telephone numbers.
          </p>
          <h6 className="text-blue"> ● Financial Data </h6>
          <p>
            includes bank account, payment card details and information to
            enable us to undertake Money Laundering and Terrorist Financing
            electronic checks on you.
          </p>
          <h6 className="text-blue"> ● Transaction Data </h6>
          <p>
            includes details about payments to and from you and other details of
            products and services you have purchased from us.
          </p>
          <h6 className="text-blue"> ● Technical Data </h6>
          <p>
            {" "}
            includes internet protocol (IP) address, your login data, browser
            type and version, time zone setting and location, browser plug-in
            types and versions, operating system and platform, and other
            technology on the devices you use to access this website.
          </p>
          <h6 className="text-blue"> ● Profile Data </h6>
          <p>
            {" "}
            includes purchases or orders made by you, your interests,
            preferences, feedback and survey responses.
          </p>
          <h6 className="text-blue"> ● Usage Data </h6>
          <p>
            {" "}
            includes information about how you use our website, products and
            services.
          </p>
          <h6 className="text-blue">● Marketing and Communications Data </h6>
          <p>
            includes your preferences in receiving marketing from us and our
            third parties and your communication preferences.
          </p>
          <h6 className="text-blue">● Call Data </h6>
          <p>includes recording of all calls made with the GetMovers agents.</p>
          <br />
          <p>
            This personal data is required to provide our services to you. If
            you do not provide the personal data we ask for, it may delay or
            prevent us from providing services to you. We do not collect any
            Special Categories of Personal Data about you (this includes details
            about your race or ethnicity, religious or philosophical beliefs,
            sex life, sexual orientation, political opinions, trade union
            membership, information about your health, and genetic and biometric
            data). Nor do we collect any information about criminal convictions
            and offenses. If you provided personal data of other people for the
            purpose and fulfillment of a collection and/or delivery, it is your
            responsibility to notify these people that their data is/was
            provided to GetMovers. We also collect, use, and share Aggregated
            Data such as statistical or demographic data for any purpose.
            Aggregated Data could be derived from your personal data but is not
            considered personal data in law as this data will not directly or
            indirectly reveal your identity.
          </p>
          <h4 className="text-blue">How do we collect personal data</h4>
          <p>
            We use different methods to collect data from and about you
            including through:
          </p>
          <p>
            ● Direct source. You may give us your Identity, Contact and
            Financial Data by filling in forms, such as requesting a quote, or
            by corresponding with us by post, phone, email or otherwise.
          </p>
          <p>This includes personal data you provide when you:</p>
          <p>
            <ul>
              <li>Apply for services</li>
              <li>Create an account on our website</li>
              <li>Subscribe to our service</li>
              <li>Contact us through social media</li>
              <li>Request marketing to be sent to you</li>
              <li>Give us some feedback</li>
              <li>Speak with us on the telephone and we record the call</li>
              <li>Make a complaint</li>
              <li>
                Automated technologies or interactions. As you interact with our
                website, we will automatically collect Technical Data about your
                equipment, browsing actions and patterns. We collect this
                personal data by using cookies, server logs, and other similar
                technologies. We may also receive Technical Data about you if
                you visit other websites employing our cookies. Please see our
                cookie policy for further details.
              </li>
              <li>
                Third parties or publicly available sources. We may receive
                personal data about you from various third parties and public
                sources as set out below.
                <li>Technical Data from analytics providers such as Google</li>
                <li>
                  Technical Data from advertising networks such as Google,
                  Facebook, Instagram, etc.
                </li>
                <li>
                  Technical Data from search information providers such as
                  Google
                </li>
                <li>
                  Contact, Financial, and Transaction Data from providers of
                  technical, payment, and delivery services such as Checkout,
                  Stripe, or Adyen, etc.
                </li>
              </li>
            </ul>
          </p>
          <h4 className="text-blue">How we use personal data</h4>
          <p>
            We will only use your personal data when the law allows us to. Most
            commonly, we will use your personal data in the following
            circumstances:
          </p>
          <p>
            <ul>
              <li>
                {" "}
                Where we need to perform the contract we are about to enter into
                or have entered into with you.
              </li>
              <li>
                {" "}
                Where it is necessary for our legitimate interests (or those of
                a third party) and your interests and fundamental rights do not
                override those interests.
              </li>
              <li> Where we need to comply with a legal obligation.</li>
            </ul>
          </p>
          <p>
            Click here to find out more about the types of lawful basis that we
            will rely on to process your personal data.
          </p>
          <p>
            Generally, we do not rely on consent as a legal basis for processing
            your personal data although we will get your consent before sending
            third party direct marketing communications to you via email or text
            message. You have the right to withdraw consent to marketing at any
            time by contacting us.
          </p>
          <p>
            <table style={{ borderCollapse: "collapse" }}>
              <thead>
                <tbody>
                  <tr>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      Purpose/Activity
                    </td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      Lawful basis for processing including basis of legitimate
                      interest
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      To register you as a new Customer, Transport Partner or
                      Driver and provide services to you.
                    </td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      For the performance of our contract with you or to take
                      steps at your request before entering into a contract.
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      To manage our relationship with you which will include:
                    </td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      <ul>
                        <li>Updating and enhancing customer records</li>
                        <li>
                          Notifying you about changes to our terms or privacy
                          policy
                        </li>
                        <li>Asking you to leave a review or take a survey</li>
                      </ul>
                      <p>Performance of a contract with you</p>
                      <p>Necessary to comply with a legal obligation</p>
                      <p>
                        Necessary for our legitimate interests (to keep our
                        records updated and to study how customers, transport
                        partners and drivers use our products/services)
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      To assist in arranging or maintaining any insurance
                      coverage that you, and other users, may require, managing
                      risk or obtaining professional advice.
                    </td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      For the performance of our contract with you or to take
                      steps at your request before entering into a contract.
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      Conducting checks to identify our customer and verify
                      their identity. Screening for financial and other
                      processing necessary to comply with professional, legal
                      and regulatory obligations that apply to our business,
                      e.g. under health and safety regulation or rules issued by
                      our professional regulator
                    </td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      To comply with our legal and regulatory obligations
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      Gathering and providing information required by or
                      relating to audits, enquiries, or investigations by
                      regulatory bodies
                    </td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      To comply with our legal and regulatory obligations
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      Ensuring business policies are adhered to, e.g. policies
                      covering security and internet use
                    </td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      For our legitimate interests or those of a third party,
                      i.e. to make sure we are following our own internal
                      procedures so we can deliver the best service to you
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      Statistical analysis to help us manage our business, e.g.
                      in relation to our financial performance, customer base,
                      range of services or other efficiency measures
                    </td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      For our legitimate interests or those of a third party,
                      i.e. to be as efficient as we can so we can deliver the
                      best service for you
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      Statutory returns
                    </td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      To comply with our legal and regulatory obligations
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      Ensuring safe working practices, staff administration and
                      assessments
                    </td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      To comply with our legal and regulatory obligations. For
                      our legitimate interests or those of a third party, e.g.
                      to make sure we are following our own internal procedures
                      and working efficiently so we can deliver the best service
                      to you
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      To record telephone calls both incoming and outgoing
                    </td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      Performance of a contract with you
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      To administer and protect our business, this website and
                      App (including troubleshooting, data analysis, testing,
                      system maintenance, data backups, support, reporting and
                      hosting of data)
                    </td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      Necessary for our legitimate interests (for running our
                      business, provision of administration and IT services,
                      network security, to prevent fraud and in the context of a
                      business reorganisation or group restructuring exercise)
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      To enable you to partake in a prize draw, competition or
                      complete a survey
                    </td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      Performance of a contract with you
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      To deliver relevant website content and advertisements to
                      you and measure or understand the effectiveness of the
                      advertising we serve to you
                    </td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      Necessary for our legitimate interests (to study how
                      customers use our products/services, to develop them, to
                      grow our business and to inform our marketing strategy)
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      To use data analytics to improve our website,
                      products/services, marketing, customer relationships and
                      experiences
                    </td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      Necessary for our legitimate interests (to define types of
                      customers for our products and services, to keep our
                      website updated and relevant, to develop our business and
                      to inform our marketing strategy)
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      Marketing our services to:
                      <br />
                      - existing and former customers;
                      <br />
                      - third parties who have previously registered with us or
                      expressed an interest in our services;
                      <br />- third parties with whom we have had no previous
                      dealings
                    </td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      Necessary for our legitimate interests (to develop our
                      products/services and grow our business)
                    </td>
                  </tr>
                </tbody>
              </thead>
            </table>
          </p>{" "}
          <h4 className="text-blue">Marketing</h4>
          <p>
            We will hold your personal data on our client management systems and
            use this to provide you with marketing information about similar
            services offered by us to those which you have engaged us to provide
            which we feel you might find useful from time to time. You can opt
            out of receiving this information at any time.
          </p>
          <p>
            <ul>
              <li>
                {" "}
                emails, except those that relate to your booking, you can do so
                by clicking “Unsubscribe” on the footer on any email; OR
              </li>
              <li>
                {" "}
                direct marketing, you can do so by logging into your account and
                clicking “Unsubscribe from marketing emails” on your profile
                page, OR
              </li>
              <li>
                {" "}
                If both the options are unavailable, you may directly contact us
                for unsubscribing.
              </li>
            </ul>
          </p>
          <h4 className="text-blue">Call recording</h4>
          <p>
            We may record any incoming or outgoing calls handled by the
            GetMovers support team (or transferred to any other department), and
            any call recordings may be used as follows:
          </p>
          <p>
            <ul>
              <li>To monitor call quality;</li>
              <li>To train and develop GetMovers staff;</li>
              <li>
                To keep a record of what is said about an event or complaint;
              </li>
              <li>
                To review and develop our processes to better support our users
                and check for any mistakes;
              </li>
              <li>To prevent, detect, and investigate any possible fraud.</li>
            </ul>
          </p>
          <p>
            If your call is being recorded, you will hear a pre-recorded message
            stating this before connecting to a member of the GetMovers support
            team and directing you to this Privacy Policy. The recording starts
            when you hear a beep and ends automatically when the call ends or
            when the call is transferred externally.
          </p>
          <p>
            If you do not want your call to be recorded, please tell the person
            you are speaking to at any time that you wish the recording to be
            stopped. Call recordings are only accessed by members of staff at
            GetMovers. If you would like a copy of any call recording, you can
            submit a request in writing to support@getmovers.co.uk.
          </p>
          <p>
            Call recordings will be securely stored for 12 months after the call
            was made and will be managed in accordance with Data Privacy Laws.
          </p>
          <h4 className="text-blue">Who we share your personal data with</h4>
          <p>
            We only allow our service providers to handle your personal
            information if we are satisfied they take appropriate measures to
            protect your personal information. We also impose contractual
            obligations on service providers (where possible) to ensure they can
            only use your personal information to provide services to us and to
            you. These will include but will not be limited to:
          </p>
          <p>
            <ul>
              <li>
                If we sell any part of our business and/or integrate it with
                another organization, your details may be disclosed to our
                advisers and to prospective purchasers or joint venture partners
                and their adviser. If this occurs, the new owners of the
                business will only be permitted to use your information in the
                same or similar way as set out in the privacy policy.
              </li>
              <li>
                We will share personal data with our regulators, governmental or
                quasi-governmental organizations, law enforcement authorities,
                and with courts, tribunals, and arbitrators as may be required
                from time to time in order to comply with our regulatory and
                legal obligations.
              </li>
              <li>
                We may share an encoded version of your personal data to third
                parties such as Facebook and Google to enable these third
                parties to display GetMovers ads when you visit their websites.
              </li>
              <li>
                We may share your personal information with any person working
                within our group companies on a need-to-know basis to ensure we
                are able to perform our obligations to you.
              </li>
            </ul>
            <p>We routinely share personal data with:</p>
            <ul>
              <li>
                <strong>Our insurers and/or professional advisers</strong> as
                reasonably necessary for the purposes of obtaining or
                maintaining insurance coverage, managing risks, obtaining
                professional advice, or the establishment, exercise, or defense
                of legal claims;
              </li>
              <li>
                <strong>Transport Partners and Drivers</strong> as reasonably
                necessary for the purposes of providing the requested
                transportation services (such data is only used and retained to
                allow the service to be completed, and will be shared through a
                secure means);
              </li>
              <li>
                <strong>Movinghub Limited T/A “Movinghub”</strong> for the
                purpose of providing home set-up services (please see
                Movinghub’s privacy policy here);
              </li>
              <li>
                <strong>
                  With companies who provide us with or support our IT systems
                  and website:
                </strong>{" "}
                We use reputable third parties to provide us with our IT
                systems, applications, and website and support for them.
              </li>
              <li>
                <strong>
                  With companies who provide marketing support services to us:
                </strong>{" "}
                We use reputable third parties to provide us with marketing
                support services such as Awin.
              </li>
              <li>
                <strong>With our suppliers,</strong> to the extent necessary in
                order to provide services to us;
              </li>
              <li>
                <strong>With our payment services providers,</strong> only to
                the extent necessary for the purposes of processing payments,
                refunding payments, and dealing with complaints and/or queries
                relating to payments and refunds;
              </li>
              <li>
                <strong>LiveChat</strong> as reasonably necessary to give you
                the option to chat with one of our support service
                representatives (see LiveChat’s privacy policy here);
              </li>
              <li>
                <strong>Bing</strong> (see an explanation of our use of Bing in
                our Cookies Policy);
              </li>
              <li>
                <strong>Facebook</strong> (see an explanation of our use of
                Facebook in our Cookies Policy);
              </li>
              <li>
                <strong>Google Analytics and Google AdSense</strong> (see an
                explanation of our use of Google Analytics and Google AdSense in
                our Cookies Policy);
              </li>
              <li>
                <strong>Google Maps</strong> as reasonably necessary to assist
                with quoting delivery requests (see Google’s privacy policy
                here); and
              </li>
            </ul>
          </p>
          <p>
            We may disclose Transport Partner personal information (name, phone
            number, vehicle details) to you as reasonably necessary for the
            purposes of providing the transportation services you have
            requested. You are only allowed to use and retain this personal
            information to allow the service you have requested to complete.
          </p>
          <h5 className="text-blue">Cookies</h5>
          <p>
            You can set your browser to refuse all or some browser cookies, or
            to alert you when websites set or access cookies. If you disable or
            refuse cookies, please note that some parts of this website may
            become inaccessible or not function properly. For more information
            about the cookies we use, please see Cookie Policy.
          </p>
          <h5 className="text-blue">Online ads</h5>
          <p>
            We use online advertising to keep you aware of what we’re up to and
            to help you find our products. Like many companies, we may target
            GetMovers banners and ads to you when you use other websites and
            apps, based on your Contact, Technical, Usage and Profile Data. We
            do this using a variety of digital marketing networks and ad
            exchanges, and a range of advertising technologies such as web
            beacons, pixels, ad tags, cookies, and mobile identifiers, as well
            as specific services offered by some sites and social networks, such
            as Facebook’s Custom Audience Service.
          </p>
          <h5 className="text-blue">
            Our use of analytics and targeted advertising tools
          </h5>
          <p>
            We use a range of analytics and targeted advertising tools to
            display relevant website content on our website and online
            advertisements on other websites and apps (as described above) to
            you, deliver relevant content to you in marketing communications
            (where applicable), and to measure the effectiveness of the
            advertising provided. For example, we use tools such as Google
            Analytics to analyze Google’s interest-based advertising data and/or
            third-party audience data (such as age, marital status, life event
            and interests) to target and improve our marketing campaigns,
            marketing strategies and website content. We may also use tools
            provided by other third parties, such as Facebook to perform similar
            tasks, using your Contact, Technical, Usage and Profile Data.
          </p>
          <p>
            To opt out of targeted advertising you need to disable your
            ‘cookies’ in your browser settings or opt-out of the relevant
            third-party Ad Settings. For example, you can opt-out of the Google
            Display Advertising Features.
          </p>
          <h4 className="text-blue">
            Overseas transfers of your personal data
          </h4>
          <p>
            We may transfer your personal data to an external third party which
            is based outside the EEA so their processing of your personal data
            will involve a transfer of data outside the EEA.
          </p>
          <p>
            Whenever we transfer your personal data out of the EEA, we ensure a
            similar degree of protection is afforded to it by ensuring at least
            one of the following safeguards is implemented:
          </p>
          <p>
            <ul>
              <li>
                We will only transfer your personal data to countries that have
                been deemed to provide an adequate level of protection for
                personal data by the European Commission. For further details,
                see European Commission: Adequacy of the protection of personal
                data in non-EU countries.
              </li>
              <li>
                Where we use certain service providers, we may use specific
                contracts approved by the European Commission which give
                personal data the same protection it has in Europe. For further
                details, see European Commission: Model contracts for the
                transfer of personal data to third countries.
              </li>
            </ul>
          </p>
          <h4 className="text-blue">How long we keep your data</h4>
          <p>
            We will not keep your personal data for longer than we need to, the
            period for which we will keep your personal data will depend on the
            type of service you have requested from us. The retention period may
            be longer than the period for which we are providing services to you
            where we have statutory or regulatory obligations to retain personal
            data for a longer period, or where we may need to retain information
            in case of legal claim.
          </p>
          <p>
            In some circumstances we will anonymise your personal data (so that
            it can no longer be associated with you) for research or statistical
            purposes, in which case we may use this information indefinitely
            without further notice to you.
          </p>
          <h4 className="text-blue">How we protect your data</h4>
          <p>
            We implement appropriate technical and organizational measures to
            protect data that we process from unauthorized disclosure, use,
            alteration, or destruction.
          </p>
          <p>
            In addition to the technical and organizational measures we have put
            in place, there are a number of simple things you can do to in order
            to further protect your personal information;
          </p>
          <p>
            <ol>
              <li>Never share a One Time Passcode (OTP).</li>
              <li>
                Never enter your details after clicking on a link in an email or
                text message.
              </li>
              <li>
                Always send confidential information by encrypted email where
                possible; this reduces the risk of interception.
              </li>
              <li>
                If you're logged into any online service, do not leave your
                computer unattended.
              </li>
              <li>Close your internet browser once you've logged off.</li>
              <li>
                Never download software or let anyone log on to your computer or
                devices remotely, during or after a cold call.
              </li>
              <li>
                You can easily identify secure websites by looking at the
                address in the top of your browser, which will begin with
                "https://" rather than "http://".
              </li>
            </ol>
          </p>
          <h4 className="text-blue">
            Your rights in relation to your personal information
          </h4>
          <p>
            Under certain circumstances, you have rights under data protection
            laws in relation to your personal data. Please click on the links
            below to find out more about these rights:
          </p>
          <p>
            <ul>
              <li>
                Request access to your personal data - (commonly known as a
                "data subject access request"). This enables you to receive a
                copy of the personal data we hold about you and to check that we
                are lawfully processing it.
              </li>
              <li>
                Request correction of your personal data - This enables you to
                have any incomplete or inaccurate data we hold about you
                corrected, though we may need to verify the accuracy of the new
                data you provide to us.
              </li>
              <li>
                Request erasure of your personal data - This enables you to ask
                us to delete or remove personal data where there is no good
                reason for us continuing to process it. You also have the right
                to ask us to delete or remove your personal data where you have
                successfully exercised your right to object to processing (see
                below), where we may have processed your information unlawfully
                or where we are required to erase your personal data to comply
                with local law. Note, however, that we may not always be able to
                comply with your request of erasure for specific legal reasons
                which will be notified to you, if applicable, at the time of
                your request.
              </li>
              <li>
                Object to processing of your personal data - where we are
                relying on a legitimate interest (or those of a third party) and
                there is something about your particular situation which makes
                you want to object to processing on this ground as you feel it
                impacts on your fundamental rights and freedoms. You also have
                the right to object where we are processing your personal data
                for direct marketing purposes. In some cases, we may demonstrate
                that we have compelling legitimate grounds to process your
                information which override your rights and freedoms.
              </li>
              <li>
                Request restriction of processing your personal data - This
                enables you to ask us to suspend the processing of your personal
                data in certain scenarios:
                <ul>
                  <li>If you want us to establish the data's accuracy.</li>
                  <li>
                    Where our use of the data is unlawful but you do not want us
                    to erase it.
                  </li>
                  <li>
                    Where you need us to hold the data even if we no longer
                    require it as you need it to establish, exercise, or defend
                    legal claims.
                  </li>
                  <li>
                    You have objected to our use of your data but we need to
                    verify whether we have overriding legitimate grounds to use
                    it.
                  </li>
                </ul>
              </li>
              <li>
                Request transfer of your personal data - We will provide you, or
                a third party you have chosen, your personal data in a
                structured, commonly used, machine-readable format. Note that
                this right only applies to automated information which you
                initially provided consent for us to use or where we used the
                information to perform a contract with you.
              </li>
              <li>
                Rights in relation to automated decisions - We have some
                processes that may involve an automated decision; however, we
                ensure you are informed of the decision by a GetMovers staff
                member who will outline the validation for the decision. You may
                request that we specifically not use automated decision making;
                however, this may affect the speed at which a decision can be
                made.
              </li>
              <li>
                Right to withdraw consent - Where we are relying on consent to
                process your personal data. However, this will not affect the
                lawfulness of any processing carried out before you withdraw
                your consent. If you withdraw your consent, we may not be able
                to provide certain products or services to you. We will advise
                you if this is the case at the time you withdraw your consent.
              </li>
            </ul>
          </p>
          <p>
            If you wish to exercise any of the rights set out above, please
            contact us.
          </p>
          <p>
            Please note that we will keep a record of the fact that you have
            made a request to exercise your rights, and our response to your
            request, to demonstrate compliance with our data protection
            obligations and so that we can handle any queries, complaints or
            claims in relation to your request. This record will be kept in
            accordance with our retention policies as described above.
          </p>
          <h5 className="text-blue">No fee usually required</h5>
          <p>
            You will not have to pay a fee to access your personal data (or to
            exercise any of the other rights). However, we may charge a
            reasonable fee if your request is clearly unfounded, repetitive, or
            excessive. Alternatively, we could refuse to comply with your
            request in these circumstances
          </p>
          <h5 className="text-blue">What we may need from you</h5>
          <p>
            We may need to request specific information from you to help us
            confirm your identity and ensure your right to access your personal
            data (or to exercise any of your other rights). This is a security
            measure to ensure that personal data is not disclosed to any person
            who has no right to receive it. We may also contact you to ask you
            for further information in relation to your request to speed up our
            response.
          </p>
          <h5 className="text-blue">Time limit to respond</h5>
          <p>
            We try to respond to all legitimate requests within one month.
            Occasionally it could take us longer than a month if your request is
            particularly complex or you have made a number of requests. In this
            case, we will notify you and keep you updated.
          </p>
          <h4 className="text-blue">Complaints and contact details</h4>
          <h5 className="text-blue">Complaints</h5>
          <p>
            If you have any complaints about the way we use your personal data
            please contact the data protection officer at
            support@getmovers.co.uk who will try to resolve the issue. If we
            cannot resolve any issue, you have the right to complain to the
            Information Commissioner Office (ICO). If you need more information
            about how to contact the ICO please let us know.
          </p>
          <h5 className="text-blue">Contact details</h5>
          <p>
            If you have any questions, comments or requests regarding any aspect
            of this Privacy Policy, please do not hesitate to contact us by
            sending an email to support@getmovers.co.uk or writing to the Data
            Protection Officer, 124 City Road London, EC1V 2NX, United Kingdom .
          </p>
          <h4 className="text-blue">Updates to this notice</h4>
          <p>
            From time to time we may need to make changes to this notice, for
            example, as the result of changes to law, technologies, or other
            developments. We will provide you with the most up-to-date notice
            and you can check our website periodically to view it.
          </p>
          <p>This notice was last updated on 20th June, 2023.</p>
          <h4 className="text-blue">Transport Partners and Drivers</h4>
          <p>
            The following outlines the additional privacy details with regards
            to our Transport Partners and Drivers. It does not replace the
            Privacy Policy rather it supplements it.
          </p>
          <h5 className="text-blue">What additional data we collect</h5>
          <p>
            The additional Personal data we may collect and use about you
            includes;
          </p>
          <p>
            <ul>
              <li>
                Identity Data includes employment references, background check
                results.
              </li>
              <li>
                Financial Data includes vehicle insurance, utility bills and
                credit check results.
              </li>
              <li>
                Technical Data includes communication content and metadata
                associated with the communication (generated by our App).
              </li>
            </ul>
          </p>
          <h4 className="text-blue">How we use personal data</h4>
          <p>
            We will only use your personal data when the law allows us to. Most
            commonly, we will use your personal data in the following
            circumstances:
          </p>
          <p>
            <ul>
              <li>
                Where we need to perform the contract we are about to enter into
                or have entered into with you.
              </li>
              <li>
                Where we need to perform the contract between GetMovers and a
                Transport Partner, where the Transport Partner provides you, a
                Driver, with access to the App.
              </li>
              <li>
                Where it is necessary for our legitimate interests (or those of
                a third party) and your interests and fundamental rights do not
                override those interests.
              </li>
              <li>Where we need to comply with a legal obligation.</li>
            </ul>
          </p>
          <p>
            Generally, we do not rely on consent as a legal basis for processing
            your personal data although we will get your consent before sending
            third party direct marketing communications to you via email or text
            message. You have the right to withdraw consent to marketing at any
            time by contacting us.
          </p>
          <p>
            <table style={{ borderCollapse: "collapse" }}>
              <tr>
                <td style={{ border: "1px solid black" }}>Purpose/Activity</td>
                <td style={{ border: "1px solid black" }}>
                  Lawful basis for processing including basis of legitimate
                  interest
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid black" }}>
                  To make a decision about your appointment as a Transport
                  Partner, determine the terms on which you provide services on
                  behalf of GetMovers and administer the contract with you as a
                  Transport Partner.
                </td>
                <td style={{ border: "1px solid black" }}>
                  Performance of a contract with you
                  <br />
                  Necessary to comply with a legal obligation
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid black" }}>
                  To process payments in respect of any services you provide for
                  GetMovers and keep a record of the same.
                </td>
                <td style={{ border: "1px solid black" }}>
                  Performance of a contract with you
                  <br />
                  Necessary to comply with a legal obligation
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid black" }}>
                  To provide your contact details to any Customers to enable
                  them to contact you in order for you to provide services to
                  them.
                </td>
                <td style={{ border: "1px solid black" }}>
                  Performance of a contract with you
                  <br />
                  Necessary for our legitimate interests (to provide the
                  purchased service to the customer)
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid black" }}>
                  To review your performance as a Transport Partner, investigate
                  any complaints concerning you, and make decisions about your
                  continued engagement as a Transport Partner.
                </td>
                <td style={{ border: "1px solid black" }}>
                  Performance of a contract with you
                  <br />
                  Necessary for our legitimate interests (to maintain high
                  levels of services and managing complaints)
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid black" }}>
                  To deal with any legal disputes that arise involving you,
                  comply with health and safety obligations, and prevent fraud.
                </td>
                <td style={{ border: "1px solid black" }}>
                  Performance of a contract with you
                  <br />
                  Necessary for our legitimate interests (to maintain high
                  levels of services and managing complaints)
                  <br />
                  To comply with our legal and regulatory obligations
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid black" }}>
                  {" "}
                  To administer the contract that GetMovers has entered into
                  with a Transport Partner.
                </td>
                <td style={{ border: "1px solid black" }}>
                  Performance of a contract
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid black" }}>
                  {" "}
                  To provide your contact details to any Customers to enable
                  them to contact you in order for you to provide services to
                  them.
                </td>
                <td style={{ border: "1px solid black" }}>
                  Performance of a contract with you
                  <br />
                  Necessary for our legitimate interests (to provide the
                  purchased service to the customer)
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid black" }}>
                  To review your performance as a Driver and investigate any
                  complaints concerning you and any legal disputes that arise
                  involving you.
                </td>
                <td style={{ border: "1px solid black" }}>
                  Performance of a contract with you
                  <br />
                  Necessary for our legitimate interests (to maintain high
                  levels of services and managing complaints)
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid black" }}>
                  To respond to, and keep a record of, any communications that
                  you, and other users, send to us for complaint investigation
                  and fraud prevention purposes.
                </td>
                <td style={{ border: "1px solid black" }}>
                  Performance of a contract with you
                  <br />
                  Necessary for our legitimate interests (to maintain high
                  levels of services and managing complaints)
                  <br />
                  To comply with our legal and regulatory obligations
                </td>
              </tr>
            </table>
          </p>
          <h4 className="text-blue">Who we share your personal data with</h4>
          <p>
            In addition to the main privacy policy we routinely share personal
            data with:
          </p>
          <h5 className="text-blue">● Customers </h5>
          <p>as reasonably necessary for the purposes of</p>
          <p>
            <ul>
              <li>
                Providing the requested transportation services (such data is
                only used and retained to allow the service to be completed, and
                will be shared through a secure means)
              </li>
              <li>
                Providing you with a job and allowing the Customers to contact
                you in relation to the job (such data is only used and retained
                to allow the service to be completed, and will be shared through
                a secure means)
              </li>
            </ul>
          </p>
          <p>
          <h5 className="text-blue">
            All other terms and definitions from the Privacy Policy should be
            read in regard to your rights and protections.
          </h5>
          </p>
        </Col>
      </Container>
    </div>
  );
}

export default Privacy;
