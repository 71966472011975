import React, {useEffect, createContext, useReducer, useContext} from 'react';
import {BrowserRouter, Route, Switch, useHistory} from 'react-router-dom';
import './css/custom.css'
import Home from './components/home';
import HeaderNavbar from './components/navbar/navbar';
import Categories from './components/categories';
import Furniture from './components/furniture';
import HomeRemovals from './components/homeRemovals';
import Vehicle from './components/vehicles';
import Motorbike from './components/motorbike';
import Piano from './components/piano';
import ParcelAndPackages from './components/parcelAndPackages';
import OfficeRemovals from './components/officeRemovals';
import VehicleParts from './components/vehicleParts';
import SpecialistAndAntiques from './components/specialistAndAntiques';
import EmailPrices from './components/emailPrices';
import Index from './components/admin-panel/index'
import Blog from './components/blog/blog'
import SinglePost from './components/blog/single-post'
import AdviceCenter from './components/advice-center/advice-center'
import AdviceCenterPost from './components/advice-center/advice-center-post'
import Login from './components/admin-panel/login/login'
import AboutUs from './components/pages/about-us'
import ContactUs from './components/pages/contact-us'
import DriverRegistration from './components/driver-registration'
import Footer from './components/footer';
import GetLocation from './components/getLocation';
import SurveyPerforma from './components/surveyPerforma';
import Payment from './components/payment'
import ChangePassword from './components/ChangePassword'
import VerifyAccount from './components/VerifyAccount'
import LoginAdmin from './components/admin-panel/login/loginAdmin'
import {reducer, initialState} from './components/Reducer/UserReducer'
import PrivacyPolicy from './components/pages/PrivacyPolicy'
import GeneralTerms from './components/pages/GeneralTerms'
import AntiBribery from './components/pages/AntiBribery'
import Privacy from './components/pages/Privacy';
import Term from './components/pages/Term';
import Insurance from './components/pages/Insurance';
import Student from './components/pages/Student';
import Manvan from './components/Manvan';


export const userContext = createContext()


const Routing = () =>
{
  const history = useHistory()
  const {state,dispatch} = useContext(userContext)
  useEffect(()=>{
    const user = JSON.parse(localStorage.getItem("getmoversuser"))
    //console.log(user)
    if(user)
    { 
      if(!history.location.pathname.startsWith('/payment') && !history.location.pathname.startsWith('/surveyPerforma') && !history.location.pathname.startsWith('/verification'))
      {
        console.log(user.type)
        dispatch({type:"GETMOVERSUSER", payload:user})
        history.push('/')
      }
      
    }
    else
     {
       if(!history.location.pathname.startsWith('/admin') && !history.location.pathname.startsWith('/payment') && !history.location.pathname.startsWith('/surveyPerforma') && !history.location.pathname.startsWith('/setPassword') && !history.location.pathname.startsWith('/verification'))
       {
        history.push('/')
       }
       
     }
  },[])
  return(
    <Switch>
            
            <Route exact path="/" component={Home}/>
            <Route exact path="/categories" component={Categories}/>
            <Route exact path="/furniture" component={Furniture}/>
            <Route exact path="/home-removals" component={HomeRemovals}/>
            <Route exact path="/vehicle" component={Vehicle}/>
            <Route exact path="/motorbike" component={Motorbike}/>
            <Route exact path="/piano" component={Piano}/>
            <Route exact path="/parcelandpackages" component={ParcelAndPackages}/>
            <Route exact path="/specialist-antiques" component={SpecialistAndAntiques}/>
            <Route exact path="/vehicle-parts" component={VehicleParts}/>
            <Route exact path="/office-removals" component={OfficeRemovals}/>
            <Route exact path="/email-prices" component={EmailPrices} />
            <Route exact path="/man-and-van" component={Manvan} />
            <Route exact path="/blog" component={Blog}/>
            <Route exact path="/single-post" component={SinglePost}/>
            <Route exact path="/advice-center" component={AdviceCenter}/>
            <Route exact path="/advice-center-post" component={AdviceCenterPost}/>
            <Route exact path="/driver-registration" component={DriverRegistration}/>
            <Route exact path="/surveyPerforma/:token" component={SurveyPerforma}/>
            <Route exact path='/admin' component={LoginAdmin}/> 
            <Route exact path="/privacy-policy-cookies" component={PrivacyPolicy}/>
            <Route exact path="/get-location" component={GetLocation}/>
            <Route exact path="/about-us" component={AboutUs}/>
            <Route exact path="/contact-us" component={ContactUs}/>
            <Route exact path="/terms-condition" component={GeneralTerms}/>
            <Route exact path="/anti-bribery-anti-corruption" component={AntiBribery}/>
            <Route exact path="/login" component={Login}/>
            <Route exact path="/newRequest" component={Index}/>
            <Route exact path="/driverNewRequests" component={Index}/>
            <Route exact path="/payment/:token" component={Payment}/>
            <Route exact path="/setPassword/:token" component={ChangePassword}/>
            <Route exact path="/privacy-policy" component={Privacy}/>
            <Route exact path="/term-and-conditions" component={Term}/>
            <Route exact path="/insurance-policies" component={Insurance}/>
            <Route exact path="/Student-discount" component={Student}/>
            <Route exact path="/verification/:token" component={VerifyAccount}/>
            
    </Switch>
  );
}

function App() {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <userContext.Provider value={{state, dispatch}}>
    <BrowserRouter>
    <HeaderNavbar/>
      <Routing />
      <Footer/>
    </BrowserRouter>
    </userContext.Provider> 
  );
}

export default App;
