import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";
import about2 from "../img/7.jpg";
import about15 from "../img/20.jpg";
import rating from "../img/rating.png";
import ebay from "../img/pricing.png";
import darkLogo from "../img/darklogo.svg";
import { RiArrowRightSLine } from "react-icons/ri";
import { FaShippingFast } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import { Form, Input, Button, Select } from "antd";
import "antd/dist/antd.css";
import b2 from "../img/b2.jpg";
import { FaFacebook, FaLinkedin, FaInstagramSquare } from "react-icons/fa";
import {
  AiFillTwitterCircle,
  AiOutlineHome,
  AiOutlineMail,
} from "react-icons/ai";
import { FiPhone, FaFax } from "react-icons/all";
import Carousel from "react-bootstrap/Carousel";
import categoriesDetail from "./json/data";
import PlacesAutocomplete from "react-places-autocomplete";
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import Slider from "./slider";
import about1 from "../img/about-1.png";
import about3 from "../img/b1.jpg";
import about11 from "../img/2.jpg";
import AOS from "aos";
import Moment from "moment";
import FooterImg from "../img/banr.png";
import ContactUs from "./pages/contact-us";

class Home extends React.Component {
  constructor(props) {
    super(props);
    AOS.init();
  }

  CallRoute(id, category) {
    this.props.history.push("/get-location", { id: id, category: category });
  }
  ServicesCards = categoriesDetail.map((item, index) => {
    return (
      <div
        data-aos="flip-left"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="2000"
        className="services-col d-flex justify-content-center col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
      >
        <Card
          className="text-center pointer"
          onClick={() => this.CallRoute(item.id, item.category)}
        >
          <div className="services-card-content justify-content-center">
            <Image
              className="services-card-bg-image"
              src={require(`../img/b${index + 1}.jpg`)}
              width="100%"
              height="100%"
            />
            <div className="services-card-bg-opacity"></div>
            <div className="card-services">
              <p>{item.image}</p>
              <Card.Title className="text-white">{item.category}</Card.Title>
              <Card.Text className="text-light">{item.description}</Card.Text>
            </div>
          </div>
        </Card>
      </div>
    );
  });

  render() {
    return (
      <>
        <Slider link={this.props.history} />

        {/* <Container className="pt-5 pb-5">
          <Row className="pt-5">
            <div
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
            >
              <Image src={about11} width="100%" />
            </div>

            
            <div data-aos="fade-up"  data-aos-duration="3000" className='col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9'>
                    <h2 className='text-orange'>Who we are ?</h2>
                    <p className='text-justify'>https://www.getmovers.co.uk  was established by a group of people that have worked within the removals and storage industry for over 20 years and realised that these services can be delivered affordably without compromising on customer service through our local partners, all of our partners are fully experienced. </p>
                    <p className='text-justify'>We offer a range of services from local to international relocation, full packing and unpacking services and storage. We ensure all our partners are highly trained, professional and understand the stress that moving home can be on the customer. Our friendly staff are here to go the extra mile to ensure that the first day in your new home is a memorable one.</p>
                    </div>
            <Row>
              
            </Row>
          </Row>
        </Container> */}

        <Container fluid className="homepage-patter pt-5">
          <Container>
            <Row>
              <Col xs={12}>
                <h2 className="title1">Our Services</h2>
                <h2 className="title3">
                  At GetMovers, our goal is to make moving as cheap and
                  hassle-free as it possibly could be. With us, you get:
                </h2>
                <br />
              </Col>
              {this.ServicesCards}
            </Row>
          </Container>
        </Container>

        <Container fluid className="homepage-patter pt-5 pb-5">
          <Row>
            <img src={FooterImg} style={{ marginLeft: "0%", width: "100%" }} />
          </Row>
        </Container>
        <Container className="pt-1 pb-5 mt-1">
          <Row>
            <Col
              data-aos="fade-up"
              data-aos-duration="3000"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <h2 className="title2">Contact Us</h2>
              <h5 className="text-center">
                Interested? We'd love to hear from you; get in touch now at…
              </h5>
            </Col>
            <Col
              data-aos="fade-up"
              data-aos-duration="3000"
              className="pt-5"
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
            >
              <h5 className="text-orange">Feel Free to Contact Us</h5>
              <h6>
                <span className="text-blue contact-us-social-icons">
                  <AiOutlineHome />
                  Kemp House 152-160. City Road London, EC1V 2NX, United Kingdom
                </span>
              </h6>
              <h6>
                <span className="text-blue contact-us-social-icons">
                  <AiOutlineMail />
                  info@getmovers.co.uk
                </span>
              </h6>
              <h6>
                <span className="text-blue contact-us-social-icons">
                  <FiPhone />
                  0800 6358888
                </span>
              </h6>
              <h6>
                <span className="text-blue contact-us-social-icons">
                  <FaFax />
                  0800 6358889
                </span>
              </h6>
              <h5 className="text-orange mt-2">Reach out at:</h5>
              <p>
                <span className="text-blue contact-us-social-icons pointer">
                  <a
                    href="https://www.facebook.com/profile.php?id=100090028214453"
                    target="_blank"
                  >
                    <FaFacebook
                      style={{
                        color: "#00acdd",
                        margin: "5px",
                        fontSize: "24px",
                      }}
                    />
                  </a>
                  <a href="https://twitter.com/Get_Mover" target="_blank">
                    <AiFillTwitterCircle
                      style={{
                        color: "#00acdd",
                        margin: "5px",
                        fontSize: "24px",
                      }}
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/get_movers/"
                    target="_blank"
                  >
                    <FaInstagramSquare
                      style={{
                        color: "#00acdd",
                        margin: "5px",
                        fontSize: "24px",
                      }}
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/in/get-movers-84a373265/"
                    target="_blank"
                  >
                    <FaLinkedin
                      style={{
                        color: "#00acdd",
                        margin: "5px",
                        fontSize: "24px",
                      }}
                    />
                  </a>
                </span>
              </p>
            </Col>
            <Col
              data-aos="fade-up"
              data-aos-duration="3000"
              className="pt-5"
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              // eslint-disable-next-line react/jsx-no-comment-textnodes
            >
              <iframe
                src="https://maps.google.com/maps?q=152-160. City Road London, EC1V 2NX, United Kingdom&t=&z=6&ie=UTF8&iwloc=&output=embed"
                width="100%"
                height="250"
                frameborder="0"
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              ></iframe>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
export default Home;
