import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button'
import 'react-toastify/dist/ReactToastify.css';
import Table from 'react-bootstrap/Table'
import AOS from 'aos';
//import FurnitureData from '../../json/furnitureData';
import Form from 'react-bootstrap/Form'
import { toast, Zoom, Bounce} from 'react-toastify'

toast.configure()
class FurnitureItems extends React.Component {
  
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    const i=0;
  }
    state={
      data:[],
      FurnitureData:[],
      item1Price:this.props.location.state.item.item1[1],
      item2Price:this.props.location.state.item.item2[1],
      item3Price:this.props.location.state.item.item3[1],
      item4Price:this.props.location.state.item.item4[1],
      item5Price:this.props.location.state.item.item5[1],
    
  }
  
  componentDidMount(){

  }
  submit(){
    console.log(this.props.location.state.item.category)
    fetch("/updateFurniture",{
      method:"post",
      headers:{
        "content-Type":"application/json"
      },
      body:JSON.stringify({
        category:this.props.location.state.item.category,
        item1:[this.props.location.state.item.item1[0],parseFloat(this.state.item1Price)],
        item2:[this.props.location.state.item.item2[0],parseFloat(this.state.item2Price)],
        item3:[this.props.location.state.item.item3[0],parseFloat(this.state.item3Price)],
        item4:[this.props.location.state.item.item4[0],parseFloat(this.state.item4Price)],
        item5:[this.props.location.state.item.item5[0],parseFloat(this.state.item5Price)],
      })
    }).then(res=>res.json())
    .then((data)=>{
      console.log(data)
      toast.success("Updated Successfully",{
        draggable: true,
        hideProgressBar:true,
        transition: Zoom,
        autoClose:7000,
        position: toast.POSITION.TOP_CENTER  
      })
    })
  }
    render ()
      { 
        const details = ()=>{
          if(this.props.location.state.item.category==='Tables')
        {
          return(
            <>
                <tr class="d-flex" key={this.props.location.state.item._id} >
                  <td class="col-1">1</td>
                  <td class="col-3">{this.props.location.state.item.category}</td>
                  <td class="col-4">{this.props.location.state.item.item1[0]}</td> 
                  <td class="col-4">
                      <Form.Control size="sm" type="text"  value={this.state.item1Price} onChange={(e)=>this.setState({item1Price:e.target.value})}/>
                    </td>
                </tr>
                <tr class="d-flex" key={this.props.location.state.item._id} >
                <td class="col-1">2</td>
                  <td class="col-3">{this.props.location.state.item.category}</td>
                  <td class="col-4">{this.props.location.state.item.item2[0]}</td>
                  <td class="col-4">
                      <Form.Control size="sm" type="text"  value={this.state.item2Price} onChange={(e)=>this.setState({item2Price:e.target.value})}/>
                    </td>
                </tr>
                <tr class="d-flex" key={this.props.location.state.item._id} >
                <td class="col-1">3</td>
                  <td class="col-3">{this.props.location.state.item.category}</td>
                  <td class="col-4">{this.props.location.state.item.item3[0]}</td>
                  <td class="col-4">
                  <Form.Control size="sm" type="text"  value={this.state.item3Price} onChange={(e)=>this.setState({item3Price:e.target.value})}/>
                    </td>
                </tr>
                <tr class="d-flex" key={this.props.location.state.item._id} >
                <td class="col-1">4</td>
                  <td class="col-3">{this.props.location.state.item.category}</td>
                  <td class="col-4">{this.props.location.state.item.item4[0]}</td>
                  <td class="col-4">
                      <Form.Control size="sm" type="text"  value={this.state.item4Price} onChange={(e)=>this.setState({item4Price:e.target.value})}/>
                    </td>
                </tr> 
                <tr class="d-flex" key={this.props.location.state.item._id} >
                <td class="col-1">5</td>
                  <td class="col-3">{this.props.location.state.item.category}</td>
                  <td class="col-4">{this.props.location.state.item.item5[0]}</td>
                  <td class="col-4">
                      <Form.Control size="sm" type="text"  value={this.state.item5Price} onChange={(e)=>this.setState({item5Price:e.target.value})}/>
                    </td>
                </tr>
              </>
          )
        }
        else{
          return(
            <>
              <tr class="d-flex" key={this.props.location.state.item._id} >
                    <td class="col-1">1</td>
                    <td class="col-3">{this.props.location.state.item.category}</td>
                    <td class="col-4">{this.props.location.state.item.item1[0]}</td> 

                    <td class="col-4">
                      <Form.Control size="sm" type="text"  value={this.state.item1Price} onChange={(e)=>this.setState({item1Price:e.target.value})}/>
                      
                    </td>
                  </tr>
                  
                  <tr class="d-flex" key={this.props.location.state.item._id} >
                  <td class="col-1">2</td>
                  <td class="col-3">{this.props.location.state.item.category}</td>
                    <td class="col-4">{this.props.location.state.item.item2[0]}</td>
                    <td class="col-4">
                    <Form.Control size="sm" type="text"  value={this.state.item2Price} onChange={(e)=>this.setState({item2Price:e.target.value})}/>
                    </td>
                  </tr>
                  
                  <tr class="d-flex" key={this.props.location.state.item._id} >
                  <td class="col-1">3</td>
                  <td class="col-3">{this.props.location.state.item.category}</td>
                    <td class="col-4">{this.props.location.state.item.item3[0]}</td>
                    <td class="col-4">
                    <Form.Control size="sm" type="text"  value={this.state.item3Price} onChange={(e)=>this.setState({item3Price:e.target.value})}/>
                    </td>
                  </tr>
                </>
              )
            }
        }
          
          

      
        return(
          <>
          <Row className='admin-forms'>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h1 className='page-title'>Furniture and Other Items</h1>
              <hr/> 
            </Col>
            <Col className='pt-3' >
                <Table hover size="sm" className='text-left table admin-table' data-aos="fade-up" data-aos-duration="2000">
                  <thead className='bg-orange text-white'>
                    <tr class="d-flex">
                      <th class="col-1">Sr. No</th>
                     
                      <th class="col-3">Sub Category</th>
                      <th class="col-4">Item</th>
                      <th class="col-4">Price</th>
                    </tr>
                  </thead>      
                
                <tbody>
                    {details()}
                </tbody>
              </Table> 
            </Col>
            <Col xs={12} >
              <br/>
              <Button className="publish-btn" onClick={this.submit} >Update</Button>
            </Col>
          </Row>
        
        </>
        )
        
     
      
      }

}

export default FurnitureItems;
