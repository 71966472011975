import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button'
import 'react-toastify/dist/ReactToastify.css';
import Table from 'react-bootstrap/Table'
import AOS from 'aos';
import moment from 'moment';

class RefferedJobs extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      data:[],
      id:'',
      haveData:false
    }
  }
  
  componentDidMount()
  {
    this.Boiler()
  }

  Boiler =async() =>{
    const data1 = await JSON.parse(localStorage.getItem("getmoversuser"))
    console.log(data1._id)
    this.setState({id:data1._id})
    fetch("/getDriverRefferedRequests",{
      method:"post",
      headers:{
        "content-Type":"application/json"
      },
      body:JSON.stringify({
        'id':data1._id,
      })
    }).then(res=>res.json())
    .then(data=>
    {
      console.log(data)
     this.setState({
        data:data
      })
    }).catch(err=>{
      console.log(err)
    })
  }
  
  
    render ()
      { 
        const requests =this.state.data.map((item,index)=>{
          console.log(item)
          if(item.status==="Paid" && item.referredTo.includes(this.state.id))
          {
            if(this.state.haveData === false)
            {
            this.setState({haveData:true})
            }
          var link=''
          if(item.category==='Furniture and Other Items')
          {
            link='/request-furniture'
          }
          if(item.category==='Home Removals')
          {
            link='/request-home-removals'
          }
          if(item.category==='Pianos')
          {
            link='/request-piano'
          }
          if(item.category==='Cars and Vehicles')
          {
            link='/request-vehicles'
          }
          if(item.category==='Motorbikes')
          {
            link='/request-motorbikes'
          }
          if(item.category==='Parcels and Packages' || item.category==='Specialist and Antiques' || item.category==='Vehicle Parts' || item.category==='Office Removals')
          {
            link='/request-parcel-packages'
          } 
          return(
              <tr class="d-flex" key={item._id} >
              {/* <td class="col-1">{index+1}</td> */}
              <td class="col-3">{item.email}</td>
              <td class="col-3">{item.category}</td>
              <td class="col-3">{moment(item.date).format('DD MMMM YYYY')}</td>
              <td className="col-3 d-flex justify-content-center"><Button  className='table-view-btn' onClick={()=>this.props.history.push(link,{item})}>View</Button></td>
            </tr>
            
          )}
      })

      const requestss = () =>{
        if(this.state.haveData=== true)
        {
          console.log(this.state.data.length)
        return(
          <>
       <Row className='admin-forms'>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <h1 className='page-title'>New Jobs</h1>
                  <hr/>
                </Col>
                <Col className='pt-3' >
                    <Table hover size="sm" className='text-left' className="table admin-table" data-aos="fade-up" data-aos-duration="2000">
                      <thead className='bg-orange text-white'>
                        <tr class="d-flex">
                          {/* <th class="col-1">Sr. No</th> */}
                          <th class="col-3">Email</th>
                          <th class="col-3">Category</th>
                          <th class="col-3">Date</th>
                          <th class="col-3 text-center">Action</th>
                        </tr>
                      </thead>      
                    
                    <tbody>
                        {requests}
                    </tbody>
                  </Table> 
                </Col>
              </Row>
          </>
        )
        }
        else
        {
          return(
          <h5><span className='font-weight-bold text-orange'>No New Jobs </span></h5>
          )
        }
      }
     
      return(
        <>
           {requestss()}
        </>
      )

      }

}

export default RefferedJobs;
