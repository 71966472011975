import React , {useState, useEffect} from 'react'
import {Button} from 'antd';
import StripeCheckout from 'react-stripe-checkout'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useParams, useHistory} from 'react-router-dom'
import { toast, Zoom, Bounce} from 'react-toastify'
import { BarLoader, BeatLoader} from 'react-spinners'
import {Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';

toast.configure()
const Payment =(props) =>
{
    const {token} = useParams()
    const history = useHistory();
    const [driver,setDriver] = useState([])

    console.log(token)
    const linkToken = token;
    const [loading, setLoading] =useState(true)
    const [price,setPrice] = useState(0)
    const [id,setId] = useState('')
    const [paid,setPaid] = useState(false)
    //const [selectedDrivers,setSelectedDrivers]= useState([])
    const [lat1,setLat1] = useState(0)
    const [lng1,setLng1] = useState(0)
    var selectedDrivers=new Array();
    const [product, setProduct] = useState({
        name: "Removal Advance Fees",
        price:price,
        prodcutBy: 'Get Movers'
    })

    const makePayment = token =>
    {
        setLoading(true)
        console.log(price)
        const body ={
            token,
            product,
            linkToken
        }
        const headers={
            "Content-Type": "Application/json"
        }
        return fetch(`/payment`,{
            method:"POST",
            headers,
            body: JSON.stringify(body)
        }).then(response=>{
            console.log("Response", response)
            
              fetch("/alertDrivers",{
                method:"post",
                headers:{
                  "content-Type":"application/json"
                },
                body:JSON.stringify({
                selectedDrivers,
                linkToken
                })
              }).then(res=>res.json())
              .then(data=>{ 
                history.push('/')
                const {status}= response;
                console.log("STATUS", status)
                setLoading(false)
                toast.success("You Paid Successfully",{
                    draggable: true,
                    transition: Zoom,
                    hideProgressBar:true,
                    autoClose:7000,
                    position: toast.POSITION.TOP_CENTER
                  })
               })
        })
        .catch(error=>console.log("error",error))
    }
    const google = window.google;
    const distanceMatrix = new google.maps.DistanceMatrixService();

    useEffect(()=>{
      fetch("/getpaymentData",{
          method:"post",
          headers:{
            "content-Type":"application/json"
          },
          body:JSON.stringify({
          token,
          })
        })
        .then(res=>res.json())
        .then((data)=>{
            if(data==="Paid")
            {
              setLoading(false)
              setPaid(true)
            }
            else
            {
              setPrice(data[0])
              setProduct({price:data[0]})
              setLat1(parseFloat(data[1]))
              setLng1(parseFloat(data[2]))
              setLoading(false)
             // console.log("Final Price is ",data)
            }
        })
        fetch('/getApprovedDrivers')
        .then(res=>res.json())
        .then(res2=>{
            setDriver(res2)
           // console.log(res2)
        })
  },[])
  
  if(paid===false)
  {
    driver.map((item)=>{
    distanceMatrix.getDistanceMatrix({
      origins: [{lat:lat1,lng:lng1}],
      destinations: item.latlng,
      travelMode: 'DRIVING',
      avoidHighways: false,
      avoidTolls: false,
      unitSystem: window.google.maps.UnitSystem.IMPERIAL
    }, (response, status) => {
      if (status === 'OK') {
       // console.log("Total Milage", parseFloat(response.rows[0].elements[0].distance.text))
        var mileage = parseFloat(response.rows[0].elements[0].distance.text);
         if(mileage<=30)
         {
           console.log("mileage is", mileage)
           const drivers = [...selectedDrivers]
           drivers.push(item._id)
           selectedDrivers=drivers
          // setSelectedDrivers(drivers)
         }
      } else {
        window.alert('Directions request failed due to ' + status);
      }
    })
  })
}

    if(loading===true)
    {
      return(
      <div className="loading">
          <BeatLoader loading
            size={20}
            color="#f26c4f" />
          <BarLoader loading 
          css={{display:'block',margin:0}}
          size={40}
          color="#f26c4f"
          />
      </div>
      )
    }
    
    if(loading===false && paid=== true)
    {
      return(
        <div>
            <h1 style={{ marginTop:130,paddingBottom:230}} className='page-title'>You have already paid for this request thanks</h1>
        </div>
      )
    } 
  if(loading===false && paid=== false)
  {
    return(
        <Container>
            <Row>
                <Col md={{ offset:1}} lg="10">
                    <StripeCheckout
                    stripeKey = "pk_test_51Hla3sJbeDo0wh2dUz61eCPFRon7FP1ryS6eQjnkmwPSopGofXx8ixqehkGtTaHxEa7p6R2mXzfEGVOrWo0U6zaQ00mfd2Y5Ce"
                    token={makePayment}
                    name="Removal Request Advance"
                    amount={product.price*100}
                    shippingAddress
                    billingAddress
                    >
                        <h1 style={{ marginTop:100}} className='page-title'>Thank You for contacting Get Movers</h1>
                        <div style={{color:"#595959", fontWeight:'bold', textAlign:'center', fontSize:18, marginTop:30}}>Please pay the advance amount for further proceed.</div>
                        <Button style={{marginLeft:'40%',marginBottom:'10%', marginTop:'5%'}} onclick={()=>{console.log(selectedDrivers)}} className="driver-reg-btn" type="submit">
                            Pay £ {price.toFixed(2)}
                        </Button>
                    </StripeCheckout>
                </Col>
            </Row>
        </Container>
    );
  }
}
export default Payment;