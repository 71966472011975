import {
    Row,
    Col,
    Container,
    Button as BootstrapButton,
  } from "react-bootstrap";
  import img from "../img/caroline2.png";
  import Image from "react-bootstrap/Image";
  import React, { useState, Component } from "react";
  import { Form, Input, Button, Select, Checkbox } from "antd";
  import GoogleApiWrapper from "./googlemap/googleMaps";
  import { Tabs } from "antd";
  import { Cascader } from "antd";
  import { IoIosBed } from "react-icons/io";
  import { MdKitchen} from "react-icons/md";
  import {CiForkAndKnife}  from "react-icons/ci"
  import {AiOutlineShoppingCart ,AiOutlineCodeSandbox} from "react-icons/ai"
  import { FaBath ,FaChair } from "react-icons/fa";
  import { GrLounge } from "react-icons/gr";
  import PickupLocation from "./googlemap/pickupLocation";
  import DropoffLocation from "./googlemap/dropoffLocation";
  import { RiDeleteBack2Line } from "react-icons/ri";
  import ParcelAndPackages from "./parcelAndPackages";
  
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  const { TabPane } = Tabs;
  const options = [
    //menu first option
    {
      value: "House",
      label: "House",
      children: [
        {
          value: "One Bed",
          label: "1 Bed House",
        },
        {
          value: "Two Bed",
          label: "2 Bed House",
        },
        {
          value: "Three Bed",
          label: "3 Bed House",
        },
        {
          value: "Four Bed",
          label: "4+ Bed House",
        },
      ],
    },
    //menu second option
    {
      value: "Flat",
      label: "Flat",
      children: [
        {
          value: "One Bed Flat",
          label: "1 Bed Flat",
        },
        {
          value: "Two Bed Flat",
          label: "2 Bed Flat",
        },
        {
          value: "Three Bed Flat",
          label: "3 Bed Flat",
        },
        {
          value: "Four Bed Flat",
          label: "4+ Bed Flat",
        },
      ],
    },
    //menu third option
    {
      value: "other",
      label: "Other",
      children: [
        {
          value: "Studio",
          label: "Studio",
        },
        {
          value: "Storage",
          label: "Storage Unit",
        },
        {
          value: "other Flat",
          label: "Flat Share",
        },
      ],
    },
  ];
  class Manvan extends Component {
    constructor(props) {
      super(props);
      this.submit = this.submit.bind(this);
    }
    state = {
      show: false,
      subCategory: false,
      map: true,
      products1: [],
      dropoffFloor: "",
      pickupFloor: "",
      bedroomArray: [
        {
          DoubleBedAndMattress: "",
          KingsizeBedAndMattress: "",
          SingleWardrobe: "",
          ChestOfDrawers: "",
          BedsideTable: "",
          DressingTable: "",
          Television: "",
          SingleBedAndMattress: "",
          Cot: "",
          Bookcase: "",
          DoubleWardrobe: "",
          Chair: "",
        },
      ],
      manArray: [
        {
         Person: "",
         Person2: "",
         Person3: "",
          
        },
      ],
      gardenArray:[
          {
              GardenChair:'',
              GardenTable:'',
              Bicycle:'',
              LawnMower:'',
              Bbq:'',
              ToolBox:'',
              SmallPottedPlant:'',
              LargePottedPlant:'',
              PlantPot:'',
              Ladder:'',
          
          }
              ],
      kitchenArray: [
        {
          FridgeFreezer: "",
          WashingMachine: "",
          MicrowaveOven: "",
          Cooker: "",
          Dishwasher: "",
          KitchenTable: "",
          DiningChair: "",
          Bin: "",
        },
      ],
      diningArray: [
        {
          DiningChair:'',
          SeaterDiningTable:'',
          SmallTable:'',
          Sideboard:'',
          Bench:'',
          DisplayCabinet:'',
          Rug:'',
          LargeMirror:'',
  
        }
      ],
      officeArray: [
        {
          Desk:'',
          OfficeChair:'',
          Printer:'',
          Bookcase:'',
          FilingCabinet:'',
          DisplayCabinet:'',
          Lamp:'',
          Computer:'',
          Monitor:'',
          StorageUnit:'',
  
        }
      ],
      tvLoungeArray: [
        {
          TwoSeaterSofa: "",
          ThreeSeaterSofa: "",
          Armchair: "",
          CoffeeTable: "",
          SmallTV: "",
          LargeTV: "",
          Bookcase: "",
          Rug: "",
          Desk: "",
          OfficeChair: "",
          Artwork: "",
        },
      ],
      
      bathroomArray: [
        {
          LargeMirror: "",
          SmallMirror: "",
          Rug: "",
          BathroomCabinet: "",
          BathTub: "",
          Basket: "",
          Shelf: "",
          StorageUnit: "",
          ClothesHorse: "",
          Bin: "",
        },
      ],
      boxArray: [
        {
          SmallBoxes: "",
          LargeBoxes: "",
        },
      ],
      roomNumber: 0,
      itemsInBedroom: [],
      itemsInMan:[],
      itemsInKitchen: [],
      itemsInDining:[],
      itemsInTVLounge: [],
      itemsInGarden:[],
      itemsInBox:[],
      itemsInOffice:[],
      itemsInBathroom: [],
      
      mileage: "",
      distanceCost: 0,
      priceCalculation: 0,
    };
  
    handleShow = () => this.setState({ show: true });
  
    componentDidMount() {
      fetch("/getBedroom")
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            itemsInBedroom: data,
          });
          console.log("Distance Data is ", data);
        });
        fetch("/getMan")
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            itemsInMan: data,
          });
          console.log("Distance Data is ", data);
        });
      fetch("/getKitchen")
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            itemsInKitchen: data,
          });
          console.log("Distance Data is ", data);
        });
        fetch("/getGarden")
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            itemsInGarden: data,
          });
          console.log("Distance Data is ", data);
        });
        fetch("/getBox")
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            itemsInBox: data,
          });
          console.log("Distance Data is ", data);
        });
        fetch("/getOffice")
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            itemsInOffice: data,
          });
          console.log("Distance Data is ", data);
        });
        fetch("/getDining")
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            itemsInDining: data,
          });
          console.log("Distance Data is ", data);
        });
      fetch("/getBathroom")
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            itemsInBathroom: data,
          });
          console.log("Distance Data is ", data);
        });
      fetch("/getTvLounge")
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            itemsInTVLounge: data,
          });
          console.log("Distance Data is ", data);
        });
      fetch("/getDistanceCost")
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            distanceCost: data.cost,
          });
          console.log("Distance Data is ", data.cost);
        });
    }
  
    handleMile = (val) => {
      const miles = val.replace("mi", "");
      this.setState({
        mileage: miles + " miles",
      });
      console.log("Value from Furni", val);
    };
  
    itemsInBedroom1 = [
      { id: "1", value: "Chest Of Drawers", name: "ChestOfDrawers" },
      { id: "2", value: "Double Bed & Mattress", name: "DoubleBedAndMattress" },
      {
        id: "3",
        value: "Kingsize Bed & Mattress",
        name: "KingsizeBedAndMattress",
      },
      { id: "4", value: "Single Wardrobe", name: "SingleWardrobe" },
      { id: "5", value: "Bedside Table", name: "BedsideTable" },
      { id: "6", value: "Dressing Table", name: "DressingTable" },
      { id: "7", value: "Television", name: "Television" },
      { id: "8", value: "Single Bed & Mattress", name: "SingleBedAndMattress" },
      { id: "9", value: "Cot", name: "Cot" },
      { id: "10", value: "Bookcase", name: "Bookcase" },
      { id: "11", value: "Double Wardrobe", name: "DoubleWardrobe" },
      { id: "12", value: "Chair", name: "Chair" },
    ];
    itemsInMan1 = [
      { id: "1", value: "person 1", name: "person 1" },
      { id: "2", value: "person 2", name: "person 2" },
      { id: "3", value: "person 3", name: "person 3" },
    ];
    itemsInKitchen1 = [
      { id: "1", value: "Fridge Freezer", name: "FridgeFreezer" },
      { id: "2", value: "Washing Machine", name: "WashingMachine" },
      { id: "3", value: "Microwave Oven", name: "MicrowaveOven" },
      { id: "4", value: "Cooker", name: "Cooker" },
      { id: "5", value: "Dishwasher", name: "Dishwasher" },
      { id: "6", value: "Kitchen Table", name: "KitchenTable" },
      { id: "7", value: "Dining Chair", name: "DiningChair" },
      { id: "8", value: "Bin", name: "Bin" },
      { id: "9", value: "Tumble Dryer", name: "TumbleDryer" },
      { id: "10", value: "Ironing Board", name: "IroningBoard" },
      { id: "11", value: "Chest Freezer", name: "ChestFreezer" },
      { id: "12", value: "Under Counter Fridge", name: "UnderCounterFridge" },
    ];
    itemsInTVLounge1 = [
      { id: "1", value: "Two Seater Sofa", name: "TwoSeaterSofa" },
      { id: "2", value: "Three Seater Sofa", name: "ThreeSeaterSofa" },
      { id: "3", value: "Armchair", name: "Armchair" },
      { id: "4", value: "Coffee Table", name: "CoffeeTable" },
      { id: "5", value: "Small TV (Less Than 30``)", name: "SmallTV" },
      { id: "6", value: "Large TV (Greater Than 40``)", name: "LargeTV" },
      { id: "7", value: "Bookcase", name: "Bookcase" },
      { id: "8", value: "Rug", name: "Rug" },
      { id: "9", value: "Desk", name: "Desk" },
      { id: "10", value: "Office Chair", name: "OfficeChair" },
      { id: "11", value: "Artwork", name: "Artwork" },
      { id: "12", value: "Nest Of Tables", name: "NestOfTables" },
      { id: "13", value: "Floor Lamp", name: "FloorLamp" },
      { id: "14", value: "Side Table", name: "SideTable" },
    ];
    itemsInBathroom1 = [
      { id: "1", value: "Large Mirror", name: "LargeMirror" },
      { id: "2", value: "Small Mirror", name: "SmallMirror" },
      { id: "3", value: "Rug", name: "Rug" },
      { id: "4", value: "Bathroom Cabinet", name: "BathroomCabinet" },
      { id: "5", value: "Bath Tub", name: "BathTub" },
      { id: "6", value: "Basket", name: "Basket" },
      { id: "7", value: "Shelf", name: "Shelf" },
      { id: "8", value: "Storage Unit", name: "StorageUnit" },
      { id: "9", value: "Towel Rail", name: "TowelRail" },
      { id: "10", value: "Clothes Horse", name: "ClothesHorse" },
      { id: "11", value: "Bin", name: "Bin" },
    ];
    itemsInGarden1=[
      { id: "1", value: "Garden Chair", name: "GardenChair" },
      { id: "2", value: "Garden Table", name: "GardenTable" },
      { id: "3", value: "BIcycle", name: "Bicycle" },
      { id: "4", value: "Lawn Mower", name: "LawnMower" },
      { id: "5", value: "Bbq", name: "Bbq" },
      { id: "6", value: "Tool Box", name: "ToolBox" },
      { id: "7", value: "Small Potted Plant", name: "SmallPottedPlant" },
      { id: "8", value: "Large Potted Plant", name: "LargePottedPlant" },
      { id: "9", value: "Plant Pot", name: "PlantPot" },
      { id: "10", value: "Ladder", name: "Ladder" },
  
    ]
   
    itemsInDining1=[
      { id: "1", value: "Dining Chair", name: "DiningChair" },
      { id: "2", value: "4 Seater Dining Table", name: "4SeaterDiningTable" },
      { id: "3", value: "6 Seater Dining Table", name: "6SeaterDiningTable" },
      { id: "4", value: "8 Seater Dining Table", name: "8SeaterDiningTable" },
      { id: "5", value: "Small Table", name: "SmallTable" },
      { id: "6", value: "Sideboard", name: "Sideboard" },
      { id: "7", value: "Bench", name: "Bench" },
      { id: "8", value: "Display Cabinet", name: "DisplayCabinet" },
      { id: "9", value: "Rug", name: "Rug" },
      { id: "10", value: "Large Mirror", name: "LargeMirror" },
  
    ]
    itemsInOffice1=[
      { id: "1", value: "Desk", name: "Desk" },
      { id: "2", value: "Office Chair", name: "OfficeChair" },
      { id: "3", value: "Printer", name: "Printer" },
      { id: "4", value: "Bookcase", name: "Bookcase" },
      { id: "5", value: "Filing Cabinet", name: "FilingCabinet" },
      { id: "6", value: "Display Cabinet", name: "DisplayCabinet" },
      { id: "7", value: "Lamp", name: "Lamp" },
      { id: "8", value: "Computer", name: "Computer" },
      { id: "9", value: "Monitor", name: "Monitor" },
      { id: "10", value: "Storage Unit", name: "StorageUnit" },
  
    ]
    itemsInBox1=[
      { id: "1", value: "2 Small Boxes", name: "2SmallBoxes" },
      { id: "2", value: "4 Small Boxes", name: "4SmallBoxes" },
      { id: "3", value: "6 Small Boxes", name: "6SmallBoxes" },
  { id: "4", value: "2Large BOxes", name: "2LargeBoxes" },
  { id: "5", value: "4Large BOxes", name: "4LargeBoxes" },
  { id: "6", value: "6Large BOxes", name: "6LargeBoxes" },
  { id: "7", value: "8Large BOxes", name: "8LargeBoxes" },
  
  
    ]
  
    handleChangeInput(type, e, index1, price) {
      if (e.target.checked) {
        const pricCal = this.state.priceCalculation + price;
        this.setState({
          priceCalculation: pricCal,
        });
        console.log(pricCal);
        if (type === "bedroom") {
          const values = [...this.state.bedroomArray];
          values[index1][e.target.name] = e.target.value;
          this.setState({ bedroomArray: values });
          console.log("Added to Bedroom", this.state.bedroomArray);
          
        } 
        else if (type === "man") {
          const values = [...this.state.manArray];
          values[index1][e.target.name] = e.target.value;
          this.setState({ manArray: values });
          console.log("Added to man", this.state.manArray);
          
        } 
         else if (type === "kitchen") {
          const values = [...this.state.kitchenArray];
          values[index1][e.target.name] = e.target.value;
          this.setState({ kitchenArray: values });
          console.log("Added to Kitchen", this.state.kitchenArray);
        }else if (type === "dining") {
          const values = [...this.state.diningArray];
          values[index1][e.target.name] = e.target.value;
          this.setState({ diningArray: values });
          console.log("Added to dining", this.state.diningArray);
        }
        else if (type === "office") {
          const values = [...this.state.officeArray];
          values[index1][e.target.name] = e.target.value;
          this.setState({ officeArray: values });
          console.log("Added to office", this.state.officeArray);
        }
         
        else if (type === "tvLounge") {
          const values = [...this.state.tvLoungeArray];
          values[index1][e.target.name] = e.target.value;
          this.setState({ tvLoungeArray: values });
          console.log("Added to TV Lounge", this.state.tvLoungeArray);
        }
        else if (type === "bathroom") {
          const values = [...this.state.bathroomArray];
          values[index1][e.target.name] = e.target.value;
          this.setState({ bathroomArray: values });
          console.log("Added to Bathroom", this.state.bathroomArray);
        }
        else if (type === "garden") {
          const values = [...this.state.gardenArray];
          values[index1][e.target.name] = e.target.value;
          this.setState({ gardenArray: values });
          console.log("Added to garden", this.state.gardenArray);
        }
        else if (type === "box") {
          const values = [...this.state.boxArray];
          values[index1][e.target.name] = e.target.value;
          this.setState({ boxArray: values });
          console.log("Added to box", this.state.boxArray);
        }
      }
  
      if (!e.target.checked) {
        const pricCal = this.state.priceCalculation - price;
        this.setState({
          priceCalculation: pricCal,
        });
        console.log(pricCal);
        if (type === "bedroom") {
          const values = [...this.state.bedroomArray];
          values[index1][e.target.name] = "";
          this.setState({ bedroomArray: values });
          console.log("Deleted from Bedroom", this.state.bedroomArray);
        } else if (type === "man") {
          const values = [...this.state.manArray];
          values[index1][e.target.name] = "";
          this.setState({ manArray: values });
          console.log("Deleted from man", this.state.manArray);
        } else if (type === "kitchen") {
          const values = [...this.state.kitchenArray];
          values[index1][e.target.name] = "";
          this.setState({ kitchenArray: values });
          console.log("Deleted from kitchen", this.state.kitchenArray);
        } else if (type === "tvLounge") {
          const values = [...this.state.tvLoungeArray];
          values[index1][e.target.name] = "";
          this.setState({ tvLoungeArray: values });
          console.log("Deleted from tvLounge", this.state.tvLoungeArray);
        } 
        else if (type === "dining") {
          const values = [...this.state.diningArray];
          values[index1][e.target.name] = "";
          this.setState({ diningArray: values });
          console.log("Deleted from dining", this.state.diningArray);
        }   
        else if (type === "office") {
          const values = [...this.state.officeArray];
          values[index1][e.target.name] = "";
          this.setState({ officeArray: values });
          console.log("Deleted from office", this.state.officeArray);
        }    
        else if (type === "bathroom") {
          const values = [...this.state.bathroomArray];
          values[index1][e.target.name] = "";
          this.setState({ bathroomArray: values });
          console.log("Deleted from bathroom", this.state.bathroomArray);
        }
        else if (type === "garden") {
          const values = [...this.state.gardenArray];
          values[index1][e.target.name] = "";
          this.setState({ gardenArray: values });
          console.log("Deleted from garden", this.state.gardenArray);
        }
        else if (type === "box") {
          const values = [...this.state.boxArray];
          values[index1][e.target.name] = "";
          this.setState({ boxArray: values });
          console.log("Deleted from box", this.state.boxArray);
        }
      }
    }
    HandleAddInput = (type) => {
      if (type === "bedroom")
        this.setState({
          bedroomArray: [
            ...this.state.bedroomArray,
            {
              DoubleBedAndMattress: "",
              KingsizeBedAndMattress: "",
              SingleWardrobe: "",
              ChestOfDrawers: "",
              BedsideTable: "",
              DressingTable: "",
              Television: "",
              SingleBedAndMattress: "",
              Cot: "",
              Bookcase: "",
              DoubleWardrobe: "",
              Chair: "",
            },
          ],
        });
        if (type === "man")
        this.setState({
          manArray: [
            ...this.state.manArray,
            {
              Person1: "",
              Person2: "",
              Person3: "",
  
            },
          ],
        });
      else if (type === "kitchen")
        this.setState({
          kitchenArray: [
            ...this.state.kitchenArray,
            {
              FridgeFreezer: "",
              WashingMachine: "",
              MicrowaveOven: "",
              Cooker: "",
              Dishwasher: "",
              KitchenTable: "",
              DiningChair: "",
              Bin: "",
            },
          ],
        });
        else if (type === "Dining")
        this.setState({
          diningArray: [
            ...this.state.diningArray,
            {
              DiningChair:'',
              SeaterDiningTable:'',
              SmallTable:'',
              Sideboard:'',
              Bench:'',
              DisplayCabinet:'',
              Rug:'',
              LargeMirror:'',
      
            }
          ],
        });
        else if (type === "box")
        this.setState({
          boxArray: [
            ...this.state.boxArray,
            {
              SmallBoxes:'',
              LargeBoxes:'',
            }
          ],
        });
        else if (type === "office")
        this.setState({
          officeArray: [
            ...this.state.officeArray,
            {
              Desk:'',
              OfficeChair:'',
              Printer:'',
              Bookcase:'',
              FilingCabinet:'',
              DisplayCabinet:'',
              Lamp:'',
              Computer:'',
              Monitor:'',
              StorageUnit:'',
      
            }
          ],
        });
      else if (type === "tvLounge")
        this.setState({
          tvLoungeArray: [
            ...this.state.tvLoungeArray,
            {
              TwoSeaterSofa: "",
              ThreeSeaterSofa: "",
              Armchair: "",
              CoffeeTable: "",
              SmallTV: "",
              LargeTV: "",
              Bookcase: "",
              Rug: "",
              Desk: "",
              OfficeChair: "",
              Artwork: "",
            },
          ],
        });
      else if (type === "bathroom")
        this.setState({
          bathroomArray: [
            ...this.state.bathroomArray,
            {
              LargeMirror: "",
              SmallMirror: "",
              Rug: "",
              BathroomCabinet: "",
              BathTub: "",
              Basket: "",
              Shelf: "",
              StorageUnit: "",
              ClothesHorse: "",
              Bin: "",
            },
          ],
        });
        else if (type === "garden")
        this.setState({
          gardenArray: [
            ...this.state.gardenArray,
            {
              GardenChair:'',
              GardenTable:'',
              Bicycle:'',
              LawnMower:'',
              Bbq:'',
              ToolBox:'',
              SmallPottedPlant:'',
              LargePottedPlant:'',
              PlantPot:'',
              Ladder:'',
          
          }
          ],
        });
  
    };
  
    HandleRemoveClick(type) {
      if (type === "bedroom") {
        const values = [...this.state.bedroomArray];
        values.splice(-1, 1);
        this.setState({ bedroomArray: values });
        console.log("Deleted from Bedroom", this.state.bedroomArray);
      }else if (type === "man") {
        const values = [...this.state.manArray];
        values.splice(-1, 1);
        this.setState({ manArray: values });
        console.log("Deleted from man", this.state.manArray);
      } else if (type === "kitchen") {
        const values = [...this.state.kitchenArray];
        values.splice(-1, 1);
        this.setState({ kitchenArray: values });
        console.log("Deleted from kitchen", this.state.kitchenArray);
      }else if (type === "dining") {
        const values = [...this.state.diningArray];
        values.splice(-1, 1);
        this.setState({ diningArray: values });
        console.log("Deleted from dining", this.state.diningArray);
      }
      else if (type === "office") {
        const values = [...this.state.officeArray];
        values.splice(-1, 1);
        this.setState({ officeArray: values });
        console.log("Deleted from office", this.state.officeArray);
      }
       else if (type === "tvLounge") {
        const values = [...this.state.tvLoungeArray];
        values.splice(-1, 1);
        this.setState({ tvLoungeArray: values });
        console.log("Deleted from tvLounge", this.state.tvLoungeArray);
      }
      else if (type === "bathroom") {
        const values = [...this.state.bathroomArray];
        values.splice(-1, 1);
        this.setState({ bathroomArray: values });
        console.log("Deleted from bathroom", this.state.bathroomArray);
      }
      else if (type === "garden") {
          const values = [...this.state.gardenArray];
          values.splice(-1, 1);
          this.setState({ gardenArray: values });
          console.log("Deleted from garden", this.state.gardenArray);
        }
        else if (type === "box") {
          const values = [...this.state.boxArray];
          values.splice(-1, 1);
          this.setState({ boxArray: values });
          console.log("Deleted from box", this.state.boxArray);
        }
    }
    onChange(i, e) {
      if (e.target.checked) {
        const values = [...this.state.items];
        values.push({ room: i, item: e.target.value, qty: 1 });
        this.setState({ items: values });
      }
      if (!e.target.checked) {
        const values = [...this.state.items];
        const index = values.findIndex((x) => x.item === e.target.value);
        if (index !== -1) {
          values.splice(index, 1);
          this.setState({ items: values });
        }
      }
    }
  
    submit(e) {
  console.log(this.state.bedroomArray)
  console.log("garden:::::",this.state.gardenArray)
  
      const { dropoffFloor, pickupFloor, other } = e;
      this.props.history.push("/email-prices", {
        category: this.props.location.state.category,
        pickupLoc: this.props.location.state.pickupLoc,
        dropoffLoc: this.props.location.state.dropoffLoc,
        lat1: this.props.location.state.lat1,
        lng1: this.props.location.state.lng1,
        lat2: this.props.location.state.lat2,
        lng2: this.props.location.state.lng2,
        dropoffFloor: dropoffFloor,
        pickupFloor: pickupFloor,
        otherItem: other,
        bedroom: this.state.bedroomArray,
        man: this.state.manArray,
        kitchen: this.state.kitchenArray,
        dining:this.state.diningArray,
        office:this.state.officeArray,
        tvlounge: this.state.tvLoungeArray,
        bathroom: this.state.bathroomArray,
        box:this.state.boxArray,
        garden:this.state.gardenArray,
        mileage: this.state.mileage,
        distanceCost: this.state.distanceCost,
        priceCalculation: this.state.priceCalculation,
      });
  
      //we will just send this.state.products1 on next page we don't need to do all that stuff which you do above
    }
    selectFloor = (
      <Select placeholder="Select Floor">
        <Select.Option value="basement" className="movingItemsDropdown">
          Basement
        </Select.Option>
        <Select.Option value="groundfloor" className="movingItemsDropdown">
          Ground Floor
        </Select.Option>
        <Select.Option value="firstfloor" className="movingItemsDropdown">
          1st Floor
        </Select.Option>
        <Select.Option value="secondfloor" className="movingItemsDropdown">
          2nd Floor
        </Select.Option>
        <Select.Option value="thirdfloor" className="movingItemsDropdown">
          3rd Floor
        </Select.Option>
        <Select.Option value="fourfloor" className="movingItemsDropdown">
          4rth Floor
        </Select.Option>
        <Select.Option value="fifthfloor" className="movingItemsDropdown">
          5th Floor
        </Select.Option>
        <Select.Option value="sixfloor" className="movingItemsDropdown">
          6th Floor
        </Select.Option>
      </Select>
    );
    render() {
      const layout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
      };
      return (
        <>
          <Container fluid className="homepage-pattern">
            <Container className="pb-5 pt-5">
              <br />
              <Row className="mt-5">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <h2 className="title1">{this.props.location.state.category}</h2>
                  <br />
                </Col>
                <Col
                  className="bg-white shadow-sm"
                  xs={12}
                  sm={12}
                  md={9}
                  lg={9}
                  xl={9}
                >
                  <Form
                    className="pt-5 pb-5 pr-3 pl-3"
                    {...layout}
                    onFinish={this.submit}
                  >
                    <Row>
                      <Col>
                        <Form.Item
                          name="pickupFloor"
                          label="Please Select Pickup Floor"
                          rules={[
                            {
                              required: true,
                              message: "Please select Pick Up Floor",
                            },
                          ]}
                        >
                          {this.selectFloor}
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          name="dropoffFloor"
                          label="Please Select Dropoff Floor"
                          rules={[
                            {
                              required: true,
                              message: "Please select Delivery Floor",
                            },
                          ]}
                        >
                          {this.selectFloor}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Tabs defaultActiveKey="9">
                        <TabPane
                            key="9"
                            tab={
                              <span className="tab-icons font-weight-bold" style={{fontSize:"10px"}}>
                                {" "}
                                <IoIosBed /> 
                                <span>MAN</span>
                              </span>
                            }
                          >
                            {this.state.manArray.map((e1, key) => {
                              return (
                                <>
                                  <Row className="p-2">
                                    <Col className="bg-alternate-tabs">
                                      <h6 className="mt-1 text-blue" >
                                        Mans Required For Removal
                                      </h6>
                                    </Col>
  
                                    {this.state.itemsInMan.map((item) => {
                                      console.log("this.state.itemsInMan:::",this.state.itemsInMan)
                                      return (
                                        <Col xs={12} className="pb-2 pt-2">
                                          <Checkbox
                                            name={item.name}
                                            value={item.value}
                                            onChange={(e) =>
                                              this.handleChangeInput(
                                                "man",
                                                e,
                                                key,
                                                item.price
                                              )
                                            }
                                          >
                                            {item.value}
                                          </Checkbox>
                                        </Col>
                                      );
                                    })}
                                  </Row>
                                </>
                              );
                            })}
                            <br />
                            
                          </TabPane>
                          
                          <TabPane
                            key="1"
                            tab={
                              <span className="tab-icons font-weight-bold" style={{fontSize:"10px"}}>
                                {" "}
                                <IoIosBed /> 
                                <span>BEDROOM</span>
                              </span>
                            }
                          >
                            {this.state.bedroomArray.map((e1, key) => {
                              return (
                                <>
                                  <Row className="p-2">
                                    <Col className="bg-alternate-tabs">
                                      <h6 className="mt-1 text-blue" >
                                        Bedroom : {key + 1}
                                      </h6>
                                    </Col>
  
                                    {this.state.itemsInBedroom.map((item) => {
                                      console.log("this.state.itemsInBedroom:::",this.state.itemsInBedroom)
                                      return (
                                        <Col xs={12} className="pb-2 pt-2">
                                          <Checkbox
                                            name={item.name}
                                            value={item.value}
                                            onChange={(e) =>
                                              this.handleChangeInput(
                                                "bedroom",
                                                e,
                                                key,
                                                item.price
                                              )
                                            }
                                          >
                                            {item.value}
                                          </Checkbox>
                                        </Col>
                                      );
                                    })}
                                  </Row>
                                </>
                              );
                            })}
                            <br />
                            <BootstrapButton
                              className="mr-1 add-btn"
                              onClick={() => this.HandleAddInput("bedroom")}
                            >
                              Add Room
                            </BootstrapButton>
                            <BootstrapButton
                              className="remove-btn"
                              onClick={() => {
                                this.HandleRemoveClick("bedroom");
                              }}
                            >
                              Delete Room
                            </BootstrapButton>
                          </TabPane>
                          
                          <TabPane
                            key="2"
                            tab={
                              <span className="tab-icons font-weight-bold" style={{fontSize:"10px"}}>
                                {" "}
                                <MdKitchen /> KITCHEN{" "}
                              </span>
                            }
                          >
                            {this.state.kitchenArray.map((hh, key) => {
                              return (
                                <>
                                  <Row className="p-2">
                                    <Col className="bg-alternate-tabs">
                                      <h6 className="mt-1 text-blue">
                                        Kitchen : {key + 1}
                                      </h6>
                                    </Col>
                                    {this.state.itemsInKitchen.map((item) => {
                                      return (
                                        <Col xs={12} className="pb-2 pt-2">
                                          <Checkbox
                                            name={item.name}
                                            value={item.value}
                                            onChange={(e) =>
                                              this.handleChangeInput(
                                                "kitchen",
                                                e,
                                                key,
                                                item.price
                                              )
                                            }
                                          >
                                            {item.value}
                                          </Checkbox>
                                        </Col>
                                      );
                                    })}
                                  </Row>
                                </>
                              );
                            })}
                            <br />
                            <BootstrapButton
                              className="mr-1 add-btn"
                              onClick={() => this.HandleAddInput("kitchen")}
                            >
                              Add Kitchen
                            </BootstrapButton>
                            <BootstrapButton
                              className="remove-btn"
                              onClick={() => {
                                this.HandleRemoveClick("kitchen");
                              }}
                            >
                              Delete Kitchen
                            </BootstrapButton>
                          </TabPane>
                          <TabPane
                            key="6"
                            tab={
                              <span className="tab-icons font-weight-bold" style={{fontSize:"10px"}}>
                                {" "}
                              <CiForkAndKnife/> DINING{" "}
                              </span>
                            }
                          >
                            {this.state.diningArray.map((hh, key) => {
                              return (
                                <>
                                  <Row className="p-2">
                                    <Col className="bg-alternate-tabs">
                                      <h6 className="mt-1 text-blue">
                                        Dining : {key + 1}
                                      </h6>
                                    </Col>
                                    {this.state.itemsInDining.map((item) => {
                                      return (
                                        <Col xs={12} className="pb-2 pt-2">
                                          <Checkbox
                                            name={item.name}
                                            value={item.value}
                                            onChange={(e) =>
                                              this.handleChangeInput(
                                                "dining",
                                                e,
                                                key,
                                                item.price
                                              )
                                            }
                                          >
                                            {item.value}
                                          </Checkbox>
                                        </Col>
                                      );
                                    })}
                                  </Row>
                                </>
                              );
                            })}
                            <br />
                            <BootstrapButton
                              className="mr-1 add-btn"
                              onClick={() => this.HandleAddInput("dining")}
                            >
                              Add Dining
                            </BootstrapButton>
                            <BootstrapButton
                              className="remove-btn"
                              onClick={() => {
                                this.HandleRemoveClick("dining");
                              }}
                            >
                              Delete Dining
                            </BootstrapButton>
                          </TabPane>
                          <TabPane
                            key="3"
                            tab={
                              <span className="tab-icons font-weight-bold" style={{fontSize:"10px"}}>
                                <GrLounge /> LIVING
                              </span>
                            }
                          >
                            {this.state.tvLoungeArray.map((hh, key) => {
                              return (
                                <>
                                  <Row className="p-2">
                                    <Col className="bg-alternate-tabs">
                                      <h6 className="mt-1 text-blue">
                                      Living : {key + 1}
                                      </h6>
                                    </Col>
                                    {this.state.itemsInTVLounge.map((item) => {
                                      return (
                                        <Col xs={12} className="pb-2 pt-2">
                                          <Checkbox
                                            name={item.name}
                                            value={item.value}
                                            onChange={(e) =>
                                              this.handleChangeInput(
                                                "tvLounge",
                                                e,
                                                key,
                                                item.price
                                              )
                                            }
                                          >
                                            {item.value}
                                          </Checkbox>
                                        </Col>
                                      );
                                    })}
                                  </Row>
                                </>
                              );
                            })}
                            <br />
                            <BootstrapButton
                              className="mr-1 add-btn"
                              onClick={() => this.HandleAddInput("tvLounge")}
                            >
                              Add Living
                            </BootstrapButton>
                            <BootstrapButton
                              className="remove-btn"
                              onClick={() => {
                                this.HandleRemoveClick("tvLounge");
                              }}
                            >
                              Delete Living
                            </BootstrapButton>
                          </TabPane>
                          <TabPane
                            key="7"
                            tab={
                              <span className="tab-icons font-weight-bold" style={{fontSize:"10px"}}>
                                {" "}
                                <FaChair/> OFFICE{" "}
                              </span>
                            }
                          >
                            {this.state.officeArray.map((hh, key) => {
                              return (
                                <>
                                  <Row className="p-2">
                                    <Col className="bg-alternate-tabs">
                                      <h6 className="mt-1 text-blue">
                                        Office : {key + 1}
                                      </h6>
                                    </Col>
                                    {this.state.itemsInOffice.map((item) => {
                                      return (
                                        <Col xs={12} className="pb-2 pt-2">
                                          <Checkbox
                                            name={item.name}
                                            value={item.value}
                                            onChange={(e) =>
                                              this.handleChangeInput(
                                                "office",
                                                e,
                                                key,
                                                item.price
                                              )
                                            }
                                          >
                                            {item.value}
                                          </Checkbox>
                                        </Col>
                                      );
                                    })}
                                  </Row>
                                </>
                              );
                            })}
                            <br />
                            <BootstrapButton
                              className="mr-1 add-btn"
                              onClick={() => this.HandleAddInput("office")}
                            >
                              Add Office
                            </BootstrapButton>
                            <BootstrapButton
                              className="remove-btn"
                              onClick={() => {
                                this.HandleRemoveClick("office");
                              }}
                            >
                              Delete Office
                            </BootstrapButton>
                          </TabPane>
                          <TabPane
                            key="4"
                            tab={
                              <span className="tab-icons font-weight-bold" style={{fontSize:"10px"}}>
                                <FaBath /> BATHROOM
                              </span>
                            }
                          >
                            {this.state.bathroomArray.map((hh, key) => {
                              return (
                                <>
                                  <Row className="p-2">
                                    <Col className="bg-alternate-tabs">
                                      <h6 className="mt-1 text-blue">
                                        Bathroom : {key + 1}
                                      </h6>
                                    </Col>
                                    {this.state.itemsInBathroom.map((item) => {
                                      return (
                                        <Col xs={12} className="pb-2 pt-2">
                                          <Checkbox
                                            name={item.name}
                                            value={item.value}
                                            onChange={(e) =>
                                              this.handleChangeInput(
                                                "bathroom",
                                                e,
                                                key,
                                                item.price
                                              )
                                            }
                                          >
                                            {item.value}
                                          </Checkbox>
                                        </Col>
                                      );
                                    })}
                                  </Row>
                                </>
                              );
                            })}
                            <br />
                            <BootstrapButton
                              className="mr-1 add-btn"
                              onClick={() => this.HandleAddInput("bathroom")}
                            >
                              Add Bathroom
                            </BootstrapButton>
                            <BootstrapButton
                              className="remove-btn"
                              onClick={() => {
                                this.HandleRemoveClick("bathroom");
                              }}
                            >
                              Delete Bathroom
                            </BootstrapButton>
                          </TabPane>
                          <TabPane
                            key="5"
                            tab={
                              <span className="tab-icons font-weight-bold" style={{fontSize:"10px"}}>
                                <AiOutlineShoppingCart /> GARDEN
                              </span>
                            }ed
                          >
                            {this.state.gardenArray.map((hh, key) => {
                              return (
                                <>
                                  <Row className="p-2">
                                    <Col className="bg-alternate-tabs">
                                      <h6 className="mt-1 text-blue">
                                        Garden: {key + 1}
                                      </h6>
                                    </Col>
                                    {this.state.itemsInGarden.map((item) => {
                                      console.log("this.state.itemsInGarden:::",this.state.itemsInGarden);
                                      return (
                                        <Col xs={12} className="pb-2 pt-2">
                                          <Checkbox
                                            name={item.name}
                                            value={item.value}
                                            onChange={(e) =>
                                              this.handleChangeInput(
                                                "garden",
                                                e,
                                                key,
                                                item.price
                                              )
                                            }
                                          >
                                            {item.value}
                                          </Checkbox>
                                        </Col>
                                      );
                                    })}
                                  </Row>
                                </>
                              );
                            })}
                            <br />
                            <BootstrapButton
                              className="mr-1 add-btn"
                              onClick={() => this.HandleAddInput("garden")}
                            >
                              Add garden
                            </BootstrapButton>
                            <BootstrapButton
                              className="remove-btn"
                              onClick={() => {
                                this.HandleRemoveClick("garden");
                              }}
                            >
                              Delete garden
                            </BootstrapButton>
                          </TabPane>
                          <TabPane
                            key="8"
                            tab={
                              <span className="tab-icons font-weight-bold" style={{fontSize:"10px"}}>
                                <AiOutlineCodeSandbox /> BOX AND PACKING
                              </span>
                            }
                          >
                            {this.state.boxArray.map((hh, key) => {
                              return (
                                <>
                                  <Row className="p-2">
                                    
                                    {this.state.itemsInBox.map((item) => {
                                      return (
                                        <Col xs={12} className="pb-2 pt-2">
                                          <Checkbox
                                            name={item.name}
                                            value={item.value}
                                            onChange={(e) =>
                                              this.handleChangeInput(
                                                "Box",
                                                e,
                                                key,
                                                item.price
                                              )
                                            }
                                          >
                                            {item.value}
                                          </Checkbox>
                                        </Col>
                                      );
                                    })}
                                  </Row>
                                </>
                              );
                            })}
                            <br />
                            
                          </TabPane>
                        </Tabs>
                      </Col>
                    </Row>
                    <br />
                    <p>Any other item please input details:</p>
                    <Form.Item name="other">
                      <Input.TextArea />
                    </Form.Item>
                    <Form.Item>
                      <br />
                      <Button
                        className="float-right driver-reg-btn"
                        htmlType="submit"
                      >
                        Next
                      </Button>
                    </Form.Item>
                  </Form>
                  <div
                    style={{ marginTop: "10px" }}
                    className="p-2 text-center shadow-sm bg-orange"
                  >
                    {this.state.mileage && (
                      <h4 className="text-light">
                        Distance: {this.state.mileage}
                      </h4>
                    )}
                  </div>
                </Col>
                <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                  <GoogleApiWrapper
                    lat1={this.props.location.state.lat1}
                    lng1={this.props.location.state.lng1}
                    lat2={this.props.location.state.lat2}
                    lng2={this.props.location.state.lng2}
                    handleMile={this.handleMile}
                  />
                </Col>
                <Col xs={12} sm={12} md={4} lg={4} xl={4}></Col>
              </Row>
            </Container>
          </Container>
        </>
      );
    }
  }
  export default Manvan;
  