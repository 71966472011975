import React, { useContext, useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from "../../img/AnyVanLogo.svg";
import { FaBusAlt, FaShippingFast } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { GrUserAdmin } from "react-icons/gr";
import { userContext } from "../../App";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { toast, Zoom, Bounce } from "react-toastify";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";

toast.configure();
function HeaderNavbar() {
  const { state, dispatch } = useContext(userContext);
  const [prevScrollpos, setPrevScrollpos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const history = useHistory();
  const logout = () => {
    localStorage.clear();
    dispatch({ type: "CLEAR" });
    history.push("/");
    toast.success("You are Logged Out", {
      draggable: true,
      hideProgressBar: true,
      transition: Zoom,
      autoClose: 3000,
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const renderList = () => {
    if (state) {
      console.log("StateRole", state);
      if (state.role === "admin") {
        return [
          <NavLink
            exact
            key="1"
            className="Links"
            activeClassName="Active"
            to={"/newRequest"}
          >
            {state.name}
          </NavLink>,
          <button key="0" onClick={() => logout()} className="Logoutbtn">
            Logout
          </button>,
        ];
      } else {
        return [
          <NavLink
            exact
            key="1"
            className="Links"
            activeClassName="Active"
            to={"/driverNewRequests"}
          >
            {state.firstName}
          </NavLink>,
          <button key="0" onClick={() => logout()} className="Logoutbtn">
            Logout
          </button>,
        ];
      }
    } else {
      return [
        <NavLink
          key="8"
          className="Links float-right"
          activeClassName="Active"
          exact
          to={"/login"}
        >
          <GrUserAdmin /> Driver Login
        </NavLink>,
      ];
    }
  };
  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    setVisible(prevScrollpos > currentScrollPos || currentScrollPos < 80);
    setPrevScrollpos(currentScrollPos);
  };

  window.addEventListener("scroll", handleScroll);

  return (
    <> 
      <Navbar
        collapseOnSelect
        expand="lg"
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          left: 0,
          zIndex: 1030,
        }}
        className={`bg-white  pt-3 pb-3 ${
          visible ? "" : "hide-navbar"
        }`}
      >
        <Navbar.Brand as={Link} to="/">
          <Row>
            <Image
              alt="bike"
              src={require("../../img/Asset 2.png")}
              className="logo1 ml-2"
            />
            {/* <h5 className='text-blue font-weight-bold mt-1 mr-2 ml-2'> GET MOVERS</h5> */}
            {/* <h5 className='text-blue font-weight-bold mt-2 mr-5 ml-5'><FaShippingFast className='logo text-orange'/> GET MOVERS</h5> */}
          </Row>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="d-flex justify-content-end ml-2">
            <NavLink
              exact
              key="1"
              className="Links"
              activeClassName="Active"
              to={"/"}
            >
              Home
            </NavLink>
            <NavLink
              key="2"
              className="Links"
              activeClassName="Active"
              exact
              to={"/about-us"}
            >
              About Us
            </NavLink>
            <NavLink
              key="3"
              className="Links"
              activeClassName="Active"
              exact
              to={"/categories"}
            >
              Our Services
            </NavLink>
            <NavLink
              key="4"
              className="Links"
              activeClassName="Active"
              exact
              to={"/blog"}
            >
              Blog
            </NavLink>
            <NavLink
              key="5"
              className="Links"
              activeClassName="Active"
              exact
              to={"/driver-registration"}
            >
              Drive With Us
            </NavLink>
            <NavLink
              key="6"
              className="Links"
              activeClassName="Active"
              exact
              to={"/advice-center"}
            >
              Advice From Us
            </NavLink>
            <NavLink
              key="7"
              className="Links"
              activeClassName="Active"
              exact
              to={"/contact-us"}
            >
              Contact Us
            </NavLink>
            {renderList()}
            <a
              key="8"
              className="Links"
              target={'_blank'}
              href={"https://app.getmovers.co.uk/front/download-app"}
            >
              Proceed for Hourly Rate
            </a>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}
export default HeaderNavbar;
