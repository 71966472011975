import React,{useEffect} from 'react';
import {Col, Container} from 'react-bootstrap'
export default function GeneralTerms() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Container className="pt-5 pb-5">
    <Col xs={12}>
    <br />
          <h4 className="title1">Term And Conditions</h4>
      <p>This website Getmovers.co.uk is owned and operated by Janson’s Group of Companies Limited. (“Getmovers.co.uk”, “we” or “us). Registered office: Janson’s Group of Companies Getmovers.co.uk Kemp House, 152-160 City Road, London EC1V 2NX a subsidiary of Jansons Group of Companies Limited. Company registration number: 12449022 registered in (England and Wales). Getmovers.co.uk is the subsidiary Janson’s Group of Companies Limited trading as Getmovers.co.uk. VAT number: GB If you want to ask us anything about these terms and conditions or have any comments or complaints on or about our website, please contact us.</p>
      
      <h4 className="text-blue mt-3">Ownership of rights</h4>
      <p>All rights, including copyright, in this website are owned by or licensed to Getmovers.co.uk. You may not modify, distribute or re-post anything on this website for any purpose. Any use of this website or its contents, including copying or storing it or them in whole or part, other than for your own personal, non-commercial use is prohibited without the permission of Getmovers.co.uk. To request permission to use contents for corporate use, please contact us.</p>
      
      <h4 className="text-blue mt-3">Accuracy of content</h4>
      <p>Getmovers.co.uk has taken every care in the preparation of the contents of this website, to ensure that all products/services have been fairly described. To the extent permitted by applicable law, Getmovers.co.uk disclaims all warranties, express or implied, as to the accuracy of the information contained in any of the materials on the website. Getmovers.co.uk shall not be liable to any person for any loss or damage which may arise from the use of the information contained in any of the materials on this website.</p>
  
      
      <h4 className="text-blue mt-3">Damage to your computer</h4>
      <p>Getmovers.co.uk makes every effort to ensure that this website is free from viruses or defects. However, we cannot guarantee that your use of this website or any websites accessible through it will not cause damage to your computer. It is your responsibility to ensure that the right equipment is available to use the website and filter out anything that may damage it. Getmovers.co.uk shall not be liable to any person for any loss or damage which may arise to computer equipment because of using this website</p>
      
      <h4 className="text-blue mt-3">Links to other websites</h4>
      <p>We have placed links on this website to other websites we think you may want to visit. We do not vet these websites and do not have any control over their contents. Getmovers.co.uk cannot accept any liability in respect of the use of these websites.</p>
      
      <h4 className="text-blue mt-3">Legal notices</h4>
      <p>There may be legal notices on other areas of this website which relate to your use of the website, all of which will, together with these terms & Conditions govern your use of this website, Click here for Privacy Policy.</p>
      
      <h4 className="text-blue mt-3">Changes to legal notices</h4>
      <p>We reserve the right to change these terms and conditions from time to time and you should look through them as often as possible.</p>
      
      <h4 className="text-blue mt-3">Law, jurisdiction and language</h4>
      <p>This website, any content contained herein, and any contract brought into being because of usage of this website are governed by and construed in accordance with English Law. The parties to any such contract agree to submit to the exclusive jurisdiction of the courts of England and Wales. All contracts are concluded in English</p>

      <h4 className="text-blue mt-3">Charges if You postpone or cancel the removal</h4>
      <p>If You postpone or cancel this Agreement, we reserve the right to charge you a reasonable postponement or cancellation fee according to how much notice is given. We charge these fees based on an assessment of losses we have incurred because of You cancelling or postponing the removal. Examples of the types of loss We might incur are administration/back-office costs, being unable to re-fill a removal slot with another customer’s work, or engaging employees to work for your booked removal. “Working days” refer normal working week of Monday to Friday and excludes weekends and Public Holidays. More than 10 working days before the removal was due to start 10%. Between 5 and 10 working days inclusive before the removal was due to start: not more than 30% of the removal charge. Less than 5 working days before the removal was due to start: not more than 60% of the removal charge. Within 24 hours of the move taking place; not more than 75% of the removal charge.On the day the work starts or at any time after the work commences up to 100% of Our charges. </p>

      <h4 className="text-blue mt-3">Payment </h4>
      <p>Unless otherwise agreed by Us in writing, payment is required in full by cleared funds at the time of booking the removal or storage period. In default of such payment, we reserve the right to refuse to commence removal or storage until such payment is received.</p>
      <p>In respect of all sums which are overdue to us, we will charge interest daily calculated at 4% per annum above the prevailing base rate for the time being of the Bank of England.</p>

      <h4 className="text-blue mt-3">Termination</h4>
      <p>If payments are up to date, we will not end this contract except by giving You three months’ notice in writing. If You wish to terminate Your storage contract, you must give us at least 10 working days’ notice (working days are defined in Clause 6 above). If We can release the goods earlier, we will do so, provided that your account is paid up to date. Charges for storage are payable to the date when the notice should have taken effect</p>

      <h4 className="text-blue mt-3">Our Right to Hold the Goods (lien)</h4>
      <p>“Lien” is the legal right of the remover to hold goods until the customer has paid all outstanding charges. We shall have a right to withhold and ultimately dispose of some or all the goods if You fail to pay the charges and any other payments due under this or any other Agreement.  These include any charges that We have paid out on Your behalf. While We hold the goods, you will be liable to pay all storage charges and other costs (including legal costs) reasonably incurred by Us in recovering Our charges and applying Our right of lien. These terms and conditions shall continue to apply. </p>

      <h4 className="text-blue mt-3">Our right to sub-contract the work </h4>
      <p>We reserve the right to sub-contract some or all the work. If We sub-contract, then these conditions will still apply.</p>

    </Col>
    </Container>
  );
}
