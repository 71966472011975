import React from 'react';
import '../../css/admin-panel.css';
import  {FaBars}  from 'react-icons/fa';
import { Navbar, Nav } from 'react-bootstrap';
import {NavLink} from 'react-router-dom';

import {RiLogoutCircleLine} from 'react-icons/ri';

 
class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    
  } 
  
  
    render ()   
      { 
      
          return(
            <>

                    
                    <Navbar  collapseOnSelect  expand="lg" id="nav" className='admin-navbar'>
                    <FaBars onClick = {this.props.click} className="pointer bg-blue text-white sidebar-hideshow-icon-area"/>
                    <Navbar.Toggle  aria-controls="responsive-navbar-nav" />
                    {/* <NavLink exact to="/" className="adminNavbarLinks"> </NavLink> */}

                    </Navbar>
                
            </>
          )
      }

}

export default AdminNavbar;
