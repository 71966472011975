import React, { useState } from "react";
import { Form, Input, Button,Select,DatePicker  } from 'antd';
import {Row,Col,Container} from 'react-bootstrap';
import { toast, Zoom, Bounce} from 'react-toastify'
import { BarLoader, BeatLoader} from 'react-spinners'
import {useHistory, useParams} from 'react-router-dom';
import AOS from 'aos';

toast.configure()
const ChangePassword =()=> {
    AOS.init();
  const [loading,setLoading]= useState(false)
  const history = useHistory();
  const {token} = useParams()

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  const submit=(e)=>{
    setLoading(true)
      const {password} = e;
      fetch("/changePassword",{
        method:"post",
        headers:{
          "content-Type":"application/json"
        },
        body:JSON.stringify({
            token,
            password
  
        })
      }).then(res=>res.json())
      .then(data=>{  
        console.log(data)
        history.push('/')
          toast.success("Request submitted",{
            draggable: true,
            hideProgressBar:true,
            transition: Zoom,
            autoClose:7000,
            position: toast.POSITION.TOP_CENTER  
          })
      })
  }


         
          if(loading===true)
          {
            return(
            <div className="loading">
                <BeatLoader loading
                  size={20}
                  color="#f26c4f" />
                <BarLoader loading 
                css={{display:'block',margin:0}}
                size={40}
                color="#f26c4f"
                />
            </div>
            )
          }
          if(loading===false)
          {
          return(
            <>
                <Container className='pt-5 pb-5 driver-registration mt-5'>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <h2 className="title1" style={{fontSize:30,marginBottom:10}}>Update Password</h2><br/>
                        </Col>
                        <Col data-aos="fade-up"  data-aos-duration="3000" xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Form {...layout} 
                           onFinish={submit}> 
                                    <Col lg={5, {offset: 6}} >
                                    <Form.Item
                                        name="password"
                                        label="Password"
                                        rules={[
                                        {
                                            required: true,
                                            message: 'Please input your password!',
                                        },
                                        ]}
                                        hasFeedback
                                    >
                                        <Input.Password />
                                    </Form.Item>
                                    </Col>

                                    <Col lg={5, {offset: 6}} >
                                    <Form.Item
                                        name="confirm"
                                        label="Confirm Password"
                                        dependencies={['password']}
                                        hasFeedback
                                        rules={[
                                        {
                                            required: true,
                                            message: 'Please confirm your password!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject('The two passwords that you entered do not match!');
                                            },
                                        }),
                                        ]}
                                    >
                                        <Input.Password />
                                    </Form.Item>
                                    </Col>
                                                                  
                                <Row>
                                    
                                    <Col className="d-flex justify-content-center" style={{marginTop:15}} lg={5, {offset: 9}} >
                                   
                                        <Button type="submit" htmlType="submit" size="large" className="driver-reg-btn d-flex justify-content-center" > Update</Button>
                                   
                                    </Col>
                                </Row>       
                            </Form>
                        </Col>
                    </Row>
                    
                </Container>
            </>
          )
      }

}

export default ChangePassword;
