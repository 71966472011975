import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import img from "../img/caroline2.png";
import Image from "react-bootstrap/Image";
import React, { Component } from "react";
import { default as BootstrapForm } from "react-bootstrap/Form";
import { Form, Input, Button, Select, InputNumber } from "antd";
import { RiArrowRightSLine, RiDeleteBack2Line } from "react-icons/ri";
import { FiDelete } from "react-icons/fi";
import GoogleApiWrapper from "./googlemap/googleMaps";
// import FurnitureData from './json/furnitureData';
import Modal from "react-bootstrap/Modal";
import PickupLocation from "./googlemap/pickupLocation";
import DropoffLocation from "./googlemap/dropoffLocation";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
const { Option } = Select;
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

class Furniture extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.otherSubmit = this.otherSubmit.bind(this);
  }
  state = {
    show: false,
    subCategory: false,
    map: true,
    products: [],
    others: [],
    dropoffFloor: "",
    pickupFloor: "",
    FurnitureData: [],
    calculatedPrice: 0,
    otherProducts: [
      {
        item: "",
        qty: "",
        width: "",
        height: "",
        depth: "",
        length: "",
        weight: "",
        weightIn: "",
      },
    ],
    mileage: "",
    distance: "",
    distanceCost: 0,
  };
  handleShow = () => this.setState({ show: true });

  onChange1(e) {
    const valuesOfOther = [...this.state.otherProducts];
    const values = [...this.state.products];
    values.push(valuesOfOther);
    this.setState({ products: values });
  }
  componentDidMount() {
    fetch("/getFurnitureData")
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          FurnitureData: data,
        });
        console.log("Furniture Data is ", data);
      });

    fetch("/getDistanceCost")
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          distanceCost: data.cost,
        });
        console.log("Distance Data is ", data.cost);
      });
  }
  handleChangeInput(i, e) {
    const values = [...this.state.otherProducts];
    const { name, value } = e.target;
    values[i][name] = value;
    this.setState({ otherProducts: values });
  }
  otherSubmit(e) {
    const values = [...this.state.products];
    const { item, width, height, depth, length, weight, weightIn } = e;

    const index = values.findIndex(
      (i) =>
        i.item === item &&
        i.width === width &&
        i.height === height &&
        i.depth === depth &&
        i.length === length &&
        i.weight === weight &&
        i.weightIn === weightIn
    );

    //if item exits in products array.....
    if (index !== -1) {
      const qty = values[index].qty;
      values[index].qty = qty + 1;
      this.setState({ products: values });
    }
    //if item not exits in products array.....
    if (index === -1) {
      values.push({
        item: item,
        qty: 1,
        width: width,
        height: height,
        depth: depth,
        length: length,
        weight: weight,
        weightIn: weightIn,
      });
      this.setState({ products: values });
    }
    this.setState({
      show: false,
    });
  }
  onChange(e) {
    const values = [...this.state.products];
    const index = values.findIndex((x) => x.item === e);
    var price;

    //if item exits in products array.....
    if (index !== -1) {
      const qty = values[index].qty;
      values[index].qty = qty + 1;
      this.setState({ products: values });
      // const price= parseFloat(e[1]) + parseFloat(this.state.calculatedPrice)
      // this.setState({
      //     calculatedPrice:price
      // })
      // console.log(price)
    }
    //if item not exits in products array.....
    if (index === -1) {
      this.state.FurnitureData.map((item) => {
        if (item.item1[0] === e) {
          price = item.item1[1];
        } else if (item.item2[0] === e) {
          price = item.item2[1];
        } else if (item.item3[0] === e) {
          price = item.item3[1];
        } else if (item.item3[0] === e) {
          price = item.item3[1];
        } else if (item.item4[0] !== undefined && item.item4[0] === e) {
          price = item.item4[1];
        } else if (item.item5[0] !== undefined && item.item5[0] === e) {
          price = item.item5[1];
        }
      });
      values.push({ item: e, qty: 1, price: price });
      this.setState({ products: values });
      console.log("yo", price);
      // const price= parseFloat(e[1]) + parseFloat(this.state.calculatedPrice)
      // this.setState({
      //     calculatedPrice:price
      // })

      // console.log(price)
    }
  }
  increaseQuantiy(prod, qty) {
    const values = [...this.state.products];
    const index = values.findIndex((x) => x.item === prod);
    values[index].qty = qty + 1;
    this.setState({ products: values });
    console.log(values);
  }
  decreaseQuantiy(prod, qty) {
    const values = [...this.state.products];
    const index = values.findIndex((x) => x.item === prod);
    if (qty > 1) {
      values[index].qty = qty - 1;
      this.setState({ products: values });
    }
    if (qty === 1) {
      values.splice(index, 1);
      this.setState({ products: values });
    }
  }
  deleteItem(prod) {
    const values = [...this.state.products];
    const index = values.findIndex((x) => x.item === prod);
    values.splice(index, 1);
    this.setState({ products: values });
  }

  FurnitureItems() {
    return this.state.FurnitureData.map((e1) => {
      if (e1.id === "5") {
        return (
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <Form.Item className="mt-1" name={e1.category}>
              <Select
                placeholder={e1.category}
                onChange={(e1) => this.onChange(e1)}
              >
                <Option value={e1.item1[0]} className="movingItemsDropdown">
                  {e1.item1[0]}
                </Option>
                <Option value={e1.item2[0]} className="movingItemsDropdown">
                  {e1.item2[0]}
                </Option>
                <Option value={e1.item3[0]} className="movingItemsDropdown">
                  {e1.item3[0]}
                </Option>
                <Option value={e1.item4[0]} className="movingItemsDropdown">
                  {e1.item4[0]}
                </Option>
                <Option value={e1.item5[0]} className="movingItemsDropdown">
                  {e1.item5[0]}
                </Option>
              </Select>
            </Form.Item>
          </Col>
        ); // return end here
      } //if end here
      else {
        return (
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <Form.Item className="mt-1" name={e1.category}>
              <Select
                placeholder={e1.category}
                onChange={(e1) => this.onChange(e1)}
              >
                <Option value={e1.item1[0]} className="movingItemsDropdown">
                  {e1.item1[0]}
                </Option>
                <Option value={e1.item2[0]} className="movingItemsDropdown">
                  {e1.item2[0]}
                </Option>
                <Option value={e1.item3[0]} className="movingItemsDropdown">
                  {e1.item3[0]}
                </Option>
              </Select>
            </Form.Item>
          </Col>
        ); // return end here
      } //if end here
    });
  }

  submit(e) {
    const { other } = e;
    this.props.history.push("/email-prices", {
      category: this.props.location.state.category,
      pickupLoc: this.props.location.state.pickupLoc,
      dropoffLoc: this.props.location.state.dropoffLoc,
      lat1: this.props.location.state.lat1,
      lng1: this.props.location.state.lng1,
      lat2: this.props.location.state.lat2,
      lng2: this.props.location.state.lng2,
      pickupFloor: this.state.pickupFloor,
      dropoffFloor: this.state.dropoffFloor,
      items: this.state.products,
      mileage: this.state.mileage,
      distanceCost: this.state.distanceCost,
      otherItem: other,
    });
  }

  Cart() {
    return (
      <div>
        <div className="p-2 text-center shadow-sm bg-orange">
          <h4 className="text-light">Your Move Details</h4>
        </div>
        <div className="p-2">
          {this.state.products.map((e1) => {
            return (
              <>
                <Row className="bg-alternate pt-1">
                  <Col xs={7}>
                    <p className="mt-1" style={{ fontSize: 14 }}>
                      {e1.item}
                    </p>
                  </Col>
                  <Col xs={1}>
                    <p className="text-orange cart-qty-icon pointer">
                      <AiOutlineMinusCircle
                        onClick={() => this.decreaseQuantiy(e1.item, e1.qty)}
                      />
                    </p>
                  </Col>
                  <Col xs={1}>
                    <p className="color-black mt-1 text-center"> {e1.qty}</p>
                  </Col>
                  <Col xs={1}>
                    <p className="text-orange cart-qty-icon pointer">
                      <AiOutlinePlusCircle
                        onClick={() => this.increaseQuantiy(e1.item, e1.qty)}
                      />
                    </p>
                  </Col>
                  <Col xs={1}>
                    <p className="text-orange cart-qty-icon pointer">
                      <RiDeleteBack2Line
                        onClick={() => this.deleteItem(e1.item)}
                      />
                    </p>
                  </Col>
                </Row>
              </>
            );
          })}
        </div>
      </div>
    );
  }
 

  handleMile = (val) => {
    const miles = val.replace("mi", "");
    this.setState({
      mileage: miles + " miles",
    });
    console.log("Value from Furni", val);
  };
  render() {
    return (
      <>
        <Container className="mt-3 mb-5">
          <br />
          <Row className="mt-5">
            <Col className="mb-5" xs={12} sm={12} md={12} lg={12} xl={12}>
              <h2 className="title1 text-center">Furniture & Other Items</h2>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8} xl={8}>
              <Row>
                <Col xs={12}>
                  {console.log("show ne here")}
                  <h6>Please Select Pickup Floor</h6>
                  <Form.Item
                    name="PickupFloor"
                    rules={[
                      {
                        required: true,
                        message: "Please select Pick Up Floor",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Please Select Pickup Floor"
                      onChange={(e) => this.setState({ pickupFloor: e })}
                    >
                      <Select.Option
                        value="basement"
                        className="movingItemsDropdown"
                      >
                        Basement
                      </Select.Option>
                      <Select.Option
                        value="groundfloor"
                        className="movingItemsDropdown"
                      >
                        Ground Floor
                      </Select.Option>
                      <Select.Option
                        value="firstfloor"
                        className="movingItemsDropdown"
                      >
                        1st Floor
                      </Select.Option>
                      <Select.Option
                        value="secondfloor"
                        className="movingItemsDropdown"
                      >
                        2nd Floor
                      </Select.Option>
                      <Select.Option
                        value="thirdfloor"
                        className="movingItemsDropdown"
                      >
                        3rd Floor
                      </Select.Option>
                      <Select.Option
                        value="fourfloor"
                        className="movingItemsDropdown"
                      >
                        4rth Floor
                      </Select.Option>
                      <Select.Option
                        value="fifthfloor"
                        className="movingItemsDropdown"
                      >
                        5th Floor
                      </Select.Option>
                      <Select.Option
                        value="sixfloor"
                        className="movingItemsDropdown"
                      >
                        6th Floor
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <h6>Please Select Dropoff Floor</h6>
                  <Form.Item
                    name="DropoffFloor"
                    rules={[
                      {
                        required: true,
                        message: "Please select Delivery Floor",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Please Select DropOff Floor"
                      onChange={(e) => this.setState({ dropoffFloor: e })}
                    >
                      <Select.Option
                        value="basement"
                        className="movingItemsDropdown"
                      >
                        Basement
                      </Select.Option>
                      <Select.Option
                        value="groundfloor"
                        className="movingItemsDropdown"
                      >
                        Ground Floor
                      </Select.Option>
                      <Select.Option
                        value="firstfloor"
                        className="movingItemsDropdown"
                      >
                        1st Floor
                      </Select.Option>
                      <Select.Option
                        value="secondfloor"
                        className="movingItemsDropdown"
                      >
                        2nd Floor
                      </Select.Option>
                      <Select.Option
                        value="thirdfloor"
                        className="movingItemsDropdown"
                      >
                        3rd Floor
                      </Select.Option>
                      <Select.Option
                        value="fourfloor"
                        className="movingItemsDropdown"
                      >
                        4rth Floor
                      </Select.Option>
                      <Select.Option
                        value="fifthfloor"
                        className="movingItemsDropdown"
                      >
                        5th Floor
                      </Select.Option>
                      <Select.Option
                        value="sixfloor"
                        className="movingItemsDropdown"
                      >
                        6th Floor
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col>
              <GoogleApiWrapper
                lat1={this.props.location.state.lat1}
                lng1={this.props.location.state.lng1}
                lat2={this.props.location.state.lat2}
                lng2={this.props.location.state.lng2}
                handleMile={this.handleMile}
              />
              <br />
            </Col>
            <Col xs={12} sm={12} md={8} lg={8} xl={8}>
              <Form onFinish={this.submit}>
                <Row>
                  <Col xs={12}>
                    <h6>Please Select Your items to move</h6>
                  </Col>
                  {this.FurnitureItems()}
                </Row>
                <p>Any other item please input details:</p>
                <Form.Item name="other">
                  <Input.TextArea />
                </Form.Item>
                <Button
                  type="submit"
                  htmlType="submit"
                  size="large"
                  className="driver-reg-btn"
                >
                  Next
                </Button>
              </Form>
              <div
                style={{ marginTop: "10px" }}
                className="p-2 text-center shadow-sm bg-orange"
              >
                {this.state.mileage && (
                  <h4 className="text-light">Distance: {this.state.mileage}</h4>
                )}
              </div>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} xl={4} className="pt-2">
              {this.Cart()}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
export default Furniture;
