import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button'
import 'react-toastify/dist/ReactToastify.css';
import Table from 'react-bootstrap/Table'
import AOS from 'aos';
import { toast, Zoom, Bounce} from 'react-toastify'
import { BarLoader, BeatLoader} from 'react-spinners'

class Drivers extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      data:[],
      loading:false
    }
  }
  
  componentDidMount()
  {
    fetch("/getApprovedDrivers",{
      method:"get",
      headers:{
        "content-Type":"application/json"
      },
    }).then(res=>res.json())
    .then(data=>
    {
      console.log(data)
     this.setState({
        data:data
      })
    }).catch(err=>{
      console.log(err)
    })
  }
  
  action = (status,id) => {
    this.setState({loading:true})
    fetch("/blockDriver",{
      method:"post",
      headers:{
        "content-Type":"application/json"
      },
      body:JSON.stringify({
          id,
          status

      })
    }).then(res=>res.json())
    .then(data=>{  
      console.log(data)
       
      toast.success("Driver status updated successfully",{
        draggable: true,
        hideProgressBar:true,
        transition: Zoom,
        autoClose:7000,
        position: toast.POSITION.TOP_CENTER  
      })
      this.props.history.push('/newRequest')
    })
  }
  
    render ()
      { 
        const requests =this.state.data.map((item,index)=>{
          
          if(item.status!=="new")
          {
            var btn;
            if(item.status==="Approved")
            {
              btn = <Button style={{marginLeft:10}} className='table-view-btn' onClick={()=>this.action('Blocked',item._id)}>Block</Button>
            }
            if(item.status==="Blocked")
            {
              btn = <Button style={{marginLeft:10}} className='table-view-btn' onClick={()=>this.action('Approved',item._id)}>Unblock</Button>
            }
          return(
              <tr class="d-flex" key={item._id} >
              <td class="col-1">{index+1}</td>
              <td class="col-4">{item.email}</td>
              <td class="col-3">{item.status}</td>
             <td class="col-4 d-flex justify-content-center"><Button  className='table-view-btn' onClick={()=>this.props.history.push('/driver',{item})}>View</Button>
              {btn}</td>
            </tr>
            
          )
          }
      })
      if(this.state.loading===true)
      {
        return(
          <div className="loading">
              <BeatLoader loading
                size={20}
                color="#f26c4f" />
              <BarLoader loading 
              css={{display:'block',margin:0}}
              size={40}
              color="#f26c4f"
              />
          </div>
          )
      }
      if(this.state.loading===false)
      {
          return(
            <>
              <Row className='admin-forms'>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h1 className='page-title'>Driver Requests</h1>
                <hr/>
                </Col>
                <Col className='pt-3'>
                <Table hover size="sm" className='mt-3 text-left' className="table admin-table" data-aos="fade-up" data-aos-duration="2000">
                  <thead className='bg-orange text-white'>
                    <tr class="d-flex">
                      <th class="col-1">Sr. No</th>
                      <th class="col-4">Email</th>
                      <th class="col-3">Status</th>
                      <th class="col-4 text-center">Action</th>
                    </tr>
                  </thead>      
                
                <tbody>
                    {requests}
                </tbody>
                  </Table> 
                
              </Col>
                
              </Row>
            
            </>
          )
      }
      }

}

export default Drivers;
