import React from 'react';
import '../../css/admin-panel.css';
import AdminLayout from './adminLayout';

class Index extends React.Component {
  constructor(props) {
    super(props);
    
    
  }
    render ()
      { 
          return(
            <>
                <AdminLayout/>
            </>
          )
      }

}

export default Index;
