import React,{useEffect} from 'react';
import {Col, Container} from 'react-bootstrap'
export default function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Container className='pt-5 pb-5 driver-registration mt-5'>
    <Col lg={10} className="privacyPolicy">
      <h4 className="text-blue">Privacy Policy</h4>
      <div className='text-size'>GetMovers is a data controller  registered with the Information Commissioners Office and governed by  prevailing data protection legislation. We are committed to preserving and respecting the privacy of visitors’ to www.getmovers.com.   Our Privacy policy below will explain how we collect personal information, what we collect, what we do with that information, your rights, and how long we keep information GetMovers is committed to preserving the privacy of all visitors to www.getmovers.com</div>
      <div className='text-size'>Read the following privacy policy to understand how we use and protect the information that you provide to us.</div>
      <div className='text-size'>By making an enquiry via this site, you consent to the collection, use and transfer of your information under the terms of this policy.</div>
      <h4 className="text-blue mt-3">Information that we collect from you</h4>
      <div className='text-size'>When you contact us via www.getmovers.com you will be asked to provide certain information about yourself including your name, email address and browsing information about your use of the GetMovers website (such as information about your browser type and the pages of the website that you visit). We may also collect information about you from e-mails or letters you send to us or from telephone calls with you.</div>
      <h4 className="text-blue mt-3">Cookies</h4>
      <div className='text-size'>When you visit this website we may use cookies to collect certain browsing information about your use of the GetMovers website. Cookies are small data files which are stored by your browser on your computer’s hard drive and details of the browsing information that GetMovers collects are described above. We also use JavaScript to speed up your experience of the GetMovers website.</div>
      <div className='text-size'>You can change your browser settings to refuse or delete cookies or JavaScript but please note that, if you do, this may impair the functionality of the GetMovers website. As the method by which you may choose to refuse or delete cookies or JavaScript varies from browser to browser, please consult your browser’s Help menu for further information.</div>
      <h4 className="text-blue mt-3">Use of your information</h4>
      <div className='text-size'>Your information will enable us to supply the information you have requested. It will also enable us to contact you where necessary concerning your enquiry. We will also use and analyze the information we collect so that we can administer, support, improve and develop our business.</div>
      <h4 className="text-blue mt-3">Disclosure of your information</h4>
      <div className='text-size'>The information you provide to us will be held in the UK and accessed by or given to our staff or designated agents working within the UK. Unless required to do so by law, we will not otherwise share, sell or distribute any of the information you provide to us without your consent.</div>
      <h4 className="text-blue mt-3">Security and data retention</h4>
      <div className='text-size'>We employ security measures to protect your information from access by unauthorised persons and against unlawful processing, accidental loss, destruction and damage. We will retain your information for a reasonable period or as long as the law requires.</div>
      <h4 className="text-blue mt-3">Accessing and updating</h4>
      <div className='text-size'>You are entitled to see the information held about you and you may ask us to make any necessary changes to ensure that it is accurate and kept up to date. If you wish to do this, please contact us. We are entitled by law to charge a fee to meet our costs in providing you with details of the information we hold about you.</div>
      <h4 className="text-blue mt-3">Changes to our privacy policy</h4>
      <div className='text-size'>Any changes to our privacy policy in the future will be posted to the site and, where appropriate, through e-mail notification.</div>
      <h4 className="text-blue mt-3">Contact</h4>
      <div className='text-size'>All comments, queries and requests relating to our use of your information are welcomed and should be addressed to:</div>
      <div className='text-size'>Kemp House</div>
      <div className='text-size'>152-160. City Road</div>
      <div className='text-size'>London</div>
      <div className='text-size'>EC1V 2NX</div>
      <div className='text-size'>United Kingdom</div>
      <div className='text-size'>Tel: +44 208 625 5555</div>
    </Col>
    </Container>
  );
}
