import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useState } from 'react';
import { Form, Table,Input,Upload, message} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {FiEye} from 'react-icons/fi';
import {MdDeleteForever} from 'react-icons/md';
import Button from 'react-bootstrap/Button'
import { toast, Zoom, Bounce} from 'react-toastify'
import { BarLoader, BeatLoader} from 'react-spinners'
import { storage } from '../../config'
import {FaRegEdit} from 'react-icons/fa';




class AddPiano extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    
  };
  
  
  
 
  


  submit = (e) =>
  {
    fetch("/addPiano",{
      method:"post",
      headers:{
        "content-Type":"application/json"
      },
      body:JSON.stringify({
        type:e.name,
        price:e.price,
      })
    }).then(res=>res.json())
    .then((data)=>{
      
        
       
    })
  }
  
    render ()
      { 
        const layout = {
          labelCol: { span: 24 },
          wrapperCol: { span: 24 },
        };
       
       return(
            <>
              <Row className='add-new-advice-form'>
              
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <h1 className='page-title'>Add Piano</h1>
                <hr/>
                  <Form {...layout} onFinish={this.submit} className='pt-3' > 

                    <Form.Item name='name' label="Piano"  rules={[{ required: true, message: 'Please Enter Your Piano Name' }]}>
                      
                      <Input placeholder='please enter your Piano Name'/>
                    </Form.Item>
                    <Form.Item name='price' label="Price"  rules={[{ required: true, message: 'Please  Enter Your Piano Price' }]}>
                      
                      <Input placeholder='please enter your Piano Price'/>
                    </Form.Item>
                      <br/>
                     
                      <Button className="publish-btn" type="submit">Submit</Button>
                   
                  </Form>
                </Col>

              </Row>
            
            </>
          )
        }
     

}

export default AddPiano;
