import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useState } from 'react';
import { Tabs } from 'antd';
import {GrLounge} from 'react-icons/gr';
import {FaBath} from 'react-icons/fa';
import {IoIosBed} from 'react-icons/io';
import {MdKitchen} from 'react-icons/md';
import {Form, Input, Button, Badge, Card } from 'antd';
import GoogleApiWrapper from '../../googlemap/googleMaps'
import { toast, Zoom, Bounce} from 'react-toastify'
import { BarLoader, BeatLoader} from 'react-spinners'
import AOS from 'aos';
import { Select } from 'antd';
import moment from 'moment';

toast.configure()
const { TabPane } = Tabs;
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const { Option } = Select;
class RequestHomeRemovals extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      data:[],
      bedroom:[],
      man:[],
      bathroom:[],
      price:'',
      kitchen:[],
      loading:false,
      tvlounge:[],
      dining:[],
      office:[],
      garden:[],
      box:[],
      driver:[],
      driverId:'',
      choosedDriver:'',
      role:'',
      id:''
    }
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value,key) {
    console.log(`selected ${value}`);
    console.log("Key Value",key.key)
    this.setState({driverId:key.key})
  }

  driverApprovedJob=(e) =>
  {

    console.log(e.price)
    fetch("/acceptjob",{
      method:"post",
      headers:{
        "content-Type":"application/json"
      },
      body:JSON.stringify({
          '_id':this.props.location.state.item._id,
          id:this.state.id,
          'status':"Assigned"

      })
    }).then(res=>res.json())
    .then(data=>{  
      console.log(data)
      toast.success("Job Status Changed Successfully",{
        draggable: true,
        hideProgressBar:true,
        transition: Zoom,
        autoClose:7000,
        position: toast.POSITION.TOP_CENTER  
      })
      this.props.history.push('/driverNewRequests')
    })
  }
  
  boiler =async() =>
  {
    const data1 = await JSON.parse(localStorage.getItem("getmoversuser"))
    console.log(data1.role)
    this.setState({role:data1.role, id:data1._id})
    console.log(this.props.location.state.item.id)
    fetch("/getHomeRemovalRequestDetail",{
      method:"post",
      headers:{
        "content-Type":"application/json"
      },
      body:JSON.stringify({
          '_id':this.props.location.state.item.id,

      })
    }).then(res=>res.json())
    .then(data=>{  
      this.setState({
        data:data,
        bedroom:data.bedroom,
        man:data.man,
        tvlounge:data.tvlounge,
        bathroom:data.bathroom,
        office:data.office,
        garden:data.garden,
        box:data.box,
        dining:data.dining,
        kitchen:data.kitchen
      })
    })
  }

  selectDriver=()=>
    {
        fetch('/getApprovedDrivers')
        .then(res=>res.json())
        .then(res2=>{
            this.setState({driver:res2})
            console.log(res2)
        })
    }
  componentDidMount()
  {
    this.boiler()
    this.selectDriver()
  }
  submit =(e) =>
  {

    console.log(e.price)
    fetch("/addPrice",{
      method:"post",
      headers:{
        "content-Type":"application/json"
      },
      body:JSON.stringify({
          '_id':this.props.location.state.item._id,
          'price':e.price

      })
    }).then(res=>res.json())
    .then(data=>{  
      console.log(data)
      toast.success("Quotation send Successfully",{
        draggable: true,
        hideProgressBar:true,
        transition: Zoom,
        autoClose:7000,
        position: toast.POSITION.TOP_CENTER  
      })
      this.props.history.push('/newRequest')
    })
  }

  driverJob=(e) =>
  {

    console.log(e.price)
    fetch("/donejob",{
      method:"post",
      headers:{
        "content-Type":"application/json"
      },
      body:JSON.stringify({
          '_id':this.props.location.state.item._id,
          'status':"Completed"

      })
    }).then(res=>res.json())
    .then(data=>{  
      console.log(data)
      toast.success("Job Status Changed Successfully",{
        draggable: true,
        hideProgressBar:true,
        transition: Zoom,
        autoClose:7000,
        position: toast.POSITION.TOP_CENTER  
      })
      this.props.history.push('/driverNewRequests')
    })
  }


  assignDriver =() =>
  {
    fetch("/assignedToDriver",{
      method:"post",
      headers:{
        "content-Type":"application/json"
      },
      body:JSON.stringify({
        '_id':this.props.location.state.item._id,
          'driverId':this.state.driverId,
          'status':"Assigned"
      })
    }).then((data)=>{
      console.log(data)
      this.setState({loading:true})
      toast.success("Request Assign Successfully",{
        draggable: true,
        hideProgressBar:true,
        transition: Zoom,
        autoClose:7000,
        position: toast.POSITION.TOP_CENTER  
      })
      this.props.history.push('/newRequest')
    })
    .catch((err)=>{
      console.log(err)
    })
  }

  render ()
    {
      const originalPrice = parseFloat(this.props.location.state.item.price);
      const discountPercentage = 20;
      const discountAmount = (originalPrice * discountPercentage) / 100;
      const discountedPrice = originalPrice - discountAmount;
      const bedrooms = this.state.bedroom.map((item, index)=>{
        var itemArray;
       
        return(
        <Col xs={4} className='tile1-ribbon bg-alternate-tiles'>
        <div key={index}>
          <Badge.Ribbon text={`BEDROOM No.  ${index+1}`}>
              <Card className='tile1 shadow-sm request-title'>
                <br/> 
                {
                  itemArray = Object.entries(item),
                  itemArray.map(item1=>{
                    console.log(item1)
                    if(item1[1] !== '')
                    {
                    return(
                      <p key={item1}>{item1[1]}</p>
                    )
                    }
                  })
                }
              </Card>
            </Badge.Ribbon>
          
          
        </div>
        </Col>

        )
      })
      const mans = this.state.man.map((item, index)=>{
        var itemArray;
       
        return(
        <Col xs={4} className='tile1-ribbon bg-alternate-tiles'>
        <div key={index}>
          <Badge.Ribbon text={`MAN No.  ${index+1}`}>
              <Card className='tile1 shadow-sm request-title'>
                <br/> 
                {
                  itemArray = Object.entries(item),
                  itemArray.map(item1=>{
                    console.log(item1)
                    if(item1[1] !== '')
                    {
                    return(
                      <p key={item1}>{item1[1]}</p>
                    )
                    }
                  })
                }
              </Card>
            </Badge.Ribbon>
          
          
        </div>
        </Col>

        )
      })
      const kitchens = this.state.kitchen.map((item, index)=>{
        var itemArray;
       
        return(
          <Col xs={4} className='tile1-ribbon bg-alternate-tiles'>
        <div key={index}>
          <Badge.Ribbon text={`KITCHEN No.  ${index+1}`}>
              <Card className='tile1 shadow-sm request-title'>
                <br/> 
                {
                itemArray = Object.entries(item),
                itemArray.map(item1=>{
                  console.log(item1)
                  if(item1[1] !== '')
                  {
                  return(
                    <p key={item1}>{item1[1]}</p>
                  )
                  }
                })
              }
              </Card>
            </Badge.Ribbon>
          
          
        </div>
        </Col>
        
        )
      })

      const bathrooms = this.state.bathroom.map((item, index)=>{
        var itemArray;
       
        return(
          <Col xs={4} className='tile1-ribbon bg-alternate-tiles'>
          <div key={index}>
            <Badge.Ribbon text={`BATHROOM No.  ${index+1}`}>
                <Card className='tile1 shadow-sm request-title'>
                  <br/> 
                  {
                    itemArray = Object.entries(item),
                    itemArray.map(item1=>{
                      console.log(item1)
                      if(item1[1] !== '')
                      {
                      return(
                        <p key={item1}>{item1[1]}</p>
                      )
                      }
                    })
                  }
                </Card>
              </Badge.Ribbon>
            
            
          </div>
          </Col>
        
        )
      })

      const tvlounges = this.state.tvlounge.map((item, index)=>{
        var itemArray;
       
        return(
          <Col xs={4} className='tile1-ribbon bg-alternate-tiles'>
          <div key={index}>
            <Badge.Ribbon text={`TVLOUNGE No.  ${index+1}`}>
                <Card className='tile1 shadow-sm request-title'>
                  <br/> 
                  {
                    itemArray = Object.entries(item),
                    itemArray.map(item1=>{
                      console.log(item1)
                      if(item1[1] !== '')
                      {
                      return(
                        <p key={item1}>{item1[1]}</p>
                      )
                      }
                    })
                  }
                </Card>
              </Badge.Ribbon>
            
            
          </div>
          </Col>
        
        )
      })
      const offices = this.state.office.map((item, index)=>{
        var itemArray;
       
        return(
          <Col xs={4} className='tile1-ribbon bg-alternate-tiles'>
          <div key={index}>
            <Badge.Ribbon text={`OFFICE No.  ${index+1}`}>
                <Card className='tile1 shadow-sm request-title'>
                  <br/> 
                  {
                    itemArray = Object.entries(item),
                    itemArray.map(item1=>{
                      console.log(item1)
                      if(item1[1] !== '')
                      {
                      return(
                        <p key={item1}>{item1[1]}</p>
                      )
                      }
                    })
                  }
                </Card>
              </Badge.Ribbon>
            
            
          </div>
          </Col>
        
        )
      })
      const gardens = this.state.garden.map((item, index)=>{
        var itemArray;
       
        return(
          <Col xs={4} className='tile1-ribbon bg-alternate-tiles'>
          <div key={index}>
            <Badge.Ribbon text={`GARDEN No.  ${index+1}`}>
                <Card className='tile1 shadow-sm request-title'>
                  <br/> 
                  {
                    itemArray = Object.entries(item),
                    itemArray.map(item1=>{
                      console.log(item1)
                      if(item1[1] !== '')
                      {
                      return(
                        <p key={item1}>{item1[1]}</p>
                      )
                      }
                    })
                  }
                </Card>
              </Badge.Ribbon>
            
            
          </div>
          </Col>
        
        )
      })
      const boxes = this.state.box.map((item, index)=>{
        var itemArray;
       
        return(
          <Col xs={4} className='tile1-ribbon bg-alternate-tiles'>
          <div key={index}>
            <Badge.Ribbon text={`BOX No.  ${index+1}`}>
                <Card className='tile1 shadow-sm request-title'>
                  <br/> 
                  {
                    itemArray = Object.entries(item),
                    itemArray.map(item1=>{
                      console.log(item1)
                      if(item1[1] !== '')
                      {
                      return(
                        <p key={item1}>{item1[1]}</p>
                      )
                      }
                    })
                  }
                </Card>
              </Badge.Ribbon>
            
            
          </div>
          </Col>
        
        )
      })
      const dinings = this.state.dining.map((item, index)=>{
        var itemArray;
       
        return(
          <Col xs={4} className='tile1-ribbon bg-alternate-tiles'>
          <div key={index}>
            <Badge.Ribbon text={`DINING No.  ${index+1}`}>
                <Card className='tile1 shadow-sm request-title'>
                  <br/> 
                  {
                    itemArray = Object.entries(item),
                    itemArray.map(item1=>{
                      console.log(item1)
                      if(item1[1] !== '')
                      {
                      return(
                        <p key={item1}>{item1[1]}</p>
                      )
                      }
                    })
                  }
                </Card>
              </Badge.Ribbon>
            
            
          </div>
          </Col>
        
        )
      })
      const displayDriver = this.state.driver.map(item=>{
            return(     
                    <Option key={item._id} value={item.firstName} >{item.firstName}</Option>
            )
    })
      if(this.state.loading===true)
      {
        return(
          <div className="loading">
              <BeatLoader loading
                size={20}
                color="#f26c4f" />
              <BarLoader loading 
              css={{display:'block',margin:0}}
              size={40}
              color="#f26c4f"
              />
          </div>
          )
      }
      if(this.state.loading===false)
      {
        return(
            <>
              <Row className='admin-forms text-6e6e6e'>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                
              <h1 className='page-title mb-1'>{this.state.data.category} DETAILS</h1>
             
                <hr/>
                
                </Col>
                <Col xs={6} className='request-title pt-3' data-aos="fade-up" data-aos-duration="2000">
                <h5><span className='font-weight-bold text-orange'>Pickup Location: </span></h5>
                    <p>{this.state.data.pickupLoc}</p>
                    <h5><span className='font-weight-bold text-orange'>Dropoff Location: </span></h5>
                    <p>{this.state.data.dropoffLoc}</p>
                    <Row>
                      <Col>
                        <h5><span className='font-weight-bold text-orange'>Pickup Floor: </span></h5>
                        <p>{this.state.data.pickupfloor}</p>
                      </Col>
                      <Col>
                        <h5><span className='font-weight-bold text-orange'>Drop Floor: </span></h5>
                        <p>{this.state.data.dropofffloor}</p>
                        
                      </Col>
                    </Row>
                    <h5><span className='font-weight-bold text-orange '>Email: </span></h5>
                  <p>{this.state.data.email}</p>
                  {this.props.location.state.item.status ==="confirm" &&
                  <>
                  <h5><span className='font-weight-bold text-orange'>Request Date: </span></h5>
                    <p>{moment(this.state.data.date).format('DD MMMM YYYY')}</p>
                  </>
                  }
                    <h5><span className='font-weight-bold text-orange'>Category:</span></h5>
                    <p>{this.state.data.category}</p>
                    {this.props.location.state.item.status !=="new" && this.props.location.state.item.status !=="confrim" &&
                      <>
                        <h5><span className='font-weight-bold text-orange'>Price:</span></h5>
                        <p>{discountedPrice.toFixed(2)}</p>
                      </>
                      }
                    {this.props.location.state.item.status ==="Assigned" && this.state.role ==="admin" &&
                      <>
                        <h5><span className='font-weight-bold text-orange'>Assigned To:</span></h5>
                        <p>{this.props.location.state.item.assignedTo.firstName}</p>
                      </>
                      }
                    
                </Col>
                <Col xs={6} className='p-3'>
                  <div className='ok2'>
                    <GoogleApiWrapper
                     
                    />
                  </div>

                </Col>
               
                

               
                
              </Row>
              <Row>
                <Col>
                  <br/>
                  <Tabs defaultActiveKey="1">
                  <TabPane key="9"  tab={ <span className="tab-icons font-weight-bold"> <IoIosBed/> MAN</span>  } >
                          <Row className='mt-3 p-2' data-aos="fade-up" data-aos-duration="2000">
                           
                              {mans}
                           
                          </Row>
                        
                      </TabPane>
                      <TabPane key="1"  tab={ <span className="tab-icons font-weight-bold"> <IoIosBed/> BEDROOM</span>  } >
                          <Row className='mt-3 p-2' data-aos="fade-up" data-aos-duration="2000">
                           
                              {bedrooms}
                           
                          </Row>
                        
                      </TabPane>
                      <TabPane key="2"  tab={ <span  className="tab-icons font-weight-bold"> <MdKitchen/> KITCHEN  </span>  } >
                      <Row className='mt-3 p-2' data-aos="fade-up" data-aos-duration="2000">
                           
                           { kitchens}
                        
                       </Row>
                        
                      </TabPane>
                      <TabPane key="3" tab={ <span className="tab-icons font-weight-bold"><GrLounge/> TV LOUNGE</span>  } >
                          
                          <Row className='mt-3 p-2' data-aos="fade-up" data-aos-duration="2000">
                           
                          {tvlounges}
                        
                       </Row>
                      </TabPane>
                      <TabPane key="4" tab={ <span className="tab-icons font-weight-bold"><FaBath/> BATHROOM</span>  } >
                        
                          <Row className='mt-3 p-2' data-aos="fade-up" data-aos-duration="2000">
                           
                          {bathrooms}
                         
                        </Row>
                      </TabPane>
                      <TabPane key="5" tab={ <span className="tab-icons font-weight-bold"><FaBath/> DINING</span>  } >
                        
                          <Row className='mt-3 p-2' data-aos="fade-up" data-aos-duration="2000">
                           
                          {dinings}
                         
                        </Row>
                      </TabPane>
                      <TabPane key="6" tab={ <span className="tab-icons font-weight-bold"><FaBath/> OFFICE</span>  } >
                        
                        <Row className='mt-3 p-2' data-aos="fade-up" data-aos-duration="2000">
                         
                        {offices}
                       
                      </Row>
                    </TabPane>
                    <TabPane key="7" tab={ <span className="tab-icons font-weight-bold"><FaBath/> GARDEN</span>  } >
                        
                        <Row className='mt-3 p-2' data-aos="fade-up" data-aos-duration="2000">
                         
                        {gardens}
                       
                      </Row>
                    </TabPane>
                    <TabPane key="8" tab={ <span className="tab-icons font-weight-bold"><FaBath/> BOX AND PACKING</span>  } >
                        
                        <Row className='mt-3 p-2' data-aos="fade-up" data-aos-duration="2000">
                         
                        {boxes}
                       
                      </Row>
                    </TabPane>
                  </Tabs>
                  <Col xs={6} className='request-title pt-3' data-aos="fade-up" data-aos-duration="2000">
                      <h5><span className='font-weight-bold text-orange'>Other Item: </span></h5>
                      <p>{this.state.data.otherItem}</p>
                  </Col>

                  {this.props.location.state.item.status ==="confirm" &&
                  <Form className="pt-5 pb-5 pr-3 pl-3" {...layout} onFinish={this.submit} >
                    <Col xs={6} className='request-title pt-3' data-aos="fade-up" data-aos-duration="2000">
                      <h5><span className='font-weight-bold text-orange'>Send Quotation or Final Cost Of Removal: </span></h5>
                      <Form.Item
                      name='price'>
                        <Input style={{width:230,height:50}} />
                      </Form.Item>
                    </Col>
                    <Form.Item >
                      <br/>
                      <Button className="float-right driver-reg-btn" htmlType="submit">Send</Button>
                    </Form.Item> 
                  </Form>
                  }
                  {this.state.role ==="admin" && this.props.location.state.item.status ==="Paid" &&
                        <Form className="pt-5 pb-5 pr-3 pl-3" {...layout} onFinish={this.assignDriver} >
                          <Col xs={6} className='request-title pt-3' data-aos="fade-up" data-aos-duration="2000">
                            <h5><span className='font-weight-bold text-orange'>Choose Driver: </span></h5>
                            <Select defaultValue="Driver" style={{ width: 170, marginLeft:0 }} onChange={this.handleChange}>
                              {displayDriver}
                              </Select>
                          </Col>
                          <Form.Item >
                            <br/>
                            <Button className="float-right driver-reg-btn" htmlType="submit">Send</Button>
                          </Form.Item> 
                        </Form>
                      }
                      {this.state.role !=="admin" && this.props.location.state.item.status ==="Paid" &&
                       <Form className="pt-5 pb-5 pr-3 pl-3" {...layout} onFinish={this.driverApprovedJob} >
                       <Form.Item >
                         <br/>
                         <Button className="float-right driver-reg-btn" htmlType="submit">Accept</Button>
                       </Form.Item> 
                     </Form>
                      }
                       {this.state.role !=="admin" && this.props.location.state.item.status ==="Assigned" &&
                        <Form className="pt-5 pb-5 pr-3 pl-3" {...layout} onFinish={this.driverJob} >
                          <Form.Item >
                            <br/>
                            <Button className="float-right driver-reg-btn" htmlType="submit">Done</Button>
                          </Form.Item> 
                        </Form>
                      }
                </Col>
              </Row>
            </>
          )
      }
      }
      

}

export default RequestHomeRemovals;
