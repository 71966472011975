import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import about2 from "../img/Asset 1.png";
import about3 from "../img/31.png";
import about4 from "../img/primee.png";
import about5 from "../img/33.png";
import about6 from "../img/34.png";
import Image from "react-bootstrap/Image";
import { ButtonGroup, Button } from "react-bootstrap";
import { FaFacebook, FaLinkedin, FaInstagramSquare } from "react-icons/fa";
import {
  AiFillTwitterCircle,
  AiOutlineHome,
  AiOutlineMail,
} from "react-icons/ai";
import { FiPhone, FaFax } from "react-icons/all";

class Footer extends React.Component {
  render() {
    return (
      <>
        <Container fluid className="bg-grey pt-5 pb-1 footer">
          <Container>
            <Row className="pb-1">
              <Col xs={12} sm={12} md={4} lg={4} xl={3}>
                {/* First Image */}
                <div>
                  <Image src={about2} width="200px" />
                  <p className="text-justify text-f5f5f5">
                    getmovers.co.uk is the subsidry of Oneworld Homes Limited.
                    regsiered in England and Wales.
                  </p>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4} lg={4} xl={3}>
                <h4 className="text-orange">About Us</h4>
                <p className="text-justify text-f5f5f5">
                  getmovers.co.uk is the subsidry of Oneworld Homes Limited.
                  regsiered in England and Wales.
                </p>
              </Col>
              <Col xs={12} sm={12} md={4} lg={4} xl={3}>
                <h4 className="text-orange">Locate Us</h4>
                <p className="text-f5f5f5">
                  152-160. City Road, London ,EC1V 2NX ,United Kingdom
                </p>
                <p className="text-f5f5f5">
                  <span className="text-orange">Email:</span>{" "}
                  info@getmovers.co.uk
                </p>
                <p className="text-f5f5f5">
                  <span className="text-orange"> Tel:</span> 0800 6358888
                </p>
              </Col>
              <Col xs={12} sm={12} md={4} lg={4} xl={3}>
                <h4 className="text-orange">Quick Links</h4>
                <Nav>
                  <NavLink
                    exact
                    to="/"
                    className="footerLinks"
                    activeClassName="footerActiveLinks"
                  >
                    Home
                  </NavLink>
                  <NavLink
                    exact
                    to="/about-us"
                    className="footerLinks"
                    activeClassName="footerActiveLinks"
                  >
                    About Us
                  </NavLink>
                  <NavLink
                    exact
                    to="/contact-us"
                    className="footerLinks"
                    activeClassName="footerActiveLinks"
                  >
                    Contact Us
                  </NavLink>
                  <NavLink
                    exact
                    to="/driver-registration"
                    className="footerLinks"
                    activeClassName="footerActiveLinks"
                  >
                    Drive with us
                  </NavLink>
                  <NavLink
                    exact
                    to="/categories"
                    className="footerLinks"
                    activeClassName="footerActiveLinks"
                  >
                    Our Services
                  </NavLink>
                  <NavLink
                    exact
                    to="/blog"
                    className="footerLinks"
                    activeClassName="footerActiveLinks"
                  >
                    Our Blog
                  </NavLink>
                  <NavLink
                    exact
                    to="/advice-center"
                    className="footerLinks"
                    activeClassName="footerActiveLinks"
                  >
                    Our Advice Center
                  </NavLink>

                  {/* <NavLink exact to="/modern-slavery-statement" className="footerLinks" activeClassName='footerActiveLinks'>Modern Slavery Statement</NavLink> */}
                  <NavLink
                    exact
                    to="/anti-bribery-anti-corruption"
                    className="footerLinks"
                    activeClassName="footerActiveLinks"
                  >
                    Anti-Bribery and Anti-Corruption
                  </NavLink>

                  <NavLink
                    exact
                    to="/Student-discount"
                    className="footerLinks"
                    activeClassName="footerActiveLinks"
                  >
                    Student
                  </NavLink>
                  <a
                    className="footerLinks"
                    target={'_blank'}
                    href={"https://app.getmovers.co.uk/front/download-app"}
                  >
                    Proceed for Hourly Rate
                  </a>

                </Nav>
              </Col>

              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="d-flex flex-row flex-wrap align-items-center">
                  <Image
                    src={about6}
                    style={{
                      maxWidth: "175px",
                      marginRight: "3rem",
                      marginBottom: "1rem",
                    }}
                  />
                  <Image
                    src={about4}
                    style={{
                      maxWidth: "100px",
                      marginRight: "3rem",
                      marginBottom: "1rem",
                    }}
                  />
                  <Image
                    src={about3}
                    style={{
                      maxWidth: "100px",
                      marginRight: "3rem",
                      marginBottom: "1rem",
                    }}
                  />
                  <Image
                    src={about5}
                    style={{ maxWidth: "100px", marginBottom: "1rem" }}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </Container>

        <footer
          class="bg-grey text-center  text-lg-start"
          style={{
            display: "flex",
            borderTop: "1px solid rgba(255, 255, 255, 0.25)",
            justifyContent: "space-around",
          }}
        >
          <Container>
          <div className="button-row-container">
      <NavLink exact to="/privacy-policy" activeClassName="footerActiveLinks">
        Privacy Policy
      </NavLink>
      <span className="separator">|</span>
      <NavLink exact to="/terms-condition" activeClassName="footerActiveLinks">
        Terms of Use
      </NavLink>
      <span className="separator">|</span>
      <NavLink exact to="/insurance-policies" activeClassName="footerActiveLinks">
        Insurance
      </NavLink>
    </div>

            <p
              style={{ fontSize: "15px" }}
              className="text-#808080 text-center footer-border copy"
            >
              Copyright &copy; 2004-2023 GetMovers. All Rights Reserved.
            </p>

            <p style={{ fontSize: "15px" }}>
              {" "}
              getmovers.co.uk is the subsidry of Oneworld Homes Limited.
              regsiered in England and Wales. Under company Registration NO#
              10259644 Registered office <br />{" "}
              <span>
                {" "}
                Kemp House 152-160. City Road London, EC1V 2NX, United Kingdom{" "}
              </span>{" "}
              VAT No. 383022517 </p>
          </Container>{" "}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span className=" contact-us-social-icons pointer">
              <a
                href="https://www.facebook.com/profile.php?id=100090028214453"
                target="_blank"
              >
                <FaFacebook
                  style={{
                    color: "#393f46",
                    margin: "5px",
                    fontSize: "40px",
                  }}
                />
              </a>
              <a href="https://twitter.com/Get_Mover" target="_blank">
                <AiFillTwitterCircle
                  style={{
                    color: "#393f46",
                    margin: "5px",
                    fontSize: "40px",
                  }}
                />
              </a>
              <a href="https://www.instagram.com/get_movers/" target="_blank">
                <FaInstagramSquare
                  style={{
                    color: "#393f46",
                    margin: "5px",
                    fontSize: "40px",
                  }}
                />
              </a>
              <a
                href="https://www.linkedin.com/in/get-movers-84a373265/"
                target="_blank"
              >
                <FaLinkedin
                  style={{
                    color: "#393f46",
                    margin: "5px",
                    fontSize: "40px",
                  }}
                />
              </a>
            </span>
          </div>{" "}
        </footer>
      </>
    );
  }
}

export default Footer;
