import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button'
import 'react-toastify/dist/ReactToastify.css';
import Table from 'react-bootstrap/Table'
import AOS from 'aos';
import {FaRegEdit} from 'react-icons/fa';

//import FurnitureData from '../../json/furnitureData';
import { Form, Input} from 'antd';

class VehicleBrands extends React.Component {
  constructor(props) {
    super(props);
    this.incr=0;
    this.state={
      data:[],
      
      Data:[]
    }
  }
  componentDidMount(){
   fetch('/getCarBrand')
        .then(res=>res.json())
        .then((data)=>{
            this.setState({
                Data:data
            })
            console.log(data)
          
        })
    
  }
  
    render ()
      { 
        const i=0;
        const details =this.state.Data.map((item,index)=>{
          
           
            // var link;
            // if(item.category==='Furniture and Other Items')
            // {
            //   link='/furniture-items'
            // }
            return(
              <tr class="d-flex" key={item._id} >
              <td class="col-1">{index+1}</td>
              <td class="col-9">{item.name}</td>
              <td className="col-2 d-flex justify-content-center"><Button  className='table-view-btn' onClick={()=>this.props.history.push('/vehicle-models',{item:item})}>View</Button></td>
             </tr>
              
        )})

      
        return(
          <>
          <Row className='admin-forms'>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h1 className='page-title'>Cars and Vehicles</h1>
              <hr/> 
            </Col>
            <Col className='pt-3' >
            <p className='float-right mt-3 mb-2 text-orange pointer add-new-post-link' onClick={() =>this.props.history.push('/add-vehicle-brand')}><FaRegEdit/>Add New Brand</p>
                <Table hover size="sm" className='text-left table admin-table' data-aos="fade-up" data-aos-duration="2000">
                  <thead className='bg-orange text-white'>
                    <tr class="d-flex">
                      <th class="col-1">Sr. No</th>
                     
                      <th class="col-9">Brand</th>
                      <th class="col-2  d-flex justify-content-center">Action</th>
                      
                    </tr>
                  </thead>      
                
                <tbody>
                    {details}
                </tbody>
              </Table> 
            </Col>
            
          </Row>
        
        </>
        )
        
     
      
      }

}


export default VehicleBrands;
