import firebase from "firebase/app"
import "firebase/storage"

const firebaseConfig = {
    apiKey: "AIzaSyCtd0tE_usDIVvMB31rl7uyoMyNv41kGJw",
    authDomain: "wholelisticbrain-a0da0.firebaseapp.com",
    databaseURL: "https://wholelisticbrain-a0da0.firebaseio.com",
    projectId: "wholelisticbrain-a0da0",
    storageBucket: "wholelisticbrain-a0da0.appspot.com",
    messagingSenderId: "202214412352",
    appId: "1:202214412352:web:89b824a74696c37d87cfa5"
  };

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export {storage, firebase as default};