import {Row,Col,Image} from 'react-bootstrap';
import React, { useState } from 'react';
import {Table,Button} from 'antd';
import {FiEye} from 'react-icons/fi';
import {MdDateRange} from 'react-icons/md';
import {FaPhoneAlt,FaPassport} from 'react-icons/fa';
// import Button from 'react-bootstrap/Button'
import {AiOutlineMail} from 'react-icons/ai';
import {IoMdTransgender} from 'react-icons/io';
import moment from 'moment';


class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      data:[],
    }
  }
  
  Boiler =async() =>{
    const data1 = await JSON.parse(localStorage.getItem("getmoversuser"))
    console.log(data1._id)
    this.setState({data:data1})
  }

  componentDidMount()
  {
    this.Boiler()
  }
  
    render ()
      {
        
        return(
            <>
              <Row className='admin-forms text-6e6e6e'>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <h1 className='page-title'>Driver DETAILS</h1>
                  <hr/>
                  <br/>
                </Col>
                <Col xs={6} className='request-title pt-3'>
                    <Image className='float-right pointer' src={this.state.data.licenseImage} width='70%' thumbnail/>
                </Col>
                <Col xs={6} className='request-title pt-3'>
                  <h5><span className='font-weight-bold text-orange'>Mr. {this.state.data.name}</span></h5>
                 
                  {/* email */}
                  <p><AiOutlineMail className='text-orange'/> {this.state.data.email}</p>
                  {/* gender */}
                  <p><IoMdTransgender className='text-orange'/> {this.state.data.gender}</p>
                  {/* phone */}
                  <p><FaPhoneAlt className='text-orange'/> {this.state.data.phoneNo}</p>
                  {/* date of birth */}
                  <p><MdDateRange className='text-orange'/> {moment(this.state.data.dob).format('DD MMMM YYYY')}</p>
                  
                  
                </Col>
                <Col xs={6} className='request-title'>
                <br/>
                <h5><span className='font-weight-bold text-orange'>Cnic/Passport No.: </span></h5>
                  <p>{this.state.data.cnic}</p>
                  <h5><span className='font-weight-bold text-orange'>Driving Liscence No.: </span></h5>
                  <p>{this.state.data.drivingLicesnseNo}</p>
                  <h5><span className='font-weight-bold text-orange'>Liscence Issued Date: </span></h5>
                  <p>{moment(this.state.data.licenceIssuedDate).format('DD MMMM YYYY')}</p>
                  <h5><span className='font-weight-bold text-orange'>Liscence Expiry Date: </span></h5>
                  <p>{moment(this.state.data.licenseExpiryDate).format('DD MMMM YYYY')}</p>
                   
                </Col>
              </Row>
            </>
          )
      }

}

export default Profile;
