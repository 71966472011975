import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useState } from 'react';
import {FiEye} from 'react-icons/fi';
import {MdDeleteForever} from 'react-icons/md';
import {FaRegEdit} from 'react-icons/fa';
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import AOS from 'aos';

class AllPostsBlog extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      data:[],
      loading:false
    }
  }
  
    boiler =() =>
  {
    fetch("/getBlog",{
      method:"get",
      headers:{
        "content-Type":"application/json"
      }
    }).then(res=>res.json())
    .then(data=>{  
      this.setState({
        data:data,
        
      })
    })
  }
  componentDidMount()
  {
     this.boiler()
  }
  
  
    render ()
      { 
        const data1 = this.state.data.map((item,index)=>{
                        return(
                          <>
                          <tr class="d-flex">
                            <td class="col-1">{index+1}</td>
                            <td class="col-9">{item.title}</td>
                            <td class="col-2 d-flex justify-content-center"><Button  className='table-view-btn' onClick={() =>this.props.history.push('/update-blog',{item})}>View</Button></td>
                          </tr>
                          </>
                        )
            })
       
        
        
       
          return(
            <>
              
              <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} data-aos="fade-up" data-aos-duration="2000">
              <h1 className='page-title'>Blog Posts</h1>
                <hr/>
                <p className='float-right mt-3 mb-2 text-orange pointer add-new-post-link' onClick={() =>this.props.history.push('/add-post-blog')}><FaRegEdit/>Add New Post</p>
                
                <Table hover size="sm" className='mt-3 text-left' className="table admin-table">
                  <thead className='bg-orange text-white'>
                    <tr class="d-flex">
                      <th class="col-1">Sr. No</th>
                      <th class="col-9">Title</th>
                      <th class="col-2 text-center">Action</th>
                    </tr>
                  </thead>      
                
                <tbody>
                    {data1}
                </tbody>
                  </Table> 
                
              </Col>
                
              </Row>
            </>
          )
      }

}

export default AllPostsBlog;
