import React from "react";
import { Card, Button } from "antd";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import img1 from "../../img/1.jpg";
import about1 from "../../img/who.png";
import about2 from "../../img/why.png";
import img2 from "../../img/why.jpg";
import img3 from "../../img/b9.jpg";
import blogBanner from "../../img/blog-banner.jpg";
import Image from "react-bootstrap/Image";
import CardDeck from "react-bootstrap/CardDeck";
import AOS from "aos";

class Blog extends React.Component {
  constructor(props) {
    super(props);
    AOS.init();
    this.state = {
      data: [],
      loading: false,
    };
  }
  boiler = () => {
    fetch("/getBlog", {
      method: "get",
      headers: {
        "content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          data: data,
        });
      });
  };
  componentDidMount() {
    this.boiler();
  }

  SinglePost = (item) => {
    this.props.history.push("/single-post", {
      item,
      // title:"Guide to moving your Household Items",
      // content:"Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of de Finibus Bonorum et Malorum (The Extremes of Good and Evil) by Cicero, written in 45 BC.",
      // pic1:img1,
      // pic2:img2,
      // pic3:img3,
      // banner:blogBanner
    });
  };

  render() {
    const blogs = this.state.data.map((item, index) => {
      return (
        <div
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
          className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
        >
          <Card
            onClick={() => this.SinglePost(item)}
            hoverable
            cover={
              <img className="cardimg" alt="example" src={item.bannerImg} />
            }
          >
            <h6 className="pointer text-orange font-weight-bold">
              {item.title}
            </h6>
            <p className="text-justify">{item.para1}</p>
            <Button className="blog-btn">Read More</Button>
          </Card>
        </div>
      );
    });
    return (
      <>
        <Container className="mt-5">
          <Row>
            <Col xs={12}>
              <br />
              <h2 className="title1">Blogs</h2>
              <br />
              <h2 className="title3">
                Join the United Kingdom’s Most Convenient Removals and Storage
                Agency Now!
              </h2>
              <br />
            </Col>
            <div
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
            >
              <Image
                src={about1}
                style={{ borderRadius: "10px" }}
                className="float-right"
                width="100%"
              />
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              className="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9"
            >
              <h2 className="text-blue">Partner up with GetMovers!</h2>
              <p className="text-justify">
                Becoming a working member of the GetMovers family allows you to
                work at your convenience. You can set your availability hours
                and take on jobs whenever you deem fit
              </p>
              <p className="text-justify">
                {" "}
                With GetMovers moving just around anything you can find, from
                vehicles to household items, there is never a shortage of jobs.
                We provide our local partners with the flexibility of taking on
                whatever position they deem fit. Think you’re up for it? Get
                started now and add another income stream to your monthly
                revenue.{" "}
              </p>
            </div>
          </Row>
        </Container>

        <Container className="pt-5 pb-5">
          <Row>
            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7"
            >
              <h2 className="text-blue">Why work for us?</h2>
              <p className="text-justify">
                At GetMovers, we’re not just concerned with customer
                satisfaction, but we also make sure to ensure the comfort of our
                own. Becoming a part of the GetMovers family isn’t just in the
                name; we mean it.
              </p>
              <p className="text-justify">Working with GetMovers means:</p>
              <ul style={{ listStyle: "none", color: "#6e6e6e" }}>
                <li>&#10003;You get to set your schedule</li>
                <li>
                  &#10003; Maximise your earning potential while working as you
                  drive
                </li>
                <li>&#10003; Hassle-free registration</li>
                <li>&#10003;Timely payouts</li>
                </ul>
                <p className="text-justify">GetMovers provides a variety of services which typically includes:</p>
               <ul style={{ listStyle: "none", color: "#6e6e6e" }}> <li>&#10003;Packing items</li>
                <li>&#10003; Unloading items</li>
                <li>&#10003; Unpacking objects</li>
                </ul>
            </div>
            <div
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              className="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5"
            >
              <Image
                src={about2}
                style={{ borderRadius: "10px" }}
                width="100%"
              />
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default Blog;
