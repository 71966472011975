import React, {Fragment, useState} from "react";
import {BrowserRouter,Route, Redirect} from 'react-router-dom';
import AdminSidebar from './adminSidebar';
import AdminNavbar from './adminNavbar';
import AllPostsBlog from './blog/all-posts-blog';
import AddPostBlog from './blog/add-post-blog'
import AllPostsAdviceCenter from './advice-center/all-posts-advice-center';
import AddPostAdviceCenter from './advice-center/add-post-advice-center';
import UserRequests from './requests/user-requests'
import DriverRequests from './requests/driver-requests'
import DriverRequest from './requests/driver-request'
import RequestFurniture from './requests/request-furniture'
import RequestHomeRemovals from './requests/request-home-removals'
import Layout from '../layout';
import  {FaBars}  from 'react-icons/fa';
import { FaLessThanEqual } from "react-icons/fa";
import Switch from "react-bootstrap/esm/Switch";
import createBrowserHistory from 'history/createBrowserHistory';
import Index from './index';
import Container from 'react-bootstrap/Container';
import RequestVehicles from './requests/request-vehicles'
import RequestMotorBikes from './requests/request-motorbikes'
import RequestPiano from './requests/request-piano'
import RequestParcelPackages from './requests/request-parcel-packages'
import UpdateAdvice from './advice-center/update-advice'
import UpdateBlog from './blog/update-blog'
import UserConfirmRequests from './requests/confirmUser-Request'
import UserPaidRequests from './requests/Paid-User-Request'
import UserOtherRequests from './requests/Other-User-Request'
import Drivers from './requests/drivers'
import Driver from './requests/driver'
import AssignedRequests from './requests/assignedRequest'
import DoneJobs from './requests/donejobs'
import DriverNewRequests from '../Driver/DriverNewRequests'
import CompletedJobs from '../Driver/CompletedJobs'
import Profile from '../Driver/Profile'
import AllCategories from './categories/all-categories'
import FurnitureItems from './categories/furniture-items'
import SubCategoriesFurniture from './categories/sub-categories-furniture'
import SubCategoriesRemovals from './categories/sub-categories-homeremovals'
import HomeRemovalsItems from './categories/homeremovals-items'
import VehicleBrands from './categories/vehicle-brands'
import VehicleModels from './categories/vehicle-models'
import Motorbikes from './categories/motorbikes'
import AddVehicleBrand from './categories/add-vehicle-brand'
import AddVehicleModel from './categories/add-vehicle-model'
import AddBike from './categories/add-bike'
import AddPiano from './categories/add-piano'
import Piano from './categories/piano'
import ParcelAndPackages from './categories/parcel-and-packages'
import SpecialistAndAntiques from './categories/specialist-and-antiques'
import VehicleParts from './categories/vehicle-parts'
import OfficeRemovals from './categories/office-removals'
import RefferedJobs from '../Driver/RefferedJobs'
import Mileage from "./Mileage";

import 'aos/dist/aos.css';

class AdminLayout extends React.Component {
    constructor(props) {
        super(props);
        this.openHander = this.openHander.bind(this);
        
      }
    state={
        sidebaropen:true,
    }
    history = createBrowserHistory();
    openHander=()=>{
        if (!this.state.sidebaropen) {
         this.setState({sidebaropen:true})
        }
        else 
        {
            this.setState({sidebaropen:false})
        }
    }
    sidebar(){
        if (this.state.sidebaropen) {
            console.log('checking...',this.state.sidebaropen)
            return(
                < AdminSidebar/>    
            )
            
        }
    }
  
    render ()
      {  
        
          return(  
            <BrowserRouter history = {this.history}>
            
            <Route exact path="/" component={()=>window.location=''}/> 

            <Container fluid className='admin-panel-container p-0 m-0'>
              
                <div className="admin-wrapper mt-4 pt-5">
                    {this.sidebar()} 
                    
                    <div className="container-fluid admin-content-area"> 
                        
                        <AdminNavbar click= {this.openHander} />
                            <div className="p-5">
                                <div className="container-fluid p-4 shadow-sm dashboard-content-area">
                                    <Route exact path="/all-posts-advice-center" component={AllPostsAdviceCenter}/>
                                    <Route exact path="/add-post-advice-center" component={AddPostAdviceCenter}/>
                                    <Route exact path="/update-advice" component={UpdateAdvice}/>
                                    <Route exact path="/update-blog" component={UpdateBlog}/>
                                    <Route exact path="/all-posts-blog" component={AllPostsBlog}/>
                                    <Route exact path='/add-post-blog' component={AddPostBlog}/>            
                                    <Route exact path='/newRequest' component={UserRequests}/>            
                                    <Route exact path='/driver-requests' component={DriverRequests}/>
                                    <Route exact path='/driver-request' component={DriverRequest}/>
                                    <Route exact path='/request-furniture' component={RequestFurniture}/>
                                    <Route exact path='/request-home-removals' component={RequestHomeRemovals}/>
                                    <Route exact path='/request-vehicles' component={RequestVehicles}/>
                                    <Route exact path='/request-motorbikes' component={RequestMotorBikes}/>
                                    <Route exact path='/request-piano' component={RequestPiano}/>
                                    <Route exact path='/request-parcel-packages' component={RequestParcelPackages}/>
                                    <Route exact path='/user-confirm-requests' component={UserConfirmRequests}/>
                                    <Route exact path='/user-other-requests' component={UserOtherRequests}/>
                                    <Route exact path='/user-paid-requests' component={UserPaidRequests}/>
                                    <Route exact path='/drivers' component={Drivers}/>
                                    <Route exact path='/driver' component={Driver}/>
                                    <Route exact path='/doneJobs' component={DoneJobs}/>
                                    <Route exact path='/assignedRequests' component={AssignedRequests}/>
                                    <Route exact path='/driverNewRequests' component={DriverNewRequests}/>
                                    <Route exact path='/driverallJobs' component={CompletedJobs}/>
                                    <Route exact path='/profile' component={Profile}/>
                                    <Route exact path='/all-categories' component={AllCategories}/>
                                    <Route exact path='/furniture-items' component={FurnitureItems}/>
                                    <Route exact path='/sub-categories-furniture' component={SubCategoriesFurniture}/>
                                    <Route exact path='/sub-categories-homeremovals' component={SubCategoriesRemovals}/>
                                    <Route exact path='/homeremovals-items' component={HomeRemovalsItems}/>
                                    <Route exact path='/vehicle-brands' component={VehicleBrands}/>
                                    <Route exact path='/vehicle-models' component={VehicleModels}/>
                                    <Route exact path='/motorbikes' component={Motorbikes}/>
                                    <Route exact path='/add-vehicle-brand' component={AddVehicleBrand}/>
                                    <Route exact path='/add-vehicle-model' component={AddVehicleModel}/>
                                    <Route exact path='/add-bike' component={AddBike}/>
                                    <Route exact path='/piano' component={Piano}/>
                                    <Route exact path='/add-piano' component={AddPiano}/>
                                    <Route exact path='/specialist-and-antiques' component={SpecialistAndAntiques}/>
                                    <Route exact path='/vehicle-parts' component={VehicleParts}/>
                                    <Route exact path='/office-removals' component={OfficeRemovals}/>
                                    <Route exact path='/parcel-and-packages' component={ParcelAndPackages}/>
                                    <Route exact path='/driverRefferedRequests' component={RefferedJobs}/>
                                    <Route exact path='/mileage' component={Mileage}/>
                                    
                                </div>
                            </div>
                    </div>
                </div>
            </Container>
        </BrowserRouter>
          )
      }

}

export default AdminLayout;
