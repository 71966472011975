import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image'


class AdviceCenterPost extends React.Component {
  constructor(props) {
    super(props);
    
    
  }
    render ()
      { 
       

          return(
            <>
             <Image src= {this.props.location.state.item.bannerImg} className='advice-center-banner'/>
               <Container fluid>
              
               <Container>
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <h2 className="blog-title">{this.props.location.state.item.title}</h2>
                    
                    </Col>
                    </Row>
                    <Row className='pt-4 pb-2'>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <p className='text-justify'>{this.props.location.state.item.para1}</p>
                    <p className='text-justify'>{this.props.location.state.item.para2}</p>
                    </Col>
                    
                    </Row>
                    <Row>
                    <Col xs={6} className='p-2'>
                      <Image fluid src= {this.props.location.state.item.otherImage1} className='blog-img'/>
                    </Col>
                    <Col xs={6} className='p-2'>
                      <Image fluid src= {this.props.location.state.item.otherImage2} className='blog-img'/>
                    </Col>
                   </Row>
                    <Row className='pb-2'>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <p className='text-justify'>{this.props.location.state.item.para3}</p>
                    
                    </Col>
                    
                    </Row>
                    </Container>
               </Container>
            </>
          )
      }

}

export default AdviceCenterPost;
