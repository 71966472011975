import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import img from '../img/caroline2.png';
import Image from 'react-bootstrap/Image';
import React, {Component } from 'react';
import { Form, Input, Button, Select} from 'antd';
import {RiArrowRightSLine} from 'react-icons/ri';
import GoogleApiWrapper from './googlemap/googleMaps';
import PickupLocation from './googlemap/pickupLocation';
import DropoffLocation from './googlemap/dropoffLocation';

class Piano extends Component
{
    constructor(props) 
    {
        super(props);
        this.submit = this.submit.bind(this);
    }
    state=
    {
        show:false,
        map:true,
        products:[],
        mileage:"",
        piano:[],
        pianotype:'',
        distanceCost:0,
        price:''
        
    }
    componentDidMount(){
        fetch('/getDistanceCost')
        .then(res=>res.json())
        .then((data)=>{
            this.setState({
                distanceCost:data.cost
            })
            console.log("Distance Data is ",data.cost)
        })
        fetch('/getPiano')
             .then(res=>res.json())
             .then((data)=>{
                 this.setState({
                     piano:data
                 })
               
             })
         
       }
// Piano types
    PianoTypes =
    [
        {   value:'SelectMakeBrand', label:'Please Select Your Motorcycle Type'  },
        {   value:'Digital Piano', label:'Digital Piano'  },
        {   value:'Upright Piano', label:'Upright Piano'  },
        {   value:'Grand Piano', label:'Grand Piano'  },
        {   value:'Baby Grand Piano', label:'Baby Grand Piano'  },
        {   value:'Concert Grand Piano', label:'Concert Grand Piano'  },
    ]
    handleMile= (val) =>{
        const miles = val.replace("mi", "");
        this.setState({ 
            mileage:miles + " miles"
        })
        console.log("Value from Furni", val)
    }
    submit(e)
    {
        const {pianoType,PickupFloor,DropoffFloor}=e
        this.state.piano.map((item)=>{
            if(item._id===pianoType)
            {
                this.setState({
                    pianoType:item.type,
                    price:item.price
                })
            }
        })
        this.props.history.push('/email-prices',
        {
           category:this.props.location.state.category,
           pickupLoc:this.props.location.state.pickupLoc, 
           dropoffLoc:this.props.location.state.dropoffLoc,
           lat1:this.props.location.state.lat1,
           lng1:this.props.location.state.lng1,
           lat2:this.props.location.state.lat2,
           lng2:this.props.location.state.lng2,
           pickupFloor:PickupFloor, 
           dropoffFloor:DropoffFloor,
           type:this.state.pianoType,
           price:this.state.price,
           mileage:this.state.mileage,
           distanceCost:this.state.distanceCost,
        })
     
    }
    selectFloor = <Select placeholder="Select Floor" onChange={(e)=>this.setState({pickupFloor:e})} >
                    <Select.Option value="basement" className="movingItemsDropdown">Basement</Select.Option>
                        <Select.Option value="groundfloor" className="movingItemsDropdown">Ground Floor</Select.Option>
                        <Select.Option value="firstfloor" className="movingItemsDropdown">1st Floor</Select.Option>
                        <Select.Option value="secondfloor" className="movingItemsDropdown">2nd Floor</Select.Option>
                        <Select.Option value="thirdfloor" className="movingItemsDropdown">3rd Floor</Select.Option>
                        <Select.Option value="fourfloor" className="movingItemsDropdown">4rth Floor</Select.Option>
                        <Select.Option value="fifthfloor" className="movingItemsDropdown">5th Floor</Select.Option>
                        <Select.Option value="sixfloor" className="movingItemsDropdown">6th Floor</Select.Option>
                    </Select>
    render()
    {     
        const layout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
          };
        return(
                <>
                <Container className="mt-5 mb-5">
                   <br/>
                    <Row className="mt-5"> 
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <h2 className="title1 text-center">Pianos</h2>
                            <br/>
                        </Col>
                        <Col  xs={12} sm={12} md={8} lg={8} xl={8}>
                            <Form {...layout} onFinish={this.submit} >
                                <Row>
                                <Col className='pt-3' xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Image alt="bike"  src={require('../img/piano.jpg')} width="100%"/>
                                </Col>
                                    <Col  xs={12} sm={12} md={8} lg={8} xl={8}>
                                        <Form.Item name="pianoType" label='Please select your piano to move' rules={[{ required: true, message: 'Please select Your Piano Type' }]} >
                                            <Select placeholder="Please select Your Piano Type">
                                                {
                                                    this.state.piano.map(x=>
                                                    <Select.Option value={x._id} className="movingItemsDropdown">{x.type}</Select.Option>
                                                    )
                                                }
                                            </Select>
                                        </Form.Item>
                                        <Form.Item  name="PickupFloor" label='Please select pickup floor' rules={[{ required: true, message: 'Please select Pick Up Floor' }]} >
                                            {this.selectFloor}
                                        </Form.Item>                                       
                                        <Form.Item name="DropoffFloor" label='Please select dropoff floor' rules={[{ required: true, message: 'Please select Delivery Floor' }]} >
                                            {this.selectFloor}
                                        </Form.Item>                                        
                                        <Button type="submit" htmlType="submit" size="large" className="float-right driver-reg-btn" >Next</Button>
                                    </Col>
                                </Row>
                            </Form>
                            <div
                style={{ marginTop: "10px" }}
                className="p-2 text-center shadow-sm bg-orange"
              >
                {this.state.mileage && (
                  <h4 className="text-light">Distance: {this.state.mileage}</h4>
                )}
              </div>
                        </Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                <GoogleApiWrapper 
                                    lat1={this.props.location.state.lat1}
                                    lng1={this.props.location.state.lng1}
                                    lat2={this.props.location.state.lat2}
                                    lng2={this.props.location.state.lng2}
                                    handleMile={this.handleMile}
                                    />
                            </Col>
                    </Row>
                </Container>
                
                </>
        
        );
    }
}
export default Piano;

