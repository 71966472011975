import React from "react";
import { LoadingOutlined } from '@ant-design/icons';
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import rating from "../img/rating.png";
import ebay from "../img/pricing.png";
import darkLogo from "../img/darklogo.svg";
import { RiArrowRightSLine } from "react-icons/ri";
import Modal from "react-bootstrap/Modal";
import { Form, Input, Button, Select } from "antd";
import "antd/dist/antd.css";
import categoriesDetail from "./json/data";
import PlacesAutocomplete from "react-places-autocomplete";
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";

const { Option } = Select;
class GetLocation extends React.Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }
  state = {
    error: "",
    latlng2: "",
    address2: "",
    latlng1: "",
    address1: "",
    loading: false,
    dtlist: categoriesDetail,
  };

  submit() {
    this.setState({ loading: true });
    setTimeout(() => {
      const data = categoriesDetail.map((item, index) => {
        if (this.props.location.state.id === "1") {
          this.props.history.push("/furniture", {
            category: this.props.location.state.category,
            pickupLoc: this.state.address1,
            dropoffLoc: this.state.address2,
            lat1: this.state.latlng1.lat,
            lng1: this.state.latlng1.lng,
            lat2: this.state.latlng2.lat,
            lng2: this.state.latlng2.lng,
          });
        } else if (this.props.location.state.id === "2") {
          this.props.history.push("/home-removals", {
            category: this.props.location.state.category,
            pickupLoc: this.state.address1,
            dropoffLoc: this.state.address2,
            lat1: this.state.latlng1.lat,
            lng1: this.state.latlng1.lng,
            lat2: this.state.latlng2.lat,
            lng2: this.state.latlng2.lng,
          });
        } else if (this.props.location.state.id === "3") {
          this.props.history.push("/vehicle", {
            category: this.props.location.state.category,
            pickupLoc: this.state.address1,
            dropoffLoc: this.state.address2,
            lat1: this.state.latlng1.lat,
            lng1: this.state.latlng1.lng,
            lat2: this.state.latlng2.lat,
            lng2: this.state.latlng2.lng,
          });
        } else if (this.props.location.state.id === "4") {
          this.props.history.push("/motorbike", {
            category: this.props.location.state.category,
            pickupLoc: this.state.address1,
            dropoffLoc: this.state.address2,
            lat1: this.state.latlng1.lat,
            lng1: this.state.latlng1.lng,
            lat2: this.state.latlng2.lat,
            lng2: this.state.latlng2.lng,
          });
        } else if (this.props.location.state.id === "5") {
          this.props.history.push("/piano", {
            category: this.props.location.state.category,
            pickupLoc: this.state.address1,
            dropoffLoc: this.state.address2,
            lat1: this.state.latlng1.lat,
            lng1: this.state.latlng1.lng,
            lat2: this.state.latlng2.lat,
            lng2: this.state.latlng2.lng,
          });
        } else if (this.props.location.state.id === "6") {
          this.props.history.push("/parcelandpackages", {
            category: this.props.location.state.category,
            pickupLoc: this.state.address1,
            dropoffLoc: this.state.address2,
            lat1: this.state.latlng1.lat,
            lng1: this.state.latlng1.lng,
            lat2: this.state.latlng2.lat,
            lng2: this.state.latlng2.lng,
          });
        } else if (this.props.location.state.id === "7") {
          this.props.history.push("/specialist-antiques", {
            category: this.props.location.state.category,
            pickupLoc: this.state.address1,
            dropoffLoc: this.state.address2,
            lat1: this.state.latlng1.lat,
            lng1: this.state.latlng1.lng,
            lat2: this.state.latlng2.lat,
            lng2: this.state.latlng2.lng,
          });
        } else if (this.props.location.state.id === "8") {
          this.props.history.push("/vehicle-parts", {
            category: this.props.location.state.category,
            pickupLoc: this.state.address1,
            dropoffLoc: this.state.address2,
            lat1: this.state.latlng1.lat,
            lng1: this.state.latlng1.lng,
            lat2: this.state.latlng2.lat,
            lng2: this.state.latlng2.lng,
          });
        } else if (this.props.location.state.id === "9") {
          this.props.history.push("/office-removals", {
            category: this.props.location.state.category,
            pickupLoc: this.state.address1,
            dropoffLoc: this.state.address2,
            lat1: this.state.latlng1.lat,
            lng1: this.state.latlng1.lng,
            lat2: this.state.latlng2.lat,
            lng2: this.state.latlng2.lng,
          });
        }
      }); //map ends here......
    }, 3000);
  } //submit function ends here.....

  handleChange1 = (address1) => {
    this.setState({ address1 });
  };

  handleSelect1 = (address1) => {
    this.setState({ address1 });
    geocodeByAddress(address1)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        this.setState({
          latlng1: latLng,
        });
      })
      .catch((error) => console.error("Error", error));
  };

  searchOptions = {
    componentRestrictions: { country: ["uk"] },
    // types: ["(regions)(address)"]
  };

  handleChange2 = (address2) => {
    this.setState({ address2 });
  };

  handleSelect2 = (address2) => {
    this.setState({ address2 });
    geocodeByAddress(address2)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        this.setState({
          latlng2: latLng,
        });
      })
      .catch((error) => console.error("Error", error));
  };

  render() {
    return (
      <>
        <Container className="mt-5 p-5">
          <Row className="row mt-3">
            <Col className="mb-5" xs={12} sm={12} md={12} lg={12} xl={12}>
              <h2 className="title1 text-center">Where To Move?</h2>
            </Col>
            <Col className="mt-2" xs={12} sm={12} md={12} lg={12} xl={12}>
              <Form onFinish={this.submit}>
                <Row>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <h3 className="mt-1 text-blue">
                      We are saving your money and time by
                    </h3>
                    <h5 className="title text-blue">
                      <i>moving your goods at your place</i>
                    </h5>
                    <Form.Item name="PickUpLocation">
                      <PlacesAutocomplete
                        value={this.state.address1}
                        onChange={this.handleChange1}
                        onSelect={this.handleSelect1}
                        searchOptions={this.searchOptions}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div>
                            <input
                              {...getInputProps({
                                placeholder: "Pickup Location or Postcode",
                                className: "location-search-input",
                              })}
                              value={this.state.address1}
                              required
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#f26c4f",
                                      color: "#fff",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#275981",
                                      color: "#fff",
                                      cursor: "pointer",
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <div>{suggestion.description}</div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </Form.Item>

                    <Form.Item name="DropOffLocation">
                      <PlacesAutocomplete
                        value={this.state.address2}
                        onChange={this.handleChange2}
                        onSelect={this.handleSelect2}
                        searchOptions={this.searchOptions}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div>
                            <input
                              required
                              {...getInputProps({
                                placeholder: "Dropoff Location or Postcode",
                                className: "location-search-input",
                              })}
                              value={this.state.address2}
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#f26c4f",
                                      color: "#fff",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#275981",
                                      color: "#fff",
                                      cursor: "pointer",
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <div>{suggestion.description}</div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </Form.Item>
                    <Form.Item>
                    <Button
  type="submit"
  htmlType="submit"
  size="large"
  className="driver-reg-btn"
  
>
  {this.state.loading ? <LoadingOutlined /> : "Next"}

</Button>
                    </Form.Item>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Image
                      alt="van image"
                      src={require("../img/van.jpg")}
                      width="100%"
                    />
                  </Col>
                </Row>
                <Row></Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
export default GetLocation;
