import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button'
import 'react-toastify/dist/ReactToastify.css';
import Table from 'react-bootstrap/Table'
import AOS from 'aos';
import categoriesDetail from '../../json/data';


class AllCategories extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      data:[]
    }
  }
  
  
    render ()
      { 
        const details =categoriesDetail.map((item,index)=>{
          
          
          var link;
          console.log(item.category)
          if(item.category==='Furniture and Other Items')
          {
            link='/sub-categories-furniture' 
          }
          else if(item.category==='Home Removals')
          {
            link='/sub-categories-homeremovals'
          }
          else if(item.category==='Cars and Vehicles')
          {
            link='/vehicle-brands'
          }
          else if(item.category==='Motorbikes')
          {
            link='/motorbikes'
          }
          else if(item.category==='Pianos')
          {
            link='/piano'
          }
          else if(item.category==='Parcels and Packages')
          {
            link='/parcel-and-packages'
          }
          else if(item.category==='Office Removals')
          {
            link='/office-removals'
          }
          else if(item.category==='Specialist and Antiques')
          {
            link='/specialist-and-antiques'
          }
          else if(item.category==='Vehicle Parts')
          {
            link='/vehicle-parts'
          }
           
           
          return(
              <tr class="d-flex" key={item._id} >
              <td class="col-1">{index+1}</td>
              <td class="col-9">{item.category}</td>
              <td className="col-2 d-flex justify-content-center"><Button  className='table-view-btn' onClick={()=>this.props.history.push(link)}>View</Button></td>
            </tr>
            
          )
        })

      
        return(
          <>
          <Row className='admin-forms'>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h1 className='page-title'>Categories</h1>
              <hr/>
            </Col>
            <Col className='pt-3' >
                <Table hover size="sm" className='text-left' className="table admin-table" data-aos="fade-up" data-aos-duration="2000">
                  <thead className='bg-orange text-white'>
                    <tr class="d-flex">
                      <th class="col-1">Sr. No</th>
                     
                      <th class="col-9">Category</th>
                      <th class="col-2 text-center">Action</th>
                    </tr>
                  </thead>      
                
                <tbody>
                    {details}
                </tbody>
              </Table> 
            </Col>
          </Row>
        
        </>
        )
        
     
      
      }

}

export default AllCategories;
