import React,{useEffect} from "react";
import {Col, Container} from 'react-bootstrap'
function Student() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <Container className="pt-5 pb-5">
      <Col xs={12}>
        <br />
        <h4 className="title1">Student Discount</h4>
        <p>
          Are you a student worried about moving out or getting your items over
          to the new dorm? There’s no longer a need to stress; GetMovers has
          your back!{" "}
        </p>
        <p>
          With student accommodation at an all-time high in the United Kingdom,
          there’s a lot to pack and move to and fro from universities.
          Unfortunately, most students usually do not have what it takes to pay
          out a fully-fledged removal from a decent service.
        </p>
        <p>
          Well, that was, until now, at least. With GetMovers, get a student
          discount on your planned move to make it even more affordable!{" "}
        </p>
        <h5 className="text-blue">Why students?</h5>
        <p>
          We realise most students are at the peak of their lives and won’t find
          moving a few items around that difficult. However, student moves can
          be incredibly hectic because most students operate on a very tight
          budget, and thus, need a professional helping hand to get their move
          through quickly; GetMovers has decided to offer a special
          student-exclusive discount of ___ to help with the process.
        </p>
        <h5 className="text-blue">How can you get a student discount?</h5>
        <p>
          If you’re interested in hiring a professional removal and storage
          agency as a student, contact us now for a quote and well-deserved
          discounts.
        </p>
      </Col>
    </Container>
  );
}

export default Student;
