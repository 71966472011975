import React, { useState, useContext } from "react";
import { Form, Input, Button, Checkbox,Spin } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { Card } from "antd";
import { useHistory } from "react-router-dom";
import { userContext } from "../../../App";

const Login = () => {
  const { dispatch } = useContext(userContext);
  const history = useHistory();
  const [passwordError, setPasswordError] = useState(false);
  const [loading, setLoading] = useState(false);

  const submit = (e) => {
    setLoading(true);
    const { email, password } = e;
    console.log(email);
    fetch("/signin", {
      method: "post",
      headers: {
        "content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Yes");
        localStorage.setItem("getmoversjwt", data.token);
        localStorage.setItem("getmoversuser", JSON.stringify(data.user));
        dispatch({ type: "GETMOVERSUSER", payload: data.user });
        console.log(data);
        history.push("/driverNewRequests");
      })
      .catch((err) => {
        console.log(err);
        setPasswordError(true);
      });
      setLoading(false);
  };
  const { Meta } = Card;
  return (
    <>
      <Container fluid className="admin-login-form ">
        <Container className=" pb-5">
          <Row>
            <Col className="pt-5 d-flex justify-content-center">
              {/* cover={<img alt="example" src={LoginImage} k */}
              <Card className="shadow mt-5 " style={{ width: 380 }}>
                <h3 className="login-title">LOGIN</h3>
                <br /> <br />
                <Form
                  name="admin-login"
                  initialValues={{ remember: true }}
                  onFinish={submit}
                >
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Username!",
                      },
                    ]}
                  >
                    <Input
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      placeholder="Username"
                    />
                  </Form.Item>

                  <Form.Item
                    className="mt-3"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Password!",
                      },
                    ]}
                    validateStatus={passwordError ? "error" : ""}
                  >
                    <Input
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      type="password"
                      placeholder="Password"
                      onChange={() => setPasswordError(false)}
                    />
                  </Form.Item>
                  {passwordError && (
                    <div style={{ color: "red", marginTop: "-10px" }}>
                      Incorrect password. Please try again.
                    </div>
                  )}
                  <Form.Item>
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                      <Checkbox>Remember me</Checkbox>
                    </Form.Item>
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="submit"
                      htmlType="submit"
                      size="large"
                      block
                      className="login-btn"
                      loading={loading}
                    >
                      {loading ? <Spin /> : 'Login'}
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default Login;
