import React from 'react';
import {RiTableLine} from 'react-icons/ri';
import {FaMotorcycle,FaCarAlt,FaHome,FaShuttleVan,FaChair,FaIndustry ,} from 'react-icons/fa';
import {FcManager} from "react-icons/fc"
import {GiSofa,GiSeveredHand} from 'react-icons/gi';
import {GoPackage} from 'react-icons/go';
import {CgPiano} from 'react-icons/cg';
import {SiCoronaengine} from 'react-icons/si';
import {ImLoop2} from 'react-icons/im';
import {MdPets} from 'react-icons/md';

 const categoriesDetail=[
    { 
        id:'1',
        image:<GiSofa/>,
        category:'Furniture and Other Items',
        description:'Tables, Sof, Fridges...',
        categoryTitle:'Get Instant Delivery Quotes',
        categoryDescription:'Please give us a few details for instant delivery prices',
        subCategoryTitle:'Final Step - Tell us what you’re moving',
        subCategoryDescription:'Not 100% sure what you’re moving yet? Changing items later is easy!',
          
        
    },
    { 
      id:'2',
      image:<FaHome/>,
      category:'Home Removals',         
      description:'All Home Removal Items...',
      categoryTitle:'Get an accurate quote in just 3 mins!',
      categoryDescription:'Save up to a massive 42% moving through AnyVan',
      subCategoryTitle:'Add items to finalise your price',
      subCategoryDescription:' '
  },
  { 
      id:'3',
      image:<FaCarAlt/>,
      category:'Cars and Vehicles',
      description:' Ford Focus,Tractors, Caravans...',
      categoryTitle:'Instant Car/Vehicle Transport Prices',
      categoryDescription:'Please give us a few details for instant car transport prices',
      subCategoryTitle:' ',
      subCategoryDescription:' '
  },
  { 
      id:'4',
      image:<FaMotorcycle/>,
      category:'Motorbikes',
      description:'Cruisers, Sportsters, Scooters...',
      categoryTitle:'Instant Motorcycle Transport Prices!',
      categoryDescription:'Please give us a few details for instant bike transport prices',
      subCategoryTitle:' ',
      subCategoryDescription:' '
  },
  { 
      id:'5',
      image:<CgPiano/> ,
      category:'Pianos',
      description:'Uprights, Grands, Keyboards...',
      categoryTitle:'Get Instant Piano Moving Prices!',
      categoryDescription:'Please give us a few details for instant piano transport prices',
      subCategoryTitle:' ',
      subCategoryDescription:' '
  },
  { 
      id:'6',
      image:<GoPackage/>,
      category:'Parcels And Packages',
      description:'Of all sizes & shapes...',
      categoryTitle:' ',
      categoryDescription:' ',
      subCategoryTitle:' ',
      subCategoryDescription:' ' 
  },
  { 
      id:'7',
      image:<RiTableLine/> ,
      category:'Specialist And Antiques',
      description:'Glass cabinets, ornaments...',
      categoryTitle:' ',
      categoryDescription:' ',
      subCategoryTitle:' ',
      subCategoryDescription:' '
  },
  { 
      id:'8',
      image:<SiCoronaengine/> ,
      category:'Vehicle Parts',
      description:'Engines, Tyres, Body Parts... ',
      categoryTitle:' ',
      categoryDescription:' ',
      subCategoryTitle:' ',
      subCategoryDescription:' '
  },
  { 
      id:'9',
      image:<FaChair/>,
      category:'Office Removals',
      description:'Desks, Computers, Chairs...',
      categoryTitle:' ',
      categoryDescription:' ',
      subCategoryTitle:' ',
      subCategoryDescription:' '
  },
  
//   { 
//     id:'10',
//     image:<FcManager/>,
//     category:'Man and Van',
//     // description:'Desks, Computers, Chairs...',
//     categoryTitle:' ',
//     categoryDescription:' ',
//     subCategoryTitle:' ',
//     subCategoryDescription:' '
// },
// { 
//     id:'11',
//     image:<FaShuttleVan/>,
//     category:'Vans',
//     // description:'Desks, Computers, Chairs...',
//     categoryTitle:' ',
//     categoryDescription:' ',
//     subCategoryTitle:' ',
//     subCategoryDescription:' '
// },
  
  ]
  export default categoriesDetail;