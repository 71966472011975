import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useState } from 'react';
import { Form, Table,Input,Upload, message} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {FiEye} from 'react-icons/fi';
import {MdDeleteForever} from 'react-icons/md';
import {FaRegEdit} from 'react-icons/fa';
import Button from 'react-bootstrap/Button'
import { toast, Zoom, Bounce} from 'react-toastify'
import { BarLoader, BeatLoader} from 'react-spinners'
import { storage } from '../../config'

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload1(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

function beforeUpload2(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

function beforeUpload3(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}


class AddPostAdviceCenter extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    loading1: false,
    loading2: false,
    loading3: false,
    loading: false,
    imageUrl1:'',
    imageUrl2:'',
    imageUrl3:'',
    disable:true
  };
  
  handleChange1 = e => {
    if (e.file.originFileObj) {

      // console.log(type)
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          console.log(reader.result)
        }
      }
      reader.readAsDataURL(e.file.originFileObj)
      const image = e.file.originFileObj

      this.setState({loading1:true})

      this.uploadToFirebase1(image)
    }
  }
  uploadToFirebase1 = (imageUp) => {

    const uploadTask = storage.ref(`images/${imageUp.name}`).put(imageUp);

    uploadTask.on(
      "state_changed",
      snapshot => { },
      error => {
        console.log(error)
      },
      () => {
        storage
          .ref("images")
          .child(imageUp.name)
          .getDownloadURL()
          .then(url => {
            console.log(url)
            this.setState({
              imageUrl1:url,
              loading:false,
              disable: false,
            })
          })
      }
    )

  };
  handleChange2 =  e => {
    if (e.file.originFileObj) {

      // console.log(type)
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          console.log(reader.result)
        }
      }
      reader.readAsDataURL(e.file.originFileObj)
      const image = e.file.originFileObj

      this.setState({loading2:true})

      this.uploadToFirebase2(image)
    }
  }

  uploadToFirebase2 = (imageUp) => {

    const uploadTask = storage.ref(`images/${imageUp.name}`).put(imageUp);

    uploadTask.on(
      "state_changed",
      snapshot => { },
      error => {
        console.log(error)
      },
      () => {
        storage
          .ref("images")
          .child(imageUp.name)
          .getDownloadURL()
          .then(url => {
            console.log(url)
            this.setState({
              imageUrl2:url,
              loading:false,
              disable: false,
            })
          })
      }
    )

  };

  handleChange3 =  e => {
    if (e.file.originFileObj) {

      // console.log(type)
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          console.log(reader.result)
        }
      }
      reader.readAsDataURL(e.file.originFileObj)
      const image = e.file.originFileObj

      this.setState({loading3:true})

      this.uploadToFirebase3(image)
    }
  }
  uploadToFirebase3 = (imageUp) => {

    const uploadTask = storage.ref(`images/${imageUp.name}`).put(imageUp);

    uploadTask.on(
      "state_changed",
      snapshot => { },
      error => {
        console.log(error)
      },
      () => {
        storage
          .ref("images")
          .child(imageUp.name)
          .getDownloadURL()
          .then(url => {
            console.log(url)
            this.setState({
              imageUrl3:url,
              loading:false,
              disable: false,
              loading3:false
            })
          })
      }
    )

  };
  submit = (e) =>
  {
    this.setState({
      loading:true
  })
      console.log(e)

      fetch("/addAdvice",{
        method:"post",
        headers:{
          "content-Type":"application/json"
        },
        body:JSON.stringify({
            bannerImg:this.state.imageUrl1,
            otherImage1:this.state.imageUrl2,
            otherImage2:this.state.imageUrl3,
            title:e.postTitle,
            para1:e.para1,
            para2:e.para2,
            para3:e.para3
        })
      }).then(res=>res.json())
      .then(data=>
      {
       this.setState({
          loading:false
        })
        console.log(data)
        if(data.error)
        {
         toast.error(data.error,{
           draggable: true,
           hideProgressBar:true,
           transition: Zoom,
           autoClose:7000,
           position: toast.POSITION.TOP_CENTER
         })
        }
        else
        {
        
        toast.success("Advice Added successfully",{
         draggable: true,
         hideProgressBar:true,
         transition: Zoom,
         autoClose:7000,
         position: toast.POSITION.TOP_CENTER  
       })
       this.props.history.push("/newRequest")
     }
      }).catch(err=>{
        this.setState({
            loading:false
        })
        console.log(err)
        toast.error(err.err,{
         draggable: true,
         hideProgressBar:true,
         transition: Zoom,
         autoClose:4000,
         position: toast.POSITION.TOP_CENTER
       })
      })
  }
    render ()
      { 

        const layout = {
          labelCol: { span: 24 },
          wrapperCol: { span: 24 },
        };
        const { loading1, imageUrl1, loading2, imageUrl2,loading3, imageUrl3 } = this.state;
        const uploadButton1 = (
          <div>
            {loading1 ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        );
        const uploadButton2 = (
          <div>
            {loading2 ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        );
        const uploadButton3 = (
          <div>
            {loading3 ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        );
        
        
        if(this.state.loading===true)
        {
          return(
          <div className="loading">
              <BeatLoader loading
                size={20}
                color="#f26c4f" />
              <BarLoader loading 
              css={{display:'block',margin:0}}
              size={40}
              color="#f26c4f"
              />
          </div>
          )
        }
        if(this.state.loading===false)
        {
       
          return(
            <>
              <Row className='add-new-advice-form'>
              
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <h1 className='page-title'>New Advice</h1>
                <hr/>
                  <Form {...layout} onFinish={this.submit} className='pt-3'  > 

                    <Form.Item name='postTitle' label="Post Title"  rules={[{ required: true, message: 'Please your Post title' }]}>
                      
                      <Input placeholder='please enter your post title'/>
                    </Form.Item>

                    <Form.Item name='para1' label="Paragraph 1"  rules={[{ required: true, message: 'Please enter atleast 1 paragraph' }]}>
                      <Input.TextArea />
                    </Form.Item>

                    <Form.Item name='para2' label="Paragraph 2">
                      <Input.TextArea />
                    </Form.Item>

                    <Form.Item name='para3' label="Paragraph 3">
                      <Input.TextArea />
                    </Form.Item>

                    <Row>
                      <Col>
                        <Form.Item name='postBanner' label="Banner image" rules={[{ required: true, message: 'Please select your banner image' }]}>
                          <Upload
                          
                            name="banner"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            accept="image/*"
                            action={``}
                            onChange={this.handleChange1}
                          >
                            {imageUrl1 ? <img src={imageUrl1} alt="avatar" style={{ width: '100%' }} /> : uploadButton1}
                          </Upload>
                        </Form.Item>
                      </Col>

                      <Col>
                        <Form.Item name='image1' label="Other Image 1" rules={[{ required: true, message: 'Image 1 is required' }]}>
                          <Upload
                          
                            name="image11"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            accept="image/*"
                            action={``}
                            onChange={this.handleChange2}
                          >
                            {imageUrl2 ? <img src={imageUrl2} alt="avatar" style={{ width: '100%' }} /> : uploadButton2}
                          </Upload>
                        </Form.Item>
                      </Col>
                      
                      <Col>
                        <Form.Item name='image2' label="Other Image 2" rules={[{ required: true, message: 'Image 2 is required' }]}>
                          <Upload
                          
                            name="image22"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            accept="image/*"
                            action={``}
                            onChange={this.handleChange3}
                          >
                            {imageUrl3 ? <img src={imageUrl3} alt="avatar" style={{ width: '100%' }} /> : uploadButton3}
                          </Upload>
                        </Form.Item>
                      </Col>


                    </Row>
                    <Col xs={12} >
                    <br/>
                      <Button className="publish-btn" type="submit">Publish</Button>
                    </Col>

                     

                  </Form>
                </Col>
              </Row>
            
            </>
          )
      }
    }

}

export default AddPostAdviceCenter;
