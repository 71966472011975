import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container'
import img from '../img/caroline2.png'
import Image from 'react-bootstrap/Image'
import React, {Component } from 'react';
import { Form, Input, Button, Select} from 'antd';
import {RiArrowRightSLine} from 'react-icons/ri';
import GoogleApiWrapper from './googlemap/googleMaps';
import PickupLocation from './googlemap/pickupLocation';
import DropoffLocation from './googlemap/dropoffLocation';

class Motorbike extends Component
{
    constructor(props) 
    {
        super(props);
        this.submit = this.submit.bind(this);
    }
    state=
    {
        bike:'',
        mileage:"",
        price:'',
        distanceCost:0,
        motorbikes:[]
    }
    componentDidMount(){
        fetch('/getDistanceCost')
        .then(res=>res.json())
        .then((data)=>{
            this.setState({
                distanceCost:data.cost
            })
            console.log("Distance Data is ",data.cost)
        })
        fetch('/getBike')
             .then(res=>res.json())
             .then((data)=>{
                 this.setState({
                     motorbikes:data
                 })
             })
         
       }
// Motorbike types
MotorcycleTypes =
[
    {   value:'SelectMakeBrand', label:'Please Select Your Motorcycle Type'  },
    {   value:'standard', label:'Standard'  },
    {   value:'sport', label:'Sport'  },
    {   value:'scooter', label:'Scooter'  },
    {   value:'cruiser', label:'Cruiser'  },
    {   value:'motocross', label:'Motocross'  },
    {   value:'tourer', label:'Tourer'  },
    {   value:'adventure', label:'Adventure'  },
    {   value:'quad bike', label:'Quad Bike'  },
]


    submit(e)
    {
        this.props.history.push('/email-prices',
             {
                category:this.props.location.state.category,
                pickupLoc:this.props.location.state.pickupLoc, 
                dropoffLoc:this.props.location.state.dropoffLoc,
                lat1:this.props.location.state.lat1,
                lng1:this.props.location.state.lng1,
                lat2:this.props.location.state.lat2,
                lng2:this.props.location.state.lng2,
                items:this.state.bike,
                price:this.state.price,
                mileage:this.state.mileage,
                distanceCost:this.state.distanceCost,
             })
    }
    handleMile= (val) =>{
        const miles = val.replace("mi", "");
        this.setState({ 
            mileage:miles + " miles"
        })
        console.log("Value from Furni", val)
    }
    handleChange =(e) =>{
        console.log(e)
        this.state.motorbikes.map((item)=>{
            if(item._id===e)
            {
                console.log(item)
                this.setState({
                    bike:item.name,
                    price:item.price
                })
            }
        })
    }
    render()
    {     
 
        return(
            <>
                <Container className="mt-5 mb-5">
                   <br/>
                    <Row className="mt-5"> 
                        <Col className='mb-5' xs={12} sm={12} md={12} lg={12} xl={12}>
                            <h2 className="title1 text-center">Motorbikes</h2>
                        </Col>
                        <Col  xs={12} sm={12} md={8} lg={8} xl={8}>
                            <Form onFinish={this.submit} >
                                <Row>
                                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Image alt="bike" src={require('../img/bike.jpg')} width="100%"/>
                                </Col>
                                    <Col  xs={12} sm={12} md={8} lg={8} xl={8}>
                                        <h6>Please Select Your Bike type to move</h6>
                                        <Form.Item name="motorcycleType" rules={[{ required: true, message: 'Please select Your Motorcycle Type' }]} >
                                            <Select placeholder="Please select Your Motorcycle Type" onChange={(e)=>{this.handleChange(e)}}>
                                                {
                                                    this.state.motorbikes.map(x=>
                                                        <Select.Option value={x._id} className="movingItemsDropdown">{x.name}</Select.Option>
                                                    )
                                                }
                                            </Select>
                                        </Form.Item>
                                        <Button type="submit" htmlType="submit" size="large" className="float-right driver-reg-btn" >Next</Button>
                                    </Col>
                                </Row>
                            </Form>
                            <div
                style={{ marginTop: "10px" }}
                className="p-2 text-center shadow-sm bg-orange"
              >
                {this.state.mileage && (
                  <h4 className="text-light">Distance: {this.state.mileage}</h4>
                )}
              </div>
                        </Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                <GoogleApiWrapper 
                                    lat1={this.props.location.state.lat1}
                                    lng1={this.props.location.state.lng1}
                                    lat2={this.props.location.state.lat2}
                                    lng2={this.props.location.state.lng2}
                                    handleMile={this.handleMile}
                                    />
                            </Col>
                    </Row>
                </Container>
            </>
        
        );
    }
}
export default Motorbike;

