import React, {useContext} from 'react';
import {Nav} from 'react-bootstrap'
import {NavLink} from 'react-router-dom';
import Image from 'react-bootstrap/Image'
import adminImg from '../../img/caroline2.png'
import {FaCircle} from 'react-icons/fa';
import {userContext} from '../../App'
import NavDropdown from 'react-bootstrap/NavDropdown'
import  {FaShippingFast}  from 'react-icons/fa';
import { toast, Zoom, Bounce} from 'react-toastify'
import {useHistory} from 'react-router-dom';

toast.configure()
const AdminSidebar =() => {

  const {state,dispatch} = useContext(userContext)
  const history= useHistory()
  const logout = () =>
  {
    localStorage.clear()
    dispatch({type:"CLEAR"})
    history.push('/')
    toast.success("You are Logged Out",{
      draggable: true,
      hideProgressBar:true,
      transition: Zoom,
      autoClose:3000,
      position: toast.POSITION.TOP_CENTER
    })
  } 
  const renderList = () =>
  {
    if(state)
    {
        console.log("StateRole", state)
      if(state.role==="admin")
      {
          return[
            <NavLink exact to="/newRequest" className="adminSidebarLinks" activeClassName="adminSidebarActiveLinks">New Removal Requests</NavLink>,
            // <NavLink exact to="/user-confirm-requests" className="adminSidebarLinks" activeClassName="adminSidebarActiveLinks">Confirm Removal Requests</NavLink>,
            <NavLink exact to="/user-paid-requests" className="adminSidebarLinks" activeClassName="adminSidebarActiveLinks">Paid Removal Requests</NavLink>,
            <NavLink exact to="/user-other-requests" className="adminSidebarLinks" activeClassName="adminSidebarActiveLinks">Other Removal Requests</NavLink>,
            <NavLink exact to="/assignedRequests" className="adminSidebarLinks" activeClassName="adminSidebarActiveLinks">Assigned Requests</NavLink>,
            <NavLink exact to="/doneJobs" className="adminSidebarLinks" activeClassName="adminSidebarActiveLinks">Completed Jobs</NavLink>,
            <NavLink exact to="/all-categories" className="adminSidebarLinks" activeClassName="adminSidebarActiveLinks">Pricing Structure</NavLink>,

            <NavLink exact to="/driver-requests" className="adminSidebarLinks" activeClassName="adminSidebarActiveLinks">New Driver Requests</NavLink>,
            <NavLink exact to="/drivers" className="adminSidebarLinks" activeClassName="adminSidebarActiveLinks">Drivers</NavLink>,
            <NavLink exact to="/all-posts-advice-center" className="adminSidebarLinks" activeClassName="adminSidebarActiveLinks">Advice Center</NavLink>,
            <NavLink exact to="/all-posts-blog" className="adminSidebarLinks" activeClassName="adminSidebarActiveLinks">Blogs</NavLink>,
            <NavLink exact to="/mileage" className="adminSidebarLinks" activeClassName="adminSidebarActiveLinks">Mileage</NavLink>,
          ]
      }
      else
      {
        return[
          <NavLink exact to="/" className="adminSidebarLinks" activeClassName="adminSidebarActiveLinks">Home</NavLink>,
          <NavLink exact to="/driverRefferedRequests" className="adminSidebarLinks" activeClassName="adminSidebarActiveLinks">New Jobs</NavLink>,
          <NavLink exact to="/driverNewRequests" className="adminSidebarLinks" activeClassName="adminSidebarActiveLinks">Assigned Jobs</NavLink>,
          <NavLink exact to="/profile" className="adminSidebarLinks" activeClassName="adminSidebarActiveLinks">Profile</NavLink>,
          <NavLink exact to="/driverallJobs" className="adminSidebarLinks" activeClassName="adminSidebarActiveLinks">All Jobs</NavLink>,
        ]
      }
    }
  }
  return(
    <>
    <Nav id="admin-panel-sidebar" className=" flex-column shadow-sm">
    {/* <Brand logo={logo} className="p-3"/>             */}
        <div className='p-3 sidebar-title'>
          <div className='d-flex justify-content-center'>
          <FaShippingFast style={{fontSize:50, color:'#fff'}} className='logo text-orange'/>
            {/* <Image className='d-flex justify-content-center' src={adminImg} roundedCircle />
              <p><span className='online-status'><FaCircle /></span></p> */}
          </div>
          <p className=' text-center text-orange'>Get Movers</p>
        </div> 
        {renderList()}    
</Nav>
    </>
  )
}

export default AdminSidebar;
