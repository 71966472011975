import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useState } from 'react';
import { Form, Table,Input,Upload, message} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {FiEye} from 'react-icons/fi';
import {MdDeleteForever} from 'react-icons/md';
import {FaRegEdit} from 'react-icons/fa';
import Button from 'react-bootstrap/Button'
import { toast, Zoom, Bounce} from 'react-toastify'
import { BarLoader, BeatLoader} from 'react-spinners'
import { storage } from '../../config'




class AddVehicleBrand extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    loading:false
  };
  
  
  
 
  


  submit = (e) =>
  {
    this.setState({
      //data:data,
      loading:true
    })
    console.log("e",e.brandName)
    fetch("/addCarBrand",{
      method:"post",
      headers:{
        "content-Type":"application/json"
      },
      body:JSON.stringify({
        name:e.brandName 
      })
    }).then(res=>res.json())
    .then((data)=>{
      
        this.setState({
          //data:data,
          loading:true
        })
        toast.success("Added Successfully",{
          draggable: true,
          hideProgressBar:true,
          transition: Zoom,
          autoClose:7000,
          position: toast.POSITION.TOP_CENTER  
        })
        this.props.history.push('/vehicle-brands')
       
    })
  }
  
    render ()
      { 
        const layout = {
          labelCol: { span: 24 },
          wrapperCol: { span: 24 },
        };
        if(this.state.loading===true)
        {
          return(
          <div className="loading">
              <BeatLoader loading
                size={20}
                color="#f26c4f" />
              <BarLoader loading 
              css={{display:'block',margin:0}}
              size={40}
              color="#f26c4f"
              />
          </div>
          )
        }
        if(this.state.loading===false)
        {
       return(
            <>
              <Row className='add-new-advice-form'>
              
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <h1 className='page-title'>Add Vehicle Brand</h1>
                <hr/>
                  <Form {...layout} onFinish={this.submit} className='pt-3' > 

                    <Form.Item name='brandName' label="Brand Name"  rules={[{ required: true, message: 'Please Your Enter Brand Name' }]}>
                      
                      <Input placeholder='please enter your Brand Name'/>
                    </Form.Item>
                    
                      <br/>
                     
                      <Button className="publish-btn" type="submit">Submit</Button>
                   
                  </Form>
                </Col>

              </Row>
            
            </>
          )
       }
        }
     

}

export default AddVehicleBrand;
