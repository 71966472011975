import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button'
import 'react-toastify/dist/ReactToastify.css';
import Table from 'react-bootstrap/Table'
import AOS from 'aos';
//import FurnitureData from '../../json/furnitureData';
import Form from 'react-bootstrap/Form'

class HomeRemovalsItems extends React.Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }
    state={
      data:[],
      Data:[],
      item1Price:'',
      item2Price:'',
      item3Price:'',
      item4Price:'',
      item5Price:'',
    
  }
  
  componentDidMount(){
    if(this.props.location.state.cat==='bedroom')
    {
      fetch('/getBedroom')
      .then(res=>res.json())
      .then((data)=>{
        
          this.setState({
            Data:data
          })
          
      })
    }

    else if(this.props.location.state.cat==='kitchen')
    {
      fetch('/getKitchen')
      .then(res=>res.json())
      .then((data)=>{
        
          this.setState({
            Data:data
          })
          
      })
    }
    else if(this.props.location.state.cat==='man')
    {
      fetch('/getMan')
      .then(res=>res.json())
      .then((data)=>{
        
          this.setState({
            Data:data
          })
          
      })
    }

    else if(this.props.location.state.cat==='tvlounge')
    {
      fetch('/getTvLounge')
      .then(res=>res.json())
      .then((data)=>{
        
          this.setState({
            Data:data
          })
          
      })
    }
    else if(this.props.location.state.cat==='garden')
    {
      fetch('/getGarden')
      .then(res=>res.json())
      .then((data)=>{
        
          this.setState({
            Data:data
          })
          
      })
    }
    else if(this.props.location.state.cat==='office')
    {
      fetch('/getOffice')
      .then(res=>res.json())
      .then((data)=>{
        
          this.setState({
            Data:data
          })
          
      })
    }
    else if(this.props.location.state.cat==='dining')
    {
      fetch('/getDining')
      .then(res=>res.json())
      .then((data)=>{
        
          this.setState({
            Data:data
          })
          
      })
    }
    else if(this.props.location.state.cat==='box')
    {
      fetch('/getBox')
      .then(res=>res.json())
      .then((data)=>{
        
          this.setState({
            Data:data
          })
          
      })
    }
   
   else if(this.props.location.state.cat==='bathroom')
    {
      fetch('/getBathroom')
      .then(res=>res.json())
      .then((data)=>{
        
          this.setState({
            Data:data
          })
          
      })
    }
    
  }
  submit(){
    
  }
    render ()
      { 
        
        const details =  this.state.Data.map((item,index)=>{
          
          return(
              
              <>
                <tr class="d-flex" key={item._id} >
                  <td class="col-1">{index+1}</td>
                  <td class="col-3">{this.props.location.state.cat}</td>
                  <td class="col-4">{item.value}</td> 
                  <td class="col-4">
                  {/* <Form onFinish={this.submit}>
                    <Form.Item name={item.name} rules={[{ required: true, message: 'This field is required' }]}>
                      <Input value={item.price}/>
                    </Form.Item>
                  </Form> */}
                    <Form.Control size="sm" type="text" name={item.name}  value={item.price} />
                  </td>
                </tr>
                
              </>
            )
          })
          
            
          
        

      
        return(
          <>
          <Row className='admin-forms'>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h1 className='page-title'>Home Removals</h1>
              <hr/> 
            </Col>
            <Col className='pt-3' >
                <Table hover size="sm" className='text-left' className="table admin-table" data-aos="fade-up" data-aos-duration="2000">
                  <thead className='bg-orange text-white'>
                    <tr class="d-flex">
                      <th class="col-1">Sr. No</th>
                     
                      <th class="col-3">Sub Category</th>
                      <th class="col-4">Item</th>
                      <th class="col-4">Price</th>
                    </tr>
                  </thead>      
                
                <tbody>
                    {details}
                </tbody>
              </Table> 
            </Col>
            <Col xs={12} >
              <br/>
              <Button className="publish-btn" onClick={this.submit} >Update</Button>
            </Col>
          </Row>
        
        </>
        )
        
     
      
      }

}

export default HomeRemovalsItems;
