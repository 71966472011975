import React from "react";
import { Card } from "antd";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import banner from "../../img/office.jpg";
import about1 from "../../img/pro.png";
import about2 from "../../img/tipp.png";
import about3 from "../../img/difi.jpg";
import about4 from "../../img/b3.jpg";
import about5 from "../../img/b5.jpg";
import about6 from "../../img/art.png";
import about7 from "../../img/book.png";
import img1 from "../../img/slider4.jpg";
import img2 from "../../img/slider3.png";
import { Rate } from "antd";
import Image from "react-bootstrap/Image";

import CardDeck from "react-bootstrap/CardDeck";
import AOS from "aos";

class AdviceCenter extends React.Component {
  
  constructor(props) {
    
    super(props);
    AOS.init();

    this.state = {
      data: [],
      loading: false,
    };
  }
  boiler = () => {
    fetch("/getAdvice", {
      method: "get",
      headers: {
        "content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          data: data,
        });
      });
  };
  componentDidMount() {
    this.boiler();
  }
  SinglePost = (item) => {
    this.props.history.push("/advice-center-post", {
      item,
      // title:"Top Tips For Packing Up Your Home",
      // content:"Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of de Finibus Bonorum et Malorum (The Extremes of Good and Evil) by Cicero, written in 45 BC.",
      // pic1:img1,
      // pic2:img2,
      // banner:banner
    });
  };
  render() {
    const advices = this.state.data.map((item, index) => {
      return (
        <div
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
          className="advice-center-card col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
        >
          <Card
            onClick={() => this.SinglePost(item)}
            hoverable
            cover={
              <img className="cardimg" alt="example" src={item.otherImage1} />
            }
          >
            <h6 className="advice-center-card-title">
              {item.title}
              <span className="text-blue"> - 10 Oct 18</span>
            </h6>
          </Card>
        </div>
      );
    });
    return (
      <>
        <Container className="mt-5">
          <Row>
            <Col xs={12}>
              <br />
              <h2 className="title1">Advice From Us</h2>
              <br />
              <h5 className="title3">
                Planning to make a move soon? Here are a few things you should
                consider:
              </h5>
              <br />
            </Col>
            <div
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
            >
              <Image
                src={about1}
                style={{ borderRadius: "10px" }}
                className="float-right"
                width="100%"
              />
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              className="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9"
            >
              <h2 className="text-blue">Why hire professional movers?</h2>
              <p className="text-justify">
                Like, couldn’t you just do it yourself? While you technically
                could, there’s a lot that comes with a move. From proper
                planning to making sure everything is adequately taken care of.
                Here are a few reasons why you may prefer hiring professional
                movers instead of just doing it yourself:
              </p>
              <ul style={{ listStyle: "none", color: "#6e6e6e" }}>
                <li>
                  &#10003; Save yourself money:
                  <p className="text-justify">
                    Sometimes, getting a professional agency to do your work for
                    you can end up being cheaper. Since professional movers will
                    do most of your work for you, you wouldn’t need to take time
                    off. Carry along with your regular schedule!
                  </p>
                </li>
                <li>
                  &#10003; Leave the complications to the professionals:
                  <p className="text-justify">
                    People who’re planning to move out of state or country need
                    to prepare much more than those planning a local move. There
                    are numerous legal and custom barriers to consider. With
                    professional movers, these barriers are taken care of
                    without any need for intervention individually.
                  </p>
                </li>
                <li>
                  &#10003; Save time:
                  <p className="text-justify">
                    Experienced movers can, at the very least, halve the time
                    taken by a DIY-style move.{" "}
                  </p>
                </li>
                <li>
                  &#10003; Avoid exerting yourself:
                  <p className="text-justify">
                    Moving takes a lot of toll on the body. DIY movers are prone
                    to injury, and lifting heavy items should usually be left to
                    professionals with prior experience under their belts.
                  </p>
                </li>
              </ul>
            </div>
          </Row>
        </Container>

        <Container className="mt-5">
          <Row>
            <div
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
            >
              <Image
                src={about2}
                style={{ borderRadius: "10px" }}
                className="float-right"
                width="100%"
              />
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              className="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9"
            >
              <h2 className="text-blue">
                How much should you tip your movers?
              </h2>

              <p className="text-justify">
                The standard tipping rate for professional movers is between £3
                and £6 per hour for each mover. On average, people set aside
                around 20% of their total budget to tip their movers. Tipping
                doesn’t usually cost more than 20% of the total cost of the move
                unless, of course, you’re an extremely generous tipper.
              </p>
              <p className="text-justify">
                Point to note: You should always tip everyone the same amount of
                money per hour, be it the movers or the lead workers. Unequal
                tipping can lead to dissatisfaction amongst the crew members.
                With GetMovers, making more money in tips should depend more on
                the number of hours worked and not the position worked.
              </p>
            </div>
          </Row>
        </Container>
        <Container className="mt-5">
          <Row>
            <div
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
            >
              <Image
                src={about3}
                style={{ borderRadius: "10px" }}
                className="float-right"
                width="100%"
              />
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              className="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9"
            >
              <h2 className="text-blue">
                What are the four most difficult objects to move?
              </h2>
              <p className="text-justify">
                Moving is pretty complicated; however, these four items can make
                your moving experience even worse!
              </p>
            </div>
          </Row>
        </Container>
        <Container className="mt-5">
          <Row>
            <div
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
            >
              <Image
                src={about4}
                style={{ borderRadius: "10px" }}
                className="float-right"
                width="100%"
              />
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              className="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9"
            >
              <h4 className="text-blue">Cars</h4>
              <p className="text-justify">
                Cars can either be the easiest or the hardest object to move;
                there’s just no in-between. For a functioning vehicle with at
                least one driver and a nearby location, the move will take place
                without any significant hiccups.
              </p>
              <p className="text-justify">
                But for single or multiple inoperable vehicles with a distant
                location, you get a headache beyond your capacity. For people
                who don’t know how to operate a tow truck, calling in a
                professional moving agency or a dedicated automobile transport
                service is the best decision at hand.
              </p>
            </div>{" "}
          </Row>
        </Container>
        <Container className="mt-5">
          <Row>
            <div
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
            >
              <Image
                src={about5}
                style={{ borderRadius: "10px" }}
                className="float-right"
                width="100%"
              />
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              className="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9"
            >
              <h4 className="text-blue">Pianos</h4>
              <p className="text-justify">
                Pianos are infamous for being a frustrating mix of heavy and
                fragile.
              </p>
              <p className="text-justify">
                They aren’t just large; they’re cumbersome and fragile too.
                Pianos weigh around 340 kilograms (750 pounds), which quickly
                puts them out of the lifting range of most families. Combine
                that with the fact that pianos have a super delicate balance of
                hammers, strings, and interconnected keys, which can get all
                messed up or broken easily, and you've got yourself the ultimate
                nightmare of moving a piano
              </p>
              <p className="text-justify">
                Professionals, who are used to dealing with pianos, aren’t just
                strong people, they’re also highly familiar with the musical
                instrument and its know-how.
              </p>
            </div>
          </Row>
        </Container>
        <Container className="mt-5">
          <Row>
            <div
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
            >
              <Image
                src={about6}
                style={{ borderRadius: "10px" }}
                className="float-right"
                width="100%"
              />
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              className="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9"
            >
              <h4 className="text-blue">Pottery and Art</h4>

              <p className="text-justify">
                The difficulty of moving pottery or art goes without saying. The
                average pottery piece can break or crack at a slight drop or
                mishandling. On the other hand, art forms, such as paintings,
                might not seem as breakable, but they are much more prone to
                damage from other factors such as heat, light or smudging.
              </p>
              <p className="text-justify">
                Furthermore, dealing with such pieces often means handling an
                object that is simply not replaceable, for instance, a family
                heirloom. Hence, pottery or art of any kind must be handled very
                carefully on every step of the moving journey from the initial
                wrap to the final unloading.
              </p>
            </div>
          </Row>
        </Container>
        <Container className="mt-5">
          <Row>
            <div
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
            >
              <Image
                src={about7}
                style={{ borderRadius: "10px" }}
                className="float-right"
                width="100%"
              />
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              className="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9"
            >
              <h4 className="text-blue">Firearms</h4>
              <p className="text-justify">
                Moving firearms presents not only safety but also legal concerns
                during a move. When moving, guns should be packed into safety
                cases that are appropriately labelled. Furthermore, all movers
                (or participants if it’s a DIY move) should be informed
                beforehand about the presence of the firearms to be relocated.
              </p>

              <p className="text-justify">
                The removal and storage of firearms can also be deemed illegal
                under certain circumstances. When planning a move, be sure to
                educate yourself on the gun safety and relocation laws of the
                intended location before making a move.
              </p>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default AdviceCenter;
