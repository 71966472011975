import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import img from "../img/caroline2.png";
import Image from "react-bootstrap/Image";
import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Form, Input, Button } from "antd";
import { toast, Zoom, Bounce } from "react-toastify";
import { BarLoader, BeatLoader } from "react-spinners";
import PackImg from "../img/pack.png";
import UnpackImg from "../img/unpack.png";
// import 'react-toastify/dist/ReactToastify.css';
import CardDeck from "react-bootstrap/CardDeck";
import AOS from "aos";
import { Card } from "antd";
import { Tabs } from "antd";
import moment from "moment";
import { Calendar, Alert } from "antd";
import EventDetails from "./json/eventsData";
import Moment from "moment";

const { TabPane } = Tabs;
toast.configure();
var totalPrice = 0;
var totalPricewithPacking = 0;
var dd = new Date();
var eventArray = new Array();
class EmailPrices extends React.Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.dateCellRender = this.dateCellRender.bind(this);
    this.dateCellRender1 = this.dateCellRender1.bind(this);
    this.monthCellRender = this.monthCellRender.bind(this);
    this.monthCellRender1 = this.monthCellRender1.bind(this);
    this.getMonthData = this.getMonthData.bind(this);
  }
  state = {
    show: false,
    loading: true,
    quotation: false,
    email: "",
    totprice: "37",
    totpricewithPacking: "",
    currentDate: "",
    value: moment(),
    selectedValue: "",
    //moment('2017-01-22'),
    price: "",
  };

  handleClick = () => {
    // Send email here
    sendEmail()
      .then(() => {
        toast.success("Email sent successfully!", {
          draggable: true,
          hideProgressBar: true,
          transition: Zoom,
          autoClose: 7000,
          position: toast.POSITION.TOP_CENTER,
        });
        this.props.history.push("/");
      })
      .catch((error) => {
        toast.error(`Failed to send email: ${error.message}`);
      });
  };

  Boiler = () => {
    EventDetails.map((item) => {
      eventArray.push(item.date);
      console.log("Event Dates", eventArray);
    });
    this.setState({
      loading: false,
    });
  };
  componentDidMount() {
    var d = new Date();

    d.setMonth(d.getMonth() + 3);
    this.setState({ currentDate: Moment(d).format("YYYY-MM-DD") });

    const cat = this.props.location.state.category;
    if (cat === "Cars and Vehicles") {
      console.log("category :", this.props.location.state.category);
      console.log("pickupLoc :", this.props.location.state.pickupLoc);
      console.log("dropoffLoc :", this.props.location.state.dropoffLoc);
      console.log("brand :", this.props.location.state.brand);
      console.log("model :", this.props.location.state.model);
      console.log("price :", this.props.location.state.price);
      console.log("mileage :", this.props.location.state.mileage);
      console.log("lat1 :", this.props.location.state.lat1);
      console.log("lng1 :", this.props.location.state.lng1);
      console.log("lat2 :", this.props.location.state.lat2);
      console.log("lng2 :", this.props.location.state.lng2);
      const totalDistanceCost =
        parseFloat(this.props.location.state.mileage) *
        parseFloat(this.props.location.state.distanceCost);
      console.log("Total Distance is", totalDistanceCost);
      const fPrice =
        parseFloat(this.props.location.state.price) +
        parseFloat(totalDistanceCost);
      this.setState({
        totprice: fPrice,
      });
      const percentage =
        parseFloat(this.props.location.state.price) * (15 / 100);
      const price = parseFloat(fPrice) + parseFloat(percentage);
      console.log("calculated price", percentage);
      totalPricewithPacking = price;
      this.setState({
        totpricewithPacking: price,
      });
    } else if (cat === "Motorbikes") {
      console.log("category :", this.props.location.state.category);
      console.log("pickupLoc :", this.props.location.state.pickupLoc);
      console.log("dropoffLoc :", this.props.location.state.dropoffLoc);
      console.log("items :", this.props.location.state.items);
      console.log("lat1 :", this.props.location.state.lat1);
      console.log("lng1 :", this.props.location.state.lng1);
      console.log("lat2 :", this.props.location.state.lat2);
      console.log("lng2 :", this.props.location.state.lng2);
      const totalDistanceCost =
        parseFloat(this.props.location.state.mileage) *
        parseFloat(this.props.location.state.distanceCost);
      console.log("Total Distance is", totalDistanceCost);
      const fPrice =
        parseFloat(this.props.location.state.price) +
        parseFloat(totalDistanceCost);
      this.setState({
        totprice: fPrice,
      });
      const percentage =
        parseFloat(this.props.location.state.price) * (15 / 100);
      const price = parseFloat(fPrice) + parseFloat(percentage);
      console.log("calculated price", percentage);
      totalPricewithPacking = price;
      this.setState({
        totpricewithPacking: price,
      });
    } else if (cat === "Pianos") {
      console.log("category :", this.props.location.state.category);
      console.log("pickupLoc :", this.props.location.state.pickupLoc);
      console.log("dropoffLoc :", this.props.location.state.dropoffLoc);
      console.log("items :", this.props.location.state.type);
      console.log("lat1 :", this.props.location.state.lat1);
      console.log("lng1 :", this.props.location.state.lng1);
      console.log("lat2 :", this.props.location.state.lat2);
      console.log("lng2 :", this.props.location.state.lng2);
      const totalDistanceCost =
        parseFloat(this.props.location.state.mileage) *
        parseFloat(this.props.location.state.distanceCost);
      console.log("Total Distance is", totalDistanceCost);
      const fPrice =
        parseFloat(this.props.location.state.price) +
        parseFloat(totalDistanceCost);
      this.setState({
        totprice: fPrice,
      });
      const percentage =
        parseFloat(this.props.location.state.price) * (15 / 100);
      const price = parseFloat(fPrice) + parseFloat(percentage);
      console.log("calculated price", percentage);
      totalPricewithPacking = price;
      this.setState({
        totpricewithPacking: price,
      });
    } else if (
      cat === "Parcels and Packages" ||
      cat === "Specialist and Antiques" ||
      cat === "Vehicle Parts" ||
      cat === "Office Removals"
    ) {
      // console.log('category :',this.props.location.state.category)
      // console.log('pickupLoc :',this.props.location.state.pickupLoc)
      // console.log('dropoffLoc :',this.props.location.state.dropoffLoc)
      // console.log('items :',this.props.location.state.items)
      // console.log('lat1 :',this.props.location.state.lat1)
      // console.log('lng1 :',this.props.location.state.lng1)
      // console.log('lat2 :',this.props.location.state.lat2)
      // console.log('lng2 :',this.props.location.state.lng2)
      var totPrice = 0;
      const totalDistanceCost =
        parseFloat(this.props.location.state.mileage) *
        parseFloat(this.props.location.state.distanceCost);
      this.props.location.state.items.map((item) => {
        console.log("Item", item);
        const a1 = item.width * item.height;
        const a2 = item.height * item.depth;
        const a3 = item.width * item.depth;
        const a4 = a1 + a2 + a3;
        const area = a4 * 2;
        var finalArea;
        var weight;
        if (item.lengthIn === "ft") {
          finalArea = area * 0.0833333 * parseFloat(item.sqPrice);
        } else if (item.lengthIn === "cm") {
          finalArea = area * 2.54 * parseFloat(item.sqPrice);
        } else if (item.lengthIn === "mm") {
          finalArea = area * 25.4 * parseFloat(item.sqPrice);
        } else if (item.lengthIn === "m") {
          finalArea = area * 0.0254 * parseFloat(item.sqPrice);
        } else {
          finalArea = area * parseFloat(item.sqPrice);
        }

        if (item.weightIn === "kg") {
          weight = item.weight * parseFloat(item.kgPrice);
        } else {
          weight = (item.weight / 2.20462) * parseFloat(item.kgPrice);
        }
        console.log("Total Area is", finalArea);
        console.log("Weight is ", weight);
        const totalPrice1 =
          (parseFloat(finalArea) + parseFloat(weight)) *
          parseFloat(item.quantity);
        console.log("Total price if per area is 1", totalPrice1);
        totPrice = parseFloat(totPrice) + parseFloat(totalPrice1);
      });
      console.log("Total prices is", totPrice);
      console.log("Mileage is ", totalDistanceCost);
      const fPrice = parseFloat(totPrice) + parseFloat(totalDistanceCost);
      this.setState({
        totprice: fPrice,
      });
      const percentage = parseFloat(totPrice) * (15 / 100);
      const price = parseFloat(fPrice) + parseFloat(percentage);
      console.log("calculated price", percentage);
      totalPricewithPacking = price;
      this.setState({
        totpricewithPacking: price,
      });
    } else if (cat === "Home Removals") {
      console.log("category :", this.props.location.state.category);
      console.log("pickupLoc :", this.props.location.state.pickupLoc);
      console.log("dropoffLoc :", this.props.location.state.dropoffLoc);
      console.log("lat1 :", this.props.location.state.lat1);
      console.log("lng1 :", this.props.location.state.lng1);
      console.log("lat2 :", this.props.location.state.lat2);
      console.log("lng2 :", this.props.location.state.lng2);
      console.log("pickup floor :", this.props.location.state.pickupFloor);
      console.log("dropoff floor :", this.props.location.state.dropoffFloor);
      console.log("other Item :", this.props.location.state.otherItem);
      console.log("bedroom :", this.props.location.state.bedroom);
      console.log("man :", this.props.location.state.man);
      console.log("garden :", this.props.location.state.garden);
      console.log("office :", this.props.location.state.office);
      console.log("dining :", this.props.location.state.dining);
      console.log("box :", this.props.location.state.box);
      console.log("kitchen :", this.props.location.state.kitchen);
      console.log("tvlounge :", this.props.location.state.tvlounge);
      console.log("bathroom :", this.props.location.state.bathroom);
      const totalDistanceCost =
        parseFloat(this.props.location.state.mileage) *
        parseFloat(this.props.location.state.distanceCost);
      totalPrice =
        parseFloat(totalDistanceCost) +
        parseFloat(this.props.location.state.priceCalculation);
      this.setState({
        totprice: totalPrice,
      });
      const percentage =
        parseFloat(this.props.location.state.priceCalculation) * (15 / 100);
      const price = parseFloat(totalPrice) + parseFloat(percentage);
      console.log("calculated price", percentage);
      totalPricewithPacking = price;
      this.setState({
        totpricewithPacking: totalPricewithPacking,
      });
    } else {
      console.log("category :", this.props.location.state.category);
      console.log("pickupLoc :", this.props.location.state.pickupLoc);
      console.log("dropoffLoc :", this.props.location.state.dropoffLoc);
      console.log("lat1 :", this.props.location.state.lat1);
      console.log("lng1 :", this.props.location.state.lng1);
      console.log("lat2 :", this.props.location.state.lat2);
      console.log("lng2 :", this.props.location.state.lng2);
      console.log("pickup floor :", this.props.location.state.pickupFloor);
      console.log("dropoff floor :", this.props.location.state.dropoffFloor);
      console.log("items :", this.props.location.state.items);
      console.log("other Item :", this.props.location.state.otherItem);
      const totalDistanceCost =
        parseFloat(this.props.location.state.mileage) *
        parseFloat(this.props.location.state.distanceCost);
      totalPrice = parseFloat(totalDistanceCost) + parseFloat(totalPrice);
      totalPricewithPacking =
        parseFloat(totalDistanceCost) + parseFloat(totalPricewithPacking);
      for (var i = 0; i < this.props.location.state.items.length; i++) {
        const price =
          parseFloat(this.props.location.state.items[i].price) *
          parseFloat(this.props.location.state.items[i].qty);
        console.log("calculated price", price);
        totalPrice = parseFloat(price) + parseFloat(totalPrice);
        console.log("cal price", totalPrice);

        this.setState({
          totprice: totalPrice,
        });
      }
      for (var i = 0; i < this.props.location.state.items.length; i++) {
        const pri =
          parseFloat(this.props.location.state.items[i].price) *
          parseFloat(this.props.location.state.items[i].qty);
        const percentage = parseFloat(pri) * (15 / 100);
        const price = parseFloat(pri) + parseFloat(percentage);
        console.log("calculated price", price);
        totalPricewithPacking =
          parseFloat(price) + parseFloat(totalPricewithPacking);
        console.log("cal price", totalPricewithPacking);

        this.setState({
          totpricewithPacking: totalPricewithPacking,
        });
      }
    }
    this.Boiler();
  }

  dateCellRender(value) {
    console.log("Coming value is", value);
    const date = new Date();
    if (
      value.format("YYYY-MM-DD") > Moment(date).format("YYYY-MM-DD") &&
      value.format("YYYY-MM-DD") < this.state.currentDate
    ) {
      if (eventArray.includes(value.format("YYYY-MM-DD"))) {
        return (
          <>
            <p className="text-blue">
              {" "}
              {"£" + (2 * this.state.totprice + 100)}
            </p>
          </>
        );
      } else {
        return (
          <>
            <p className="text-blue"> {"£" + (this.state.totprice + 100)}</p>
          </>
        );
      }
    }
  }
  dateCellRender1(value) {
    console.log("Coming value is", value);
    const date = new Date();
    if (
      value.format("YYYY-MM-DD") > Moment(date).format("YYYY-MM-DD") &&
      value.format("YYYY-MM-DD") < this.state.currentDate
    ) {
      if (eventArray.includes(value.format("YYYY-MM-DD"))) {
        return (
          <>
            <p className="text-blue">
              {" "}
              {"£" + (2 * this.state.totpricewithPacking + 100)}
            </p>
          </>
        );
      } else {
        return (
          <>
            <p className="text-blue">
              {" "}
              {"£" + (this.state.totpricewithPacking + 100)}
            </p>
          </>
        );
      }
    }
  }
  getMonthData(value) {
    if (value.month() === 8) {
      return 1394;
    }
  }

  monthCellRender(value) {
    const num = this.getMonthData(value);
    return num ? (
      <div className="notes-month">
        <section>{num}</section>
        <span>Backlog number</span>
      </div>
    ) : null;
  }
  monthCellRender1(value) {
    const num = this.getMonthData(value);
    return num ? (
      <div className="notes-month">
        <section>{num}</section>
        <span>Backlog number</span>
      </div>
    ) : null;
  }

  handleShow = () => this.setState({ show: true });

  onSelect = (value) => {
    const date = new Date();
    if (
      value.format("YYYY-MM-DD") > Moment(date).format("YYYY-MM-DD") &&
      value.format("YYYY-MM-DD") < this.state.currentDate
    ) {
      this.setState({
        value,
        selectedValue: value,
      });
      this.setState({ show: true, price: this.state.totprice });
      if (eventArray.includes(value.format("YYYY-MM-DD"))) {
        this.setState({
          price: this.state.totprice * 2,
        });
      }
    }
  };
  onSelect1 = (value) => {
    console.log(value);
    const date = new Date();
    if (
      value.format("YYYY-MM-DD") > Moment(date).format("YYYY-MM-DD") &&
      value.format("YYYY-MM-DD") < this.state.currentDate
    ) {
      this.setState({
        value,
        selectedValue: value,
      });
      this.setState({ show: true, price: this.state.totpricewithPacking });
      if (eventArray.includes(value.format("YYYY-MM-DD"))) {
        this.setState({
          price: this.state.totpricewithPacking * 2,
        });
      }
    }
  };

  onPanelChange = (value) => {
    this.setState({ value });
  };

  submit(e) {
    console.log("Email Found", e);
    const cat = this.props.location.state.category;
    if (cat === "Cars and Vehicles") {
      fetch("/submitVehicles", {
        method: "post",
        headers: {
          "content-Type": "application/json",
        },
        body: JSON.stringify({
          category: this.props.location.state.category,
          pickupLoc: this.props.location.state.pickupLoc,
          dropoffLoc: this.props.location.state.dropoffLoc,
          lat1: this.props.location.state.lat1,
          lng1: this.props.location.state.lng1,
          lat2: this.props.location.state.lat2,
          lng2: this.props.location.state.lng2,
          brand: this.props.location.state.brand,
          model: this.props.location.state.model,
          email: e.email,
          date: this.state.selectedValue,
          price: this.state.price + 100,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            loading: false,
          });
          console.log(data);

          // this.props.history.push("/")
          this.setState({
            loading: false,
            show: false,
            quotation: true,
          });
          //   toast.success("Please check your email",{
          //    draggable: true,
          //    hideProgressBar:true,
          //    transition: Zoom,
          //    autoClose:7000,
          //    position: toast.POSITION.TOP_CENTER
          //  })
        })
        .catch((err) => {
          this.setState({
            loading: false,
          });
          console.log(err);
          toast.error(err.err, {
            draggable: true,
            hideProgressBar: true,
            transition: Zoom,
            autoClose: 4000,
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } else if (cat === "Motorbikes") {
      console.log("Hello");
      fetch("/submitbikes", {
        method: "post",
        headers: {
          "content-Type": "application/json",
        },
        body: JSON.stringify({
          category: this.props.location.state.category,
          pickupLoc: this.props.location.state.pickupLoc,
          dropoffLoc: this.props.location.state.dropoffLoc,
          lat1: this.props.location.state.lat1,
          lng1: this.props.location.state.lng1,
          lat2: this.props.location.state.lat2,
          lng2: this.props.location.state.lng2,
          items: this.props.location.state.items,
          email: e.email,
          date: this.state.selectedValue,
          price: this.state.price + 100,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            loading: false,
            show: false,
          });
          console.log(data);

          // this.props.history.push("/")
          this.setState({
            loading: false,
            show: false,
            quotation: true,
          });
          //   toast.success("Please check your email",{
          //    draggable: true,
          //    hideProgressBar:true,
          //    transition: Zoom,
          //    autoClose:7000,
          //    position: toast.POSITION.TOP_CENTER
          //  })
        })
        .catch((err) => {
          this.setState({
            loading: false,
          });
          console.log("Error yahan se aya ", err);
          toast.error(err.err, {
            draggable: true,
            hideProgressBar: true,
            transition: Zoom,
            autoClose: 4000,
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } else if (
      cat === "Parcels and Packages" ||
      cat === "Specialist and Antiques" ||
      cat === "Vehicle Parts" ||
      cat === "Office Removals"
    ) {
      console.log("We are on parcels");
      fetch("/submitparcel", {
        method: "post",
        headers: {
          "content-Type": "application/json",
        },
        body: JSON.stringify({
          category: this.props.location.state.category,
          pickupLoc: this.props.location.state.pickupLoc,
          dropoffLoc: this.props.location.state.dropoffLoc,
          lat1: this.props.location.state.lat1,
          lng1: this.props.location.state.lng1,
          lat2: this.props.location.state.lat2,
          lng2: this.props.location.state.lng2,
          items: this.props.location.state.items,
          email: e.email,
          date: this.state.selectedValue,
          price: this.state.price + 100,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            loading: false,
            show: false,
            quotation: true,
          });
          console.log(data);
          // this.props.history.push("/")
          //   toast.success("Please check your email",{
          //    draggable: true,
          //    hideProgressBar:true,
          //    transition: Zoom,
          //    autoClose:7000,
          //    position: toast.POSITION.TOP_CENTER
          //  })
        })
        .catch((err) => {
          this.setState({
            loading: false,
          });
          console.log(err);
          toast.error(err.err, {
            draggable: true,
            hideProgressBar: true,
            transition: Zoom,
            autoClose: 4000,
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } else if (cat === "Home Removals") {
      fetch("/submitHomeRemoval", {
        method: "post",
        headers: {
          "content-Type": "application/json",
        },
        body: JSON.stringify({
          category: this.props.location.state.category,
          pickupLoc: this.props.location.state.pickupLoc,
          dropoffLoc: this.props.location.state.dropoffLoc,
          pickupfloor: this.props.location.state.pickupFloor,
          dropofffloor: this.props.location.state.dropoffFloor,
          lat1: this.props.location.state.lat1,
          lng1: this.props.location.state.lng1,
          lat2: this.props.location.state.lat2,
          lng2: this.props.location.state.lng2,
          otherItem: this.props.location.state.otherItem,
          bedroom: this.props.location.state.bedroom,
          man: this.props.location.state.man,
          garden:this.props.location.state.garden,
          office:this.props.location.state.office,
          dining:this.props.location.state.dining,
          box:this.props.location.state.box,
          kitchen: this.props.location.state.kitchen,
          tvlounge: this.props.location.state.tvlounge,
          bathroom: this.props.location.state.bathroom,
          email: e.email,
          date: this.state.selectedValue,
          price: this.state.price + 100,

          lat1: this.props.location.state.lat1,
          lng1: this.props.location.state.lng1,
          lat2: this.props.location.state.lat2,
          lng2: this.props.location.state.lng2,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            loading: false,
          });
          console.log(data);
          // this.props.history.push("/")
          this.setState({ quotation: true });
          this.setState({
            loading: false,
            show: false,
          });

          //   toast.success("Please check your email",{
          //    draggable: true,
          //    hideProgressBar:true,
          //    transition: Zoom,
          //    autoClose:7000,
          //    position: toast.POSITION.TOP_CENTER
          //  })
        })
        .catch((err) => {
          this.setState({
            loading: false,
          });
          console.log(err);
          toast.error(err.err, {
            draggable: true,
            hideProgressBar: true,
            transition: Zoom,
            autoClose: 4000,
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } else {
      fetch("/submitFurniture", {
        method: "post",
        headers: {
          "content-Type": "application/json",
        },
        body: JSON.stringify({
          category: this.props.location.state.category,
          pickupLoc: this.props.location.state.pickupLoc,
          dropoffLoc: this.props.location.state.dropoffLoc,
          pickupfloor: this.props.location.state.pickupFloor,
          dropofffloor: this.props.location.state.dropoffFloor,
          lat1: this.props.location.state.lat1,
          lng1: this.props.location.state.lng1,
          lat2: this.props.location.state.lat2,
          lng2: this.props.location.state.lng2,
          items: this.props.location.state.items,
          otherItem: this.props.location.state.otherItem,
          email: e.email,
          date: this.state.selectedValue,
          price: this.state.price + 100,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            loading: false,
            show: false,
            quotation: true,
          });
          console.log(data);

          //this.props.history.push("/")
          //   toast.success("Please check your email",{
          //    draggable: true,
          //    hideProgressBar:true,
          //    transition: Zoom,
          //    autoClose:7000,
          //    position: toast.POSITION.TOP_CENTER
          //  })
        })
        .catch((err) => {
          this.setState({
            loading: false,
          });
          console.log(err);
          toast.error(err.err, {
            draggable: true,
            hideProgressBar: true,
            transition: Zoom,
            autoClose: 4000,
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  }

  render() {
    const { value, selectedValue } = this.state;
    const validateMessages = {
      required: "Email is required!",
      types: {
        email: "Email is not valid!",
      },
    };

    return (
      <>
        <Modal
          backdrop="static"
          show={this.state.show}
          onHide={() => this.setState({ show: false })}
          dialogClassName="modal-90w"
        >
          <Modal.Header className="bg-orange text-white" closeButton>
            <Modal.Title>
              Please Enter Your Email to Place Your Order
            </Modal.Title>
          </Modal.Header>
          <Modal.Body closeButton id="example-modal-sizes-title-lg">
            <div className="pr-3 pl-3 pb-3">
              {/* <div>{this.state.totprice}</div>
              <div>{this.state.totpricewithPacking}</div> */}
              <Alert
                message={`You selected date: ${
                  selectedValue && selectedValue.format("YYYY-MM-DD")
                }`}
              />

              <Form
                className="requestQuoteForm"
                onFinish={this.submit}
                validateMessages={validateMessages}
              >
                <Row className="mt-3">
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    {/* <Form.Item  name="email" rules={[{ required: true, message: 'Please input your email' }]}>
                                <Input required value={this.state.email} onChange={(text)=>this.setState({email:text.target.value})} placeholder="Enter your email address"/>
                            </Form.Item> */}
                    <h2 className="text-orange"> {this.state.price + 100}</h2>
                    <Form.Item name="email" rules={[{ type: "email" }]}>
                      <Input placeholder="Please input your email address" />
                    </Form.Item>
                    <h5 style={{color:"#00acdd" ,fontWeight:"500"}}>For more better prices please call us : 0800 6358888</h5>
                  </Col>
                  <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Button
                      htmlType="submit"
                      size="large"
                      className="login-btn"
                      onClick={this.handleClick}
                    >
                      Submit Now
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
        <Container fluid className="section1 pb-4">
          <Container className="pt-4 pb-4">
            <Row>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="tab-title"
              >
                <br />
                <br />
                <br />
                <h5 style={{color:"#00acdd" ,fontWeight:"700"}}>For more better prices please call us : 0800 6358888</h5>
                <Tabs defaultActiveKey="1">
                  <TabPane
                    key="1"
                    tab={
                      <span
                        className="tab-icons font-weight-bold"
                        onClick={() =>
                          this.setState({ price: this.state.totPrice + 100 })
                        }
                      >
                        Fixed Price without Packing £{" "}
                        {this.state.totprice + 100}
                      </span>
                    
                    }
                  >
                    <Row className="p-2 custom-calendar">
                      <Col>
                        <Calendar
                          value={value}
                          onSelect={this.onSelect}
                          dateCellRender={this.dateCellRender}
                          monthCellRender={this.monthCellRender}
                          disabledDate={(current) => {
                            return (
                              current && current < moment().add(0, "month")
                            );
                          }}
                        />
                      </Col>
                    </Row>
                  </TabPane>
                 
                  <TabPane
                    key="2"
                    tab={
                      <span
                        className="tab-icons font-weight-bold"
                        onClick={() =>
                          this.setState({
                            price: this.state.totalPricewithPacking + 100,
                          })
                        }
                      >
                        Fixed Price with Packing £{" "}
                        {this.state.totpricewithPacking + 100}
                      </span>
                    }
                  >
                  
                    <Row className="p-2 custom-calendar">
                      <Col>
                      
                        <Calendar
                          value={value}
                          onSelect={this.onSelect1}
                          dateCellRender={this.dateCellRender1}
                          monthCellRender={this.monthCellRender1}
                          disabledDate={(current) => {
                            return (
                              current && current < moment().add(0, "month")
                            );
                          }}
                        />
                      </Col>
                    </Row>
                  </TabPane>
                 
                </Tabs>
                
              </Col>
            </Row>
          </Container>
        </Container>
      </>
    );
  }
}
export default EmailPrices;

function sendEmail() {
  return new Promise((resolve, reject) => {
    // Simulate email sending delay with setTimeout
    setTimeout(() => {
      // Assume email sending succeeds
      resolve();
    }, 2000);
  });
}
