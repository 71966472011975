import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button'
import 'react-toastify/dist/ReactToastify.css';
import Table from 'react-bootstrap/Table'
import AOS from 'aos';
//import FurnitureData from '../../json/furnitureData';
import Form from 'react-bootstrap/Form'
import {FaRegEdit} from 'react-icons/fa';
import { toast, Zoom, Bounce} from 'react-toastify'

toast.configure()
class VehicleModels extends React.Component {
  
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    const i=0;
  }
    state={
      data:[],
      prices:[]
      
  }
  
  componentDidMount(){
    
    console.log(this.props.location.state.item.name)
    
    fetch("/getCar",{
      method:"post",
      headers:{
        "content-Type":"application/json"
      },
      body:JSON.stringify({
        name:this.props.location.state.item.name
      })
    }).then(res=>res.json())
    .then((data)=>{
       
        this.setState({
          data:data
        })
        data.map(item=>{
          const arr = [...this.state.prices]
          arr.push(item.price)
          this.setState({prices:arr})
          console.log(this.state.prices)
        })
        console.log(this.props.location.state.item.name)
    })
  }
  submit(){
    const data = [...this.state.data]
    data.map((item,index)=>{
      data[index].price = this.state.prices[index]    
    })
    console.log(data)
    this.setState({
      data:data
    })
    fetch("/updateCar",{
      method:"post",
      headers:{
        "content-Type":"application/json"
      },
      body:JSON.stringify({
        name:this.props.location.state.item.name,
        data:this.state.data
      })
    }).then(res=>res.json())
    .then((data)=>{
      console.log(data)
      toast.success("Updated Successfully",{
        draggable: true,
        hideProgressBar:true,
        transition: Zoom,
        autoClose:7000,
        position: toast.POSITION.TOP_CENTER  
      })
    })
  }

  updatePrice =(val,index) =>{
    console.log(val.target.value)
    const arr = [...this.state.prices]
    arr[index] = val.target.value
    this.setState({
      prices:arr
    })
  }
    render ()
      { 
        const details =  this.state.data.map((item,index)=>{
          
          return(
              
              <>
                <tr class="d-flex" key={item._id} >
                  <td class="col-1">{index+1}</td>
                  <td class="col-3">{item.brand}</td>
                  <td class="col-4">{item.model}</td> 
                  <td class="col-4">
                    <Form.Control size="sm" type="text" name={item.model} onChange={(val)=>this.updatePrice(val,index)} value={this.state.prices[index]} />
                  </td>
                </tr>
                
              </>
          )
          })

        return(
          <>
          <Row className='admin-forms'>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <h1 className='page-title'>Cars and Vehicles</h1>
              <hr/> 
            </Col>
            <Col className='pt-3' >
            <p className='float-right mt-3 mb-2 text-orange pointer add-new-post-link' onClick={() =>this.props.history.push('/add-vehicle-model',{brand:this.props.location.state.item.name})}><FaRegEdit/>Add New Model</p>
                <Table hover size="sm" className='text-left' className="table admin-table" data-aos="fade-up" data-aos-duration="2000">
                  <thead className='bg-orange text-white'>
                    <tr class="d-flex">
                      <th class="col-1">Sr. No</th>
                     
                      <th class="col-3">Sub Category</th>
                      <th class="col-4">Item</th>
                      <th class="col-4">Price</th>
                    </tr>
                  </thead>      
                
                <tbody>
                    {details}
                </tbody>
              </Table> 
            </Col>
            <Col xs={12} >
              <br/>
              <Button className="publish-btn" onClick={this.submit} >Update</Button>
            </Col>
          </Row>
        
        </>
        )
        
     
      
      }

}

export default VehicleModels;
