import React from 'react';
import {Row,Col,Container} from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'
import img from '../img/caroline2.png'
import Image from 'react-bootstrap/Image'
import {RiDeleteBack2Line} from 'react-icons/ri';
import GoogleApiWrapper from './googlemap/googleMaps'
import PickupLocation from './googlemap/pickupLocation';
import DropoffLocation from './googlemap/dropoffLocation';

class VehicleParts extends React.Component
{
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
      } 
      state={
            customFields:[{item:'',width:'',depth:'',height:'',lengthIn:'',
                        quantity:'',weight:'',weightIn:'',sqPrice:'',kgPrice:''}],
            
            instructions:'',
            mileage:"",
            distanceCost:0,
            itemsDropDrown:[]
      }
      componentDidMount()
      {
        fetch('/getDistanceCost')
        .then(res=>res.json())
        .then((data)=>{
            this.setState({
                distanceCost:data.cost
            })
            console.log("Distance Data is ",data.cost)
        })

        fetch('/getVehicleParts')
        .then(res=>res.json())
        .then((dat)=>{
            this.setState({
                itemsDropDrown:dat
            }) 
          
        })
      }
    submit()
    {
      
      if(this.state.customFields.length>0)
        {
          this.props.history.push('/email-prices',
            {
               category:this.props.location.state.category,
               pickupLoc:this.props.location.state.pickupLoc, 
               dropoffLoc:this.props.location.state.dropoffLoc,
               lat1:this.props.location.state.lat1,
               lng1:this.props.location.state.lng1,
               lat2:this.props.location.state.lat2,
               lng2:this.props.location.state.lng2,
               items:this.state.customFields,
               mileage:this.state.mileage,
               distanceCost:this.state.distanceCost,
            })
        }
    }
    // addCustomField = ()=>{
    //     this.setState({ingredients:[... this.state.ingredients, ""]});
    // }
    // customNameHandler = (text,index)=>{
    //     this.state.ingredients[index]= text
    // }
    handleChangeInput(i,event)
    {
        if(event.target.value==="Parcel")
        {
            console.log(this.state.itemsDropDrown[0].pricePerSqIn)
            console.log(this.state.itemsDropDrown[0].pricePerKg)
            const values = [...this.state.customFields];
            values[i]["sqPrice"] = this.state.itemsDropDrown[0].pricePerSqIn;
            values[i]["kgPrice"] = this.state.itemsDropDrown[0].pricePerKg;
            this.setState({customFields:values});
        }
        else if(event.target.value==="Box")
        {
            console.log(this.state.itemsDropDrown[1].pricePerSqIn)
            console.log(this.state.itemsDropDrown[1].pricePerKg)
            const values = [...this.state.customFields];
            values[i]["sqPrice"] = this.state.itemsDropDrown[1].pricePerSqIn;
            values[i]["kgPrice"] = this.state.itemsDropDrown[1].pricePerKg;
            this.setState({customFields:values});
        }
        else if(event.target.value==="Appliance")
        {
            console.log(this.state.itemsDropDrown[2].pricePerSqIn)
            console.log(this.state.itemsDropDrown[2].pricePerKg)
            const values = [...this.state.customFields];
            values[i]["sqPrice"] = this.state.itemsDropDrown[2].pricePerSqIn;
            values[i]["kgPrice"] = this.state.itemsDropDrown[2].pricePerKg;
            this.setState({customFields:values});
        }
        else if(event.target.value==="Packaged Item")
        {
            console.log(this.state.itemsDropDrown[3].pricePerSqIn)
            console.log(this.state.itemsDropDrown[3].pricePerKg)
            const values = [...this.state.customFields];
            values[i]["sqPrice"] = this.state.itemsDropDrown[3].pricePerSqIn;
            values[i]["kgPrice"] = this.state.itemsDropDrown[3].pricePerKg;
            this.setState({customFields:values});
        }
        else if(event.target.value==="Other")
        {
            console.log(this.state.itemsDropDrown[4].pricePerSqIn)
            console.log(this.state.itemsDropDrown[4].pricePerKg)
            const values = [...this.state.customFields];
            values[i]["sqPrice"] = this.state.itemsDropDrown[4].pricePerSqIn;
            values[i]["kgPrice"] = this.state.itemsDropDrown[4].pricePerKg;
            this.setState({customFields:values});
        }
        const values = [...this.state.customFields];
        const {name,value}=event.target;
        values[i][name] = value;
       this.setState({customFields:values});
        console.log(this.state.customFields);
        
    } 
    handleRemoveClick=()=> {
        const list = [...this.state.customFields];
        list.splice(-1, 1);
        this.setState({customFields:list});
      };
     handleAddInput=()=>{
        this.setState({customFields:[... this.state.customFields,
            { item:'',width:'',depth:'',height:'',lengthIn:'',
            quantity:'',weight:'',weightIn:'' }]});
      }
      handleMile= (val) =>{
        const miles = val.replace("mi", "");
        this.setState({ 
            mileage:miles + " miles"
        })
        console.log("Value from Furni", val)
    }
render(){
    return(
        <>
        <Container fluid className="homepage-pattern">
        <Container className="pb-5 pt-5">
        <br/>
        <Row className="mt-5">
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <h2 className="title1">{this.props.location.state.category}</h2>
                <br/>
            </Col>
            <Col className="shadow-sm bg-white" xs={12} sm={12} md={8} lg={8} xl={8}>
               <Form  className="pt-5 pb-5 pr-3 pl-3" onSubmit={this.submit} >
                            <h6 className="text-orange font-weight-bold mt-2">Please enter details of your item to move</h6>
                    {
                        this.state.customFields.map((customInput,key)=>{
                            return(
                                <>
                                <br/>
                                <Form.Row >
                                    <Col xs={10}>
                                    
                                    <Form.Label>Please Select an Item
                                    

                                    </Form.Label>
                                            <Form.Control as="select" name="item" size="md" custom
                                            onChange={(e)=>this.handleChangeInput(key,e)} required>
                                                <option selected disabled value="">Choose...</option>
                                                {
                                                     this.state.itemsDropDrown.map(x=>
                                                        <option value={x.name}>{x.name}</option>
                                                        )
                                                }
                                                {/* <option value="not selected">select...</option>
                                                    <option value="parcel">Parcel</option>
                                                    <option value="box">Box</option>
                                                    <option value="appliance">Appliance</option>
                                                    <option value="packaged item">Packaged item</option>
                                                    <option value="other">Other</option> */}
                                            </Form.Control>
                                    </Col>
                                    <Col xs={2}>
                                        <Form.Label>Quantity</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="quantity"
                                                value={customInput.quantity}
                                                onChange={(e)=>this.handleChangeInput(key,e)} required>
                                            </Form.Control>
                                    </Col>
                                    
                                </Form.Row>
                                <Form.Row className="mt-3">
                                    <Col xs={2}>
                                        <Form.Label>Width</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="width"
                                                value={customInput.width}
                                                onChange={(e)=>this.handleChangeInput(key,e)} required>
                                            </Form.Control>
                                    </Col>
                                    <Col xs={2}>
                                    <Form.Label>Height</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="height"
                                            value={customInput.height}
                                            onChange={(e)=>this.handleChangeInput(key,e)} required>
                                        </Form.Control>
                                    </Col>
                                    <Col xs={2}>
                                    <Form.Label>Depth</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="depth"
                                            value={customInput.depth}
                                            onChange={(e)=>this.handleChangeInput(key,e)} required>
                                        </Form.Control>
                                    </Col>

                                    <Col xs={2}>
                                    <Form.Label>Length In</Form.Label>
                                            <Form.Control as="select" name="lengthIn" size="md" custom
                                            onChange={(e)=>this.handleChangeInput(key,e)} required>
                                                <option selected disabled value="">Choose...</option>
                                                <option value="cm">cm</option>
                                                <option value="m">m</option>
                                                <option  value="in">in</option>
                                                <option  value="ft">ft</option>
                                                <option  value="mm">mm</option>
                                            </Form.Control>
                                    </Col>
                                   
                                   
                                    <Col xs={2}>
                                    <Form.Label>Weight</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="weight"
                                            value={customInput.weight}
                                            required
                                            onChange={(e)=>this.handleChangeInput(key,e)}>
                                        </Form.Control>
                                    </Col>
                                    <Col xs={2}>
                                    <Form.Label>Weight In</Form.Label>
                                            <Form.Control as="select" 
                                            name="weightIn" size="md" custom
                                            onChange={(e)=>this.handleChangeInput(key,e)} required>
                                                <option selected disabled value="">Choose...</option>
                                                <option value="kg">KG</option>
                                                <option value="lbs">LBS</option>
                                            </Form.Control>
                                    </Col>
                                    
                                    </Form.Row>
                                    <Row className='p-4' >
                                        <Col className='bg-alternate-tabs'> </Col>
                                    </Row>
                                    
                                            
                                        
                                 
                                    </>
                            )})}
                            
                            <Form.Row>
                                <Col xs={12} > 
                            
                                    <Form.Label classame="mt-1">Any Instructions</Form.Label>
                                    <Form.Control as="textarea" name="instructions" rows="3" 
                                    onChange={(e)=>this.setState({instructions:e.target.value})}
                                    />
                                </Col>
                            </Form.Row>
                            <Row>
                            <Col xs={12} >
                            <br/>
                                <Button className="mr-1 add-btn" onClick={this.handleAddInput}>Add Item</Button>
                                <Button className="remove-btn" onClick={this.handleRemoveClick}>Delete Item</Button>

                                </Col>
                            </Row>
                                
                       
                           <Form.Row>
                           
                           <Col> <br/>
                           
                           <Button type="submit" size="large" className="float-right driver-reg-btn" >Next</Button>
                           <br/>
                           </Col>
                           </Form.Row>
                            
                                
                  
                </Form>
                <div
                style={{ marginTop: "10px" }}
                className="p-2 text-center shadow-sm bg-orange"
              >
                {this.state.mileage && (
                  <h4 className="text-light">Distance: {this.state.mileage}</h4>
                )}
              </div>
                    </Col>
            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                <GoogleApiWrapper 
                    lat1={this.props.location.state.lat1}
                    lng1={this.props.location.state.lng1}
                    lat2={this.props.location.state.lat2}
                    lng2={this.props.location.state.lng2}
                    handleMile={this.handleMile}
                 />
                   
            </Col>
            
                        
                   
                </Row>
                         
               
                    

                    
                </Container>
           
        </Container>
        </>
    ); //return end here...
    }//reder end here...
}//class end here...
export default VehicleParts;

