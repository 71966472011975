import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import categoriesDetail from './json/data';
import Image from 'react-bootstrap/Image'
import AOS from 'aos';

class Categories extends React.Component
{
    constructor(props) {
        super(props);
        AOS.init();
       
      }
    
      CallRoute(id,category)
      {
        this.props.history.push('/get-location',{id:id,category:category})
        
      }

      
        ServicesCards = categoriesDetail.map((item,index)=>{
            return(
    
                <div data-aos="flip-left"   data-aos-easing="ease-out-cubic"  data-aos-duration="2000" className='services-col d-flex justify-content-center col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3'>
                    <Card className="text-center pointer" onClick={()=>this.CallRoute(item.id,item.category)} >
                        <div className="services-card-content justify-content-center">
                            <Image className="services-card-bg-image" src={require(`../img/b${index+1}.jpg`)} width='100%' height="100%"/>
                            <div className="services-card-bg-opacity"></div>
                            <div className="card-services">
                                <p>{item.image}</p>
                                <Card.Title className='text-white'>{item.category}</Card.Title>
                                <Card.Text className='text-light'>{item.description}</Card.Text>
                            </div>
                        </div>
                    </Card>
                </div>
            )})
    render(){
        return(
            <>
                <Container className='pt-5 pb-5 mt-5'>
        <Row > 
                        <Col xs={12}>
                    <h2 className="title1">Our Services</h2>
                    <br/>
                    <h2 className="title3">At GetMovers, our goal is to make moving as cheap and hassle-free as it possibly could be. With us, you get:
</h2>
                    <br/>
                    
                    </Col>
                    {this.ServicesCards}
                </Row>
        </Container>
            
            </>
        )}
}
export default Categories;
