import React, {useState, useEffect} from 'react'
import {useParams, useHistory} from 'react-router-dom'
import {Container,Row,Col,Table} from 'react-bootstrap';
import { toast, Zoom, Bounce} from 'react-toastify'
import { BarLoader, BeatLoader} from 'react-spinners'
import GoogleApiWrapper from './googlemap/googleMaps'
import { Tabs, Button } from 'antd';
import 'antd/dist/antd.css'
import {FaBath,FaChair } from 'react-icons/fa';
import {GrLounge} from 'react-icons/gr';
import {IoIosBed} from 'react-icons/io';
import {MdKitchen} from 'react-icons/md';
// import {CiForkAndKnife}  from "react-icons/ci"
import {AiOutlineShoppingCart ,AiOutlineCodeSandbox} from "react-icons/ai"
import { Badge, Card } from 'antd';
import AOS from 'aos';
import { DatePicker, Space } from 'antd';

toast.configure()
const SurveyPerforma = (props) => {
    const { TabPane } = Tabs;
    const history = useHistory();
    const {token} = useParams();
    const [data, setData] =useState([])
    const [items, setItems] =useState([])
    const [bedroom, setBedroom] =useState([])
    const [man ,setMan]=useState([])
    const [tvlounge, setTvlounge] =useState([])
    const [bathroom, setBathroom] =useState([])
    const [kitchen, setKitchen] =useState([])
    const [dining , setDining]=useState([])
    const [office , setOffice]=useState([])
    const [garden , setGarden]=useState([])
    const [box , setBox]=useState([])
    const [loading, setLoading] =useState(true)
    const [mapBool,setMapBool] =useState(false)
    const [date,setDate] = useState('')
    const [confirm,setConfirm] = useState(false)
    const [price,setPrice] = useState('')

    function onChange(date, dateString) {
      console.log(date, dateString);
      setDate(date)
    }

    useEffect(()=>{
        
        console.log("Data is", token)
        fetch("/getSurveyPerforma",{
            method:"post",
            headers:{
              "content-Type":"application/json"
            },
            body:JSON.stringify({
            token,
            })
          })
          .then(res=>res.json())
          .then((data)=>{
            console.log(data)
            if(data==="confirm")
            {
              setLoading(false)
              setConfirm(true)
            }
            else
            {
            if(data.category === 'Furniture and Other Items' || data.category === 'Pianos' ||
              data.category ==='Parcels and Packages' || data.category ==='Specialist and Antiques' || 
              data.category ==='Vehicle Parts' || data.category === 'Office Removals')
              {
                setData(data)
                setItems(data.items)
                setLoading(false)
              }
            
             else if(data.category === 'Cars and Vehicles' || data.category === 'Motorbikes')
              {
                setData(data)
                setLoading(false)
              }
            else if(data.category === 'Home Removals')
              {
                setData(data)
                setBedroom(data.bedroom)
                setMan(data.man)
                setBathroom(data.bathroom)
                setTvlounge(data.tvlounge)
                setKitchen(data.kitchen)
                setDining(data.dining)
                setOffice(data.office)
                setGarden(data.garden)
                setBox(data.box)
                setLoading(false)
              }
              fetch("/getSurveyprice",{
                method:"post",
                headers:{
                  "content-Type":"application/json"
                },
                body:JSON.stringify({
                id:data._id,
                })
              })
              .then(res=>res.json())
              .then((data)=>{
                console.log(data.price)
                  setPrice(data.price)
              })
              setMapBool(true)
                
          }
        }
          )
    },[])
    
    const postData = () => {
        console.log("Date selected")
        setLoading(true)
        fetch("/confirmRequest",{
          method:"post",
          headers:{
            "content-Type":"application/json"
          },
          body:JSON.stringify({
          id:data._id,
          //date
          })
        })
        .then(res=>res.json())
        .then((data)=>{
          console.log(data)
          setLoading(false)
          history.push('/')
          toast.success("Request confirmed",{
            draggable: true,
            hideProgressBar:true,
            transition: Zoom,
            autoClose:7000,
            position: toast.POSITION.TOP_CENTER  
          })
        })
    }

    const confirmation = () =>{
      if(confirm===false)
      {
        return(
          <div>
            {/* <div className='request-title pt-3' data-aos="fade-up" data-aos-duration="2000">
              <h5><span className='font-weight-bold text-blue'>SELECT DATE OF REMOVAL: </span></h5>
              <DatePicker onChange={onChange} />
            </div> */}
            <div className='request-title pt-3' data-aos="fade-up" data-aos-duration="1000">
              <Button style={{marginLeft:'40%'}} onClick={()=>postData()} size="large" className="driver-reg-btn" >CONFIRM MY REQUEST</Button>
            </div>
          </div>
        )
      }
      else
      {
        return(
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <h1 className='page-title'>YOUR REQUEST ALREADY CONFIRMED</h1>
            <hr/>
          </Col>
        )
      }
    }

    const Furniture=()=>{
      return( 
          <>
              <Row className='admin-forms text-6e6e6e'>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <h1 className='page-title'>YOUR REQUEST DETAILS</h1>
                      <hr/>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={6} xl={6} className='request-title pt-3' data-aos="fade-up" data-aos-duration="2000">
                      <h5><span className='font-weight-bold text-orange'>Pickup Location: </span></h5>
                      <p>{data.pickupLoc}</p>
                      <h5><span className='font-weight-bold text-orange'>Dropoff Location: </span></h5>
                      <p>{data.dropoffLoc}</p>
                      <Row>
                          <Col>
                              <h5><span className='font-weight-bold text-orange'>Pickup Floor: </span></h5>
                              <p>{data.pickupfloor}</p>
                          </Col>
                          <Col>
                          <h5><span className='font-weight-bold text-orange'>Drop Floor: </span></h5>
                          <p>{data.dropofffloor}</p>
                          </Col>
                      </Row> 
                      <h5><span className='font-weight-bold text-orange'>Email: </span></h5>
                      <p>{data.email}</p>
                      <h5><span className='font-weight-bold text-orange'>Request Date: </span></h5>
                      <p>{data.date}</p>
                      <h5><span className='font-weight-bold text-orange'>Category:</span></h5>
                      <p>{data.category}</p>
                      <h5><span className='font-weight-bold text-orange'>Price:</span></h5>
                      <p>{data.price}</p>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={6} xl={6} className='p-3' >
                      <div className='ok2 float-right' >
                          <GoogleApiWrapper
                           lat1={parseFloat(data.lat1)}
                           lng1={parseFloat(data.lng1)}
                           lat2={parseFloat(data.lat2)}
                           lng2={parseFloat(data.lng2)}
                           handleMile={handleMile}
                           />
                      </div>
                  </Col>
              </Row>
              <Row>
                  <Col className='pt-3'>
                      <Table hover size="sm" className='text-left table admin-table'>
                          <thead className='bg-orange text-white'>
                              <tr class="d-flex">
                                  <th class="col-1">Sr. No</th>
                                  <th class="col-9">Item Description</th>
                                  <th class="col-2">Quantity</th>
                              </tr>
                          </thead>      
                          <tbody>
                          {
                              items.map((item,index)=>{
                              return(
                                  <tr class="d-flex" key={item.item}>
                                    <td class="col-1">{index+1}</td>
                                    <td class="col-9">{item.item}</td>
                                    <td class="col-2">{item.qty}</td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                      </Table> 
                  </Col>
              </Row>
          </>
      )//furniture return ends here...
  }//furniture function ends here...

  const Vehicles=()=>{
    return(
        <>
            <Row className='admin-forms text-6e6e6e'>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <h1 className='page-title'>YOUR REQUEST DETAILS</h1>
                    <hr/>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={6} className='request-title pt-3' data-aos="fade-up" data-aos-duration="2000">
                    <h5><span className='font-weight-bold text-orange'>Pickup Location: </span></h5>
                    <p>{data.pickupLoc}</p>
                    <h5><span className='font-weight-bold text-orange'>Dropoff Location: </span></h5>
                    <p>{data.dropoffLoc}</p>
                    <Row>
                        <Col xs={6} className='request-title'>
                            <h5><span className='font-weight-bold text-orange'>Vehicle Brand: </span></h5>
                            <p>{data.brand}</p>
                        </Col>
                        <Col xs={6} className='request-title'>
                            <h5><span className='font-weight-bold text-orange'>Vehicle Model: </span></h5>
                            <p>{data.model}</p>
                        </Col>
                    </Row> 
                    <h5><span className='font-weight-bold text-orange'>Email: </span></h5>
                    <p>{data.email}</p>
                    <h5><span className='font-weight-bold text-orange'>Removal Date: </span></h5>
                    <p>{data.date}</p>
                    <h5><span className='font-weight-bold text-orange'>Category:</span></h5>
                    <p>{data.category}</p>
                    <h5><span className='font-weight-bold text-orange'>Price:</span></h5>
                      <p>{price}</p>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={6} className='p-3'>
                    <div className='ok2 float-right' >
                      <GoogleApiWrapper
                           lat1={parseFloat(data.lat1)}
                           lng1={parseFloat(data.lng1)}
                           lat2={parseFloat(data.lat2)}
                           lng2={parseFloat(data.lng2)}
                           handleMile={handleMile}
                           />
                    </div>
                </Col>
            </Row>
        </>
    )//vehicle return ends here...
}//vehicle function ends here...

const Pianos=()=>{
  return(
      <>
          <Row className='admin-forms text-6e6e6e'>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <h1 className='page-title'>YOUR REQUEST DETAILS</h1>
                  <hr/>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6} className='request-title pt-3' data-aos="fade-up" data-aos-duration="2000">
                  <h5><span className='font-weight-bold text-orange'>Pickup Location: </span></h5>
                  <p>{data.pickupLoc}</p>
                  <h5><span className='font-weight-bold text-orange'>Dropoff Location: </span></h5>
                  <p>{data.dropoffLoc}</p>
                  <h5><span className='font-weight-bold text-orange'>Pickup Floor: </span></h5>
                  <p>{data.pickupfloor}</p>
                  <h5><span className='font-weight-bold text-orange'>Drop Floor: </span></h5>
                  <p>{data.dropofffloor}</p>
                  <h5><span className='font-weight-bold text-orange'>Email: </span></h5>
                  <p>{data.email}</p>
                  <h5><span className='font-weight-bold text-orange'>Removal Date: </span></h5>
                  <p>{data.date}</p>
                  <h5><span className='font-weight-bold text-orange'>Piano Type: </span></h5>
                  {data.items}
                  <h5><span className='font-weight-bold text-orange'>Category:</span></h5>
                  <p>{data.category}</p>
                  <h5><span className='font-weight-bold text-orange'>Price:</span></h5>
                  <p>{price}</p>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6} className='p-3'>
                  <div className='ok2 float-right' >
                        <GoogleApiWrapper
                           lat1={parseFloat(data.lat1)}
                           lng1={parseFloat(data.lng1)}
                           lat2={parseFloat(data.lat2)}
                           lng2={parseFloat(data.lng2)}
                           handleMile={handleMile}
                           />
                  </div>
              </Col>
          </Row>
      </>
  )//piano return ends here...
}//piano function ends here...
const Motorbikes=()=>{
  return(
      <>
          <Row className='admin-forms text-6e6e6e'>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <h1 className='page-title'>YOUR REQUEST DETAILS</h1>
                  <hr/>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6} className='request-title pt-3' data-aos="fade-up" data-aos-duration="2000">
                  <h5><span className='font-weight-bold text-orange'>Pickup Location: </span></h5>
                  <p>{data.pickupLoc}</p>
                  <h5><span className='font-weight-bold text-orange'>Dropoff Location: </span></h5>
                  <p>{data.dropoffLoc}</p>
                  <h5><span className='font-weight-bold text-orange'>Bike Type: </span></h5>
                  <p>{data.items}</p>
                  <h5><span className='font-weight-bold text-orange'>Email: </span></h5>
                  <p>{data.email}</p>
                  <h5><span className='font-weight-bold text-orange'>Removal Date: </span></h5>
                  <p>{data.date}</p>
                  <h5><span className='font-weight-bold text-orange'>Category:</span></h5>
                  <p>{data.category}</p>
                  <h5><span className='font-weight-bold text-orange'>Price:</span></h5>
                  <p>{price}</p>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6} className='p-3'>
                  <div className='ok2 float-right' >
                  <   GoogleApiWrapper
                           lat1={parseFloat(data.lat1)}
                           lng1={parseFloat(data.lng1)}
                           lat2={parseFloat(data.lat2)}
                           lng2={parseFloat(data.lng2)}
                           handleMile={handleMile}
                           />
                  </div>
              </Col>
          </Row>
      </>
  )//motorbike return ends here...
}//motorbike function ends here...

const ParcelsPackages=()=>{
  return(
      <>
          <Row className='admin-forms text-6e6e6e'>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h1 className='page-title'>YOUR REQUEST DETAILS</h1>
              <hr/>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6} className='request-title pt-3' data-aos="fade-up" data-aos-duration="2000">
              <h5><span className='font-weight-bold text-orange'>Pickup Location: </span></h5>
              <p>{data.pickupLoc}</p>
              <h5><span className='font-weight-bold text-orange'>Dropoff Location: </span></h5>
              <p>{data.dropoffLoc}</p>
              <h5><span className='font-weight-bold text-orange'>Email: </span></h5>
              <p>{data.email}</p>
              <h5><span className='font-weight-bold text-orange'>Removal Date: </span></h5>
              <p>{data.date}</p>
              <h5><span className='font-weight-bold text-orange'>Category:</span></h5>
              <p>{data.category}</p>
              <h5><span className='font-weight-bold text-orange'>Price:</span></h5>
              <p>{price}</p>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6} className='p-3'>
                  <div className='ok2 float-right' >
                      <GoogleApiWrapper
                              lat1={parseFloat(data.lat1)}
                              lng1={parseFloat(data.lng1)}
                              lat2={parseFloat(data.lat2)}
                              lng2={parseFloat(data.lng2)}
                              handleMile={handleMile}
                              />
                      </div>
              </Col>
          </Row>
          <Row className='p-2' >
              <Col>
                  <br/>
                  <Table hover size="sm" className='mt-3 text-left table admin-table'>
                      <thead className='bg-orange text-white'>
                          <tr class="d-flex">
                              <th class="col-1">Sr. No</th>
                              <th class="col-2">Item</th>
                              <th class="col-1">Qty</th>
                              <th class="col-1">Weight</th>
                              <th class="col-1">Width</th>
                              <th class="col-1">Height</th>
                              <th class="col-1">Depth</th>
                              <th class="col-2">Length In</th>
                              <th class="col-2">Weight In</th>
                          </tr>
                      </thead>      
                      <tbody>
                          {
                            items.map((item,index)=>{
                              return(
                                <tr class="d-flex">
                                  <td class="col-1">{index+1}</td>
                                  <td class="col-2">{item.item}</td>
                                  <td class="col-1">{item.quantity}</td>
                                  <td class="col-1">{item.weight}</td>
                                  <td class="col-1">{item.width}</td>
                                  <td class="col-1">{item.height}</td>
                                  <td class="col-1">{item.depth}</td>
                                  <td class="col-2">{item.lengthIn}</td>
                                  <td class="col-2">{item.weightIn}</td>
                                  
                                
                                </tr> 
                              )
                             })
                          }
                      </tbody>
                  </Table> 
              </Col>
          </Row>
      </>
  )//return of parcel and packages ends here... 
}//function of parcel and packages ends here... 
console.log("bedroomsdfeg:",bedroom)
const bedrooms = bedroom.map((item, index)=>{
  var itemArray;
 
  return(
  <Col xs={4} className='tile1-ribbon bg-alternate-tiles container-child'>
  <div key={index}>
    <Badge.Ribbon text={`BEDROOM No.  ${index+1}`}>
        <Card className='tile1 shadow-sm request-title'>
          <br/> 
          {
            itemArray = Object.entries(item),
            itemArray.map(item1=>{
              
              if(item1[1] !== '')
              {
              return(
                <p key={item1}>{item1[1]}</p>
              )
              }
            })
          }
        </Card>
      </Badge.Ribbon>
    
    
  </div>
  </Col>

  )
})
const mans = man.map((item, index)=>{
  var itemArray;
 
  return(
  <Col xs={4} className='tile1-ribbon bg-alternate-tiles container-child'>
  <div key={index}>
    <Badge.Ribbon text={`MAN No.  ${index+1}`}>
        <Card className='tile1 shadow-sm request-title'>
          <br/> 
          {
            itemArray = Object.entries(item),
            itemArray.map(item1=>{
              
              if(item1[1] !== '')
              {
              return(
                <p key={item1}>{item1[1]}</p>
              )
              }
            })
          }
        </Card>
      </Badge.Ribbon>
    
    
  </div>
  </Col>

  )
})
const dinings = dining.map((item, index)=>{
  var itemArray;
 
  return(
  <Col xs={4} className='tile1-ribbon bg-alternate-tiles container-child'>
  <div key={index}>
    <Badge.Ribbon text={`DINING No.  ${index+1}`}>
        <Card className='tile1 shadow-sm request-title'>
          <br/> 
          {
            itemArray = Object.entries(item),
            itemArray.map(item1=>{
              
              if(item1[1] !== '')
              {
              return(
                <p key={item1}>{item1[1]}</p>
              )
              }
            })
          }
        </Card>
      </Badge.Ribbon>
    
    
  </div>
  </Col>

  )
})
const offices = office.map((item, index)=>{
  var itemArray;
 
  return(
  <Col xs={4} className='tile1-ribbon bg-alternate-tiles container-child'>
  <div key={index}>
    <Badge.Ribbon text={`OFFICE No.  ${index+1}`}>
        <Card className='tile1 shadow-sm request-title'>
          <br/> 
          {
            itemArray = Object.entries(item),
            itemArray.map(item1=>{
              
              if(item1[1] !== '')
              {
              return(
                <p key={item1}>{item1[1]}</p>
              )
              }
            })
          }
        </Card>
      </Badge.Ribbon>
    
    
  </div>
  </Col>

  )
})
console.log("gardengarden:",garden)
const gardens = garden.map((item, index)=>{
  var itemArray;
 
  return(
  <Col xs={4} className='tile1-ribbon bg-alternate-tiles container-child'>
  <div key={index}>
    <Badge.Ribbon text={`GARDEN No.  ${index+1}`}>
        <Card className='tile1 shadow-sm request-title'>
          <br/> 
          {
            itemArray = Object.entries(item),
            itemArray.map(item1=>{
              
              if(item1[1] !== '')
              {
              return(
                <p key={item1}>{item1[1]}</p>
              )
              }
            })
          }
        </Card>
      </Badge.Ribbon>
    
    
  </div>
  </Col>

  )
})
const boxes = box.map((item, index)=>{
  var itemArray;
 
  return(
  <Col xs={4} className='tile1-ribbon bg-alternate-tiles container-child'>
  <div key={index}>
    <Badge.Ribbon text={`BOX No.  ${index+1}`}>
        <Card className='tile1 shadow-sm request-title'>
          <br/> 
          {
            itemArray = Object.entries(item),
            itemArray.map(item1=>{
              
              if(item1[1] !== '')
              {
              return(
                <p key={item1}>{item1[1]}</p>
              )
              }
            })
          }
        </Card>
      </Badge.Ribbon>
    
    
  </div>
  </Col>

  )
})

const kitchens = kitchen.map((item, index)=>{
  var itemArray;
 
  return(
    <Col xs={4} className='tile1-ribbon bg-alternate-tiles'>
  <div key={index}>
    <Badge.Ribbon text={`KITCHEN No.  ${index+1}`}>
        <Card className='tile1 shadow-sm request-title'>
          <br/> 
          {
          itemArray = Object.entries(item),
          itemArray.map(item1=>{
            console.log(item1)
            if(item1[1] !== '')
            {
            return(
              <p key={item1}>{item1[1]}</p>
            )
            }
          })
        }
        </Card>
      </Badge.Ribbon>
    
    
  </div>
  </Col>
  
  )
})

const bathrooms = bathroom.map((item, index)=>{
  var itemArray;
 
  return(
    <Col xs={4} className='tile1-ribbon bg-alternate-tiles'>
    <div key={index}>
      <Badge.Ribbon text={`BATHROOM No.  ${index+1}`}>
          <Card className='tile1 shadow-sm request-title'>
            <br/> 
            {
              itemArray = Object.entries(item),
              itemArray.map(item1=>{
                console.log(item1)
                if(item1[1] !== '')
                {
                return(
                  <p key={item1}>{item1[1]}</p>
                )
                }
              })
            }
          </Card>
        </Badge.Ribbon>
      
      
    </div>
    </Col>
  
  )
})

const tvlounges = tvlounge.map((item, index)=>{
  var itemArray;
 
  return(
    <Col xs={4} className='tile1-ribbon bg-alternate-tiles'>
    <div key={index}>
      <Badge.Ribbon text={`TVLOUNGE No.  ${index+1}`}>
          <Card className='tile1 shadow-sm request-title'>
            <br/> 
            {
              itemArray = Object.entries(item),
              itemArray.map(item1=>{
                console.log(item1)
                if(item1[1] !== '')
                {
                return(
                  <p key={item1}>{item1[1]}</p>
                )
                }
              })
            }
          </Card>
        </Badge.Ribbon>
      
      
    </div>
    </Col>
  
  )
})
const handleMile= (val) =>{
  console.log("Hello")
}

const mapDisplay = () =>{
  console.log("LatLng",data.lat1,data.lng1)
  console.log("LatLng2",data.lat2,data.lng2)
  if(mapBool === true)
  {
  return(
    <Col xs={12} sm={12} md={12} lg={6} xl={6} className='p-3' >
                  <div className='ok2 float-right' >
                  <GoogleApiWrapper
                    lat1={parseFloat(data.lat1)}
                    lng1={parseFloat(data.lng1)}
                    lat2={parseFloat(data.lat2)}
                    lng2={parseFloat(data.lng2)}
                    handleMile={handleMile}
                  />
                  </div>
              </Col>
  )
  }
}

const HomeRemovals=()=>{
  return(
      <>
          <Row className='admin-forms text-6e6e6e'>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <h1 className='page-title mb-1'>YOUR REQUEST DETAILS</h1>
                  <hr/>
              </Col>
              <Col xs={6} className='request-title pt-3' data-aos="fade-up" data-aos-duration="2000">
                  <h5><span className='font-weight-bold text-orange'>Pickup Location: </span></h5>
                  <p>{data.pickupLoc}</p>
                  <h5><span className='font-weight-bold text-orange'>Dropoff Location: </span></h5>
                  <p>{data.dropoffLoc}</p>
                  <h5><span className='font-weight-bold text-orange'>Pickup Floor: </span></h5>
                  <p>{data.pickupfloor}</p>
                  <h5><span className='font-weight-bold text-orange'>Drop Floor: </span></h5>
                  <p>{data.dropofffloor}</p>
                  <h5><span className='font-weight-bold text-orange '>Email: </span></h5>
                  <p>{data.email}</p>
                  <h5><span className='font-weight-bold text-orange'>Request Date: </span></h5>
                  <p>{data.date}</p>
                  <h5><span className='font-weight-bold text-orange'>Category:</span></h5>
                  <p>{data.category}</p>
                  <h5><span className='font-weight-bold text-orange'>Price:</span></h5>
                  <p>{price}</p>
              </Col>
              {mapDisplay()}
          </Row>
          <Row>
              <Col>
                  <br/>
                  <Tabs defaultActiveKey="9">
                  <TabPane key="9"  tab={ <span className="tab-icons font-weight-bold"> <IoIosBed/> MANS</span>  } >
                          <Row className='mt-3 p-2' data-aos="fade-up" data-aos-duration="2000">
                              {mans}
                          </Row>
                      </TabPane>
                      <TabPane key="1"  tab={ <span className="tab-icons font-weight-bold"> <IoIosBed/> BEDROOM</span>  } >
                          <Row className='mt-3 p-2' data-aos="fade-up" data-aos-duration="2000">
                              {bedrooms}
                          </Row>
                      </TabPane>
                      <TabPane key="2"  tab={ <span  className="tab-icons font-weight-bold"> <MdKitchen/> KITCHEN  </span>  } >
                          <Row className='mt-3 p-2' data-aos="fade-up" data-aos-duration="2000">
                              { kitchens}
                          </Row>
                      </TabPane>
                      <TabPane key="3" tab={ <span className="tab-icons font-weight-bold"><GrLounge/> TV LOUNGE</span>  } >
                          <Row className='mt-3 p-2' data-aos="fade-up" data-aos-duration="2000">
                              {tvlounges}
                          </Row>
                      </TabPane>
                      <TabPane key="4" tab={ <span className="tab-icons font-weight-bold"><FaBath/> BATHROOM</span>  } >
                          <Row className='mt-3 p-2' data-aos="fade-up" data-aos-duration="2000">
                              {bathrooms}
                          </Row>
                      </TabPane>
                      <TabPane key="5" tab={ <span className="tab-icons font-weight-bold"><AiOutlineShoppingCart/> GARDEN</span>  } >
                          <Row className='mt-3 p-2' data-aos="fade-up" data-aos-duration="2000">
                              {gardens}
                          </Row>
                      </TabPane>
                      <TabPane key="6" tab={ <span className="tab-icons font-weight-bold"><MdKitchen /> DINING</span>  } >
                          <Row className='mt-3 p-2' data-aos="fade-up" data-aos-duration="2000">
                              {dinings}
                          </Row>
                      </TabPane>
                      <TabPane key="7" tab={ <span className="tab-icons font-weight-bold"><FaChair/> OFFICE</span>  } >
                          <Row className='mt-3 p-2' data-aos="fade-up" data-aos-duration="2000">
                              {offices}
                          </Row>
                      </TabPane>
                      
                      <TabPane key="8" tab={ <span className="tab-icons font-weight-bold"><AiOutlineCodeSandbox/> BOX AND PACKING</span>  } >
                          <Row className='mt-3 p-2' data-aos="fade-up" data-aos-duration="2000">
                              {boxes}
                          </Row>
                      </TabPane>
                  </Tabs>
              </Col>
          </Row>
      </>
  )
}

if(loading===true)
      {
        return(
        <div className="loading">
            <BeatLoader loading
              size={20}
              color="#f26c4f" />
            <BarLoader loading 
            css={{display:'block',margin:0}}
            size={40}
            color="#f26c4f"
            />
        </div>
        )
      }
      
const Category=()=>{
        
  if (data.category=== 'Furniture and Other Items')
    return (
      <>
        { Furniture() }
      </>
    )
        
  else if (data.category=== 'Home Removals') 
    return (
      <>
       { HomeRemovals() }
      </>
    )
        
    else if (data.category==='Motorbikes') 
      return (
        <>
         { Motorbikes() }
        </>
      )
        
    else if (data.category === 'Cars and Vehicles') 
      return (
        <>
         { Vehicles() }
        </>
      )
        
    else if (data.category=== 'Pianos') 
      return (
        <>
          { Pianos() }
        </>
      )
        
    else if (data.category ==='Parcels and Packages' || data.category ==='Specialist and Antiques' || data.category ==='Vehicle Parts' || data.category === 'Office Removals') 
      return (
        <>         
          { ParcelsPackages() }
        </>
      )       
  }

    if(loading===false)
    {
      
    return(
    <>
      <Container className='pt-5 pb-5'>
          <br/> <br/>
          {Category()}
          {confirmation()}
      </Container>
    </>
    );
    }
}
export default SurveyPerforma;