import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image'

class SinglePost extends React.Component {
  constructor(props) {
    super(props);
    
    
  }
    render ()
      { 
       

          return(
            <>
               <Container fluid>
                  <Container className='pt-5'>
                  <Row className='pt-5'>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <h2 className="blog-title">{this.props.location.state.item.title}</h2>
                    <br/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Image fluid src= {this.props.location.state.item.bannerImg} width='100%'/>
                    </Col>
                    </Row>
                    <Row className='pt-4 pb-2'>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <p className='text-justify'>{this.props.location.state.item.para1}</p>
                    
                    </Col>
                    
                    </Row>
                    <Row>
                    <Col xs={4} className='p-1'>
                      <Image fluid src= {this.props.location.state.item.otherImage1} className='blog-img'/>
                    </Col>
                    <Col xs={4} className='p-1'>
                      <Image fluid src= {this.props.location.state.item.otherImage2} className='blog-img'/>
                    </Col>
                    <Col xs={4} className='p-1'>
                      <Image fluid src= {this.props.location.state.item.otherImage3} className='blog-img'/>
                    </Col>
                    
                    
                    </Row>
                    <Row className='pb-2'>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <p className='text-justify'>{this.props.location.state.item.para2}</p>
                    <p className='text-justify'>{this.props.location.state.item.para3}</p>
                    </Col>
                    
                    </Row>
                  </Container>
               </Container>
            </>
          )
      }

}

export default SinglePost;
