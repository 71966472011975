import React, { useState, useEffect } from "react";
import { Form, Input, Button,Select,DatePicker  } from 'antd';
import {Row,Col,Container} from 'react-bootstrap';
import { toast, Zoom, Bounce} from 'react-toastify'
import { BarLoader, BeatLoader} from 'react-spinners'
import {useHistory, useParams} from 'react-router-dom';
import AOS from 'aos';

toast.configure()
const VerifyAccount =()=> {
    AOS.init();
  const [loading,setLoading]= useState(false)
  const history = useHistory();
  const {token} = useParams()

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  useEffect(()=>{
    console.log(token)
      fetch("/verifyAccount",{
        method:"post",
        headers:{
          "content-Type":"application/json"
        },
        body:JSON.stringify({
            token
        })
      }).then(res=>res.json())
      .then(data=>{  
        if(data.err==="Incorrect or expired Link")
        {
          toast.success("Incorrect or expired Link",{
            draggable: true,
            hideProgressBar:true,
            transition: Zoom,
            autoClose:7000,
            position: toast.POSITION.TOP_CENTER  
          })
        }
        if(data.err==="Something went wrong!!")
        {
          toast.success("Something went wrong!!",{
            draggable: true,
            hideProgressBar:true,
            transition: Zoom,
            autoClose:7000,
            position: toast.POSITION.TOP_CENTER  
          })
        }
        console.log(data)
        setLoading(false)
        history.push('/')
          toast.success("Account verified successfully",{
            draggable: true,
            hideProgressBar:true,
            transition: Zoom,
            autoClose:7000,
            position: toast.POSITION.TOP_CENTER  
          })
      })
      .catch(e=>{
        toast.success(e,{
          draggable: true,
          hideProgressBar:true,
          transition: Zoom,
          autoClose:7000,
          position: toast.POSITION.TOP_CENTER  
        })
      })
  },[])


         
          // if(loading===true)
          // {
          //   return(
          //   <div className="loading">
          //       <BeatLoader loading
          //         size={20}
          //         color="#f26c4f" />
          //       <BarLoader loading 
          //       css={{display:'block',margin:0}}
          //       size={40}
          //       color="#f26c4f"
          //       />
          //   </div>
          //   )
          // }
          // if(loading===false)
          // {
          return(
            <>
                <Container className='pt-5 pb-5 driver-registration mt-5'>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <h2 className="title1" style={{fontSize:30,marginBottom:10}}>Account Verified Thanks For Your Cooperation</h2><br/>
                        </Col>
                    </Row>
                    
                </Container>
            </>
          )
      // }

}

export default VerifyAccount;
