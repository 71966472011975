import React,{useState, useEffect} from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { toast, Zoom, Bounce} from 'react-toastify'

export default function Mileage() {

  const [price,setPrice] = useState(0)

  const submit =() =>{
    fetch("/updateMileage",{
      method:"post",
      headers:{
        "content-Type":"application/json"
      },
      body:JSON.stringify({
        cost:price,
      })
    }).then(res=>res.json())
    .then((data)=>{
      console.log(data)
      toast.success("Updated Successfully",{
        draggable: true,
        hideProgressBar:true,
        transition: Zoom,
        autoClose:7000,
        position: toast.POSITION.TOP_CENTER  
      })
    })

  }
  
  useEffect(()=>{
    fetch('/getDistanceCost')
        .then(res=>res.json())
        .then((data)=>{
          console.log(data)
          setPrice(data.cost)
          console.log("Distance Data is ",data.cost)
        })
  },[])

  return (
    <>
      <Row className='add-new-advice-form'>
              
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <h1 className='page-title'>Mileage</h1>
          <hr/>
          <Form.Label>Price</Form.Label>
          <Form.Control size="sm" type="text" value={price} onChange={(e)=>setPrice(e.target.value)}/>
          <Col xs={12} >
              <br/>
              <Button className="publish-btn" onClick={submit} >Update</Button>
            </Col>
          </Col>

            </Row>
          
    </>
  )
}
