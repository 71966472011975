import React,{useEffect} from 'react';
import {Col, Container} from 'react-bootstrap'
export default function AntiBribery() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Container className="pt-5 pb-5">
    <Col xs={12}>
      <br />
      <h4 className="title1">Anti-Bribery and Anti-Corruption</h4>

      
      <p>Membership in FIDI is highly valued by its Affiliates, and to ensure that value continues, all FIDI Affiliates pledge to abide by the highest ethical standards.</p>
      <p>This Charter is a declaration of commitment. It will strengthen the FIDI organisation, the FAIM programme, and Excess International Movers by making it clear what distinguishes FIDI Affiliates from non-FIDI companies. Excess International Movers agree to sign and be guided by the Charter’s provisions. The Charter covers our employees (whether permanent, fixed-term or temporary) and any associated third parties providing services to or on behalf of Excess International Movers. The Charter will be integrated into FAIM. The procedural and audit requirements will form part of the FAIM Implementation Manual and the Pre-Audit assessment.</p>
      
      <h4 className="text-blue mt-3">What Is Bribery?</h4>
      <p>Bribery is the offering, promising, giving, accepting or soliciting of an advantage as an inducement for action. It is illegal, and it is a breach of trust. A bribe is an inducement or reward offered, promised or provided in order to gain a commercial, contractual, regulatory or personal advantage.</p>
      
      <h4 className="text-blue mt-3">FIDI Will Not Tolerate Bribery</h4>
      <p>Corruption, bribery or attempted bribery is unacceptable. This applies whether offering a bribe, or accepting a bribe. It is against FIDI’s core values of conducting business to the highest legal, moral and ethical standards. Bribery and corruption are covered by various international laws and statutes. These laws often require companies, including FIDI Affiliates, to have rigorous, pro-active measures in place to detect and prevent corrupt practices.</p>
  
      
      <h4 className="text-blue mt-3">Charter Statement</h4>
      <p>Undertaking by all FIDI Affiliates with immediate effect All FIDI Affiliates commit to legal and ethical behaviour, and to refrain from doing anything that will harm the interests of FIDI, other affiliates, clients, or the industry. FIDI and its Affiliates will take steps to ensure they are fully informed of applicable regulations and will monitor their employees and business partners to ensure full and continual compliance. Legal compliance FIDI Affiliates will ensure that they are aware of all applicable laws countering bribery and corruption in all the jurisdictions in which they operate, and that they will obey and uphold those laws. The laws that apply to particular international business activities include those of the countries in which the activities occur as well as others that - like the US Foreign Corrupt Practices Act (FCPA) and the UK Bribery Act 2010 - govern the international operations of national companies and citizens in respect of their conduct both at home and abroad. We, as a FIDI affiliated company, have to ensure that we are aware of, and are complying with, applicable laws. Ethical behaviour as a demonstration of its commitment, FIDI and its Affiliates pledge to take a zero-tolerance approach to bribery and corruption. At all times, FIDI and its Affiliates will act professionally, fairly and with the utmost integrity in all business dealings and relationships. This will apply wherever they operate. Commitment to the values of FIDI This Charter will be formally integrated into the FAIM quality standard.</p>
      
    </Col>
    </Container>
  );
}
