import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button'
import 'react-toastify/dist/ReactToastify.css';
import Table from 'react-bootstrap/Table'
import AOS from 'aos';
import {FaRegEdit} from 'react-icons/fa';
import Form from 'react-bootstrap/Form'
import { toast, Zoom, Bounce} from 'react-toastify'

toast.configure()

class Piano extends React.Component {
  constructor(props) {
    super(props);
    this.incr=0;
    this.submit = this.submit.bind(this);
  } 
  state={
    data:[],
    prices:[]
  }
  componentDidMount(){
   fetch('/getPiano')
        .then(res=>res.json())
        .then((dat)=>{
            this.setState({
                data:dat
            })
            dat.map(item=>{
              const arr = [...this.state.prices]
              arr.push(item.price)
              this.setState({prices:arr})
              console.log(this.state.prices)
            })
          console.log("jkk...",dat)
        })
    
  }
  submit(){
    const data = [...this.state.data]
    data.map((item,index)=>{
      data[index].price = this.state.prices[index]    
    })
    console.log(data)
    this.setState({
      data:data
    })
    fetch("/updatePiano",{
      method:"post",
      headers:{
        "content-Type":"application/json"
      },
      body:JSON.stringify({
        data:this.state.data
      })
    }).then(res=>res.json())
    .then((data)=>{
      console.log(data)
      toast.success("Updated Successfully",{
        draggable: true,
        hideProgressBar:true,
        transition: Zoom,
        autoClose:7000,
        position: toast.POSITION.TOP_CENTER  
      })
    })
  } 

  updatePrice =(val,index) =>{
    console.log(val.target.value)
    const arr = [...this.state.prices]
    arr[index] = val.target.value
    this.setState({
      prices:arr
    })
  }


    render ()
      { 
        const i=0;
        const details =this.state.data.map((item,index)=>{
          
           
            
            return(
              <tr class="d-flex" key={item._id} >
              <td class="col-1">{index+1}</td>
              <td class="col-9">{item.type}</td>
              <td class="col-2">
                  <Form.Control size="sm" type="text"  onChange={(val)=>this.updatePrice(val,index)} value={this.state.prices[index]} />
              </td>
             </tr>
              
        )})

      
        return(
          <>
          <Row className='admin-forms'>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h1 className='page-title'>Pianos</h1>
              <hr/> 
            </Col>
            <Col className='pt-3' >
            <p className='float-right mt-3 mb-2 text-orange pointer add-new-post-link' onClick={() =>this.props.history.push('/add-piano')}><FaRegEdit/>Add New Piano</p>
                <Table hover size="sm" className='text-left' className="table admin-table" data-aos="fade-up" data-aos-duration="2000">
                  <thead className='bg-orange text-white'>
                    <tr class="d-flex">
                      <th class="col-1">Sr. No</th>
                     
                      <th class="col-9">Brand</th>
                      <th class="col-2  d-flex justify-content-center">Action</th>
                      
                    </tr>
                  </thead>      
                
                <tbody>
                    {details}
                </tbody>
              </Table> 
            </Col>
            <Col xs={12} >
              <br/>
              <Button className="publish-btn" onClick={this.submit} >Update</Button>
            </Col>
          </Row>
        
        </>
        )
        
     
      
      }

}


export default Piano;
