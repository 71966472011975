import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { geocodeByAddress,  geocodeByPlaceId,  getLatLng,  } from 'react-places-autocomplete';

  class PickupLocation extends React.Component
{
    constructor(props) {
        super(props);
        
      }
      state={
       latlng1:'',
       address1:this.props.pickupLoc
    }

    handleChange1 = address1 => 
    {
        this.setState({ address1 });
    };

    handleSelect1 = address1 =>
    {
        this.setState({ address1 })
        geocodeByAddress(address1)
            .then(results => getLatLng(results[0]))
                .then(latLng => {
                    this.setState({
                        latlng1:latLng
        })  
        console.log('Success',this.state.latlng1)})
        .catch(error => console.error('Error', error));
    };
    componentDidMount()
    {
        console.log("addressDisplay",this.props.address1)
        this.setState({
            address1:this.props.address1
        })
    }
    searchOptions = {
    componentRestrictions: { country: ['uk'] },
    //types: ['address']
    }
    render()
    {
        return(
            <PlacesAutocomplete
            value={this.state.address1}
            onChange={this.handleChange1}
            onSelect={this.handleSelect1}
            searchOptions={this.searchOptions}>
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div> 
                        <input {...getInputProps({ placeholder: 'Pickup Location or Postcode',  className: 'location-search-input', })} value={this.state.address1}/>
                            <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                    {suggestions.map(suggestion => 
                                    {
                                        const className = suggestion.active
                                            ? 'suggestion-item--active': 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                ? { backgroundColor: '#ed2232',color:'#fff', cursor: 'pointer' }
                                                    : { backgroundColor: '#00acdd',color:'#fff', cursor: 'pointer' };
                                                return (
                                                            <div
                                                                {...getSuggestionItemProps(suggestion, {
                                                                    className, style, })
                                                                }>
                                                                    
                                                                    <div>{suggestion.description}</div>
                                                            </div>
                                                        );
                                    })}
                            </div>
                    </div>
                )}
        </PlacesAutocomplete>
        )
    }
}
export default PickupLocation;