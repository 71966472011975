import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { Button } from "antd";
import { Route, withRouter } from "react-router-dom";

class Slider extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <div>
          <Carousel className="homepage-slider">
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={require("../img/s1.jpg")}
                alt="First slide"
              />
              <Carousel.Caption>
               
                <p className='text-red'>
                  Move Anything to Anywhere… at the cheapest rates!! Start your
                  moving journey now with the United Kingdom's most convenient
                  transport and removal agency.
                </p>
                <Button
                  onClick={() => this.props.link.push("/categories")}
                  size="large"
                  className="login-btn"
                >
                  Book Now
                </Button>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={require("../img/5.png")}
                alt="First slide"
              />
              <Carousel.Caption>
                <p className='text-red'>
                  Move Anything to Anywhere… at the cheapest rates!! Start your
                  moving journey now with the United Kingdom's most convenient
                  transport and removal agency.
                </p>
                <Button
                  onClick={() => this.props.link.push("/categories")}
                  size="large"
                  className="login-btn"
                >
                  Book Now
                </Button>{" "}
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={require("../img/6.png")}
                alt="First slide"
              />
              <Carousel.Caption>
                <p className='text-red'>
                  Move Anything to Anywhere… at the cheapest rates!! Start your
                  moving journey now with the United Kingdom's most convenient
                  transport and removal agency.
                </p>
                <Button
                  onClick={() => this.props.link.push("/categories")}
                  size="large"
                  className="login-btn"
                >
                  Book Now
                </Button>{" "}
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={require("../img/7.png")}
                alt="First slide"
              />
              <Carousel.Caption>
                <p className='text-red'>
                  Move Anything to Anywhere… at the cheapest rates!! Start your
                  moving journey now with the United Kingdom's most convenient
                  transport and removal agency.
                </p>
                <Button
                  onClick={() => this.props.link.push("/categories")}
                  size="large"
                  className="login-btn"
                >
                  Book Now
                </Button>{" "}
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={require("../img/8.png")}
                alt="First slide"
              />
              <Carousel.Caption>
                <p className='text-red'>
                  Move Anything to Anywhere… at the cheapest rates!! Start your
                  moving journey now with the United Kingdom's most convenient
                  transport and removal agency.
                </p>
                <Button
                  onClick={() => this.props.link.push("/categories")}
                  size="large"
                  className="login-btn"
                >
                  Book Now
                </Button>{" "}
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={require("../img/9.png")}
                alt="First slide"
              />
              <Carousel.Caption>
                <p className='text-red' style={{color:"red !important"}}>
                  Move Anything to Anywhere… at the cheapest rates!! Start your
                  moving journey now with the United Kingdom's most convenient
                  transport and removal agency.
                </p>
                <Button
                  onClick={() => this.props.link.push("/categories")}
                  size="large"
                  className="login-btn"
                >
                  Book Now
                </Button>{" "}
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </>
    );
  }
}
export default Slider;
