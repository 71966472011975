import React,{Component} from 'react';
import {Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
 
export class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.handleMapReady = this.handleMapReady.bind(this);
    this.state={
      mileage:''
    }
  }
  google = window.google
  data = [
    {
      lat: this.props.lat1,
      lng: this.props.lng1
    },
    {
      lat: this.props.lat2,
      lng: this.props.lng2
    }
  ];
  handleMapReady(mapProps, map) {
    this.calculateAndDisplayRoute(map);
  }

  calculateAndDisplayRoute(map) {
    const directionsService = new this.google.maps.DirectionsService();
    const directionsDisplay = new this.google.maps.DirectionsRenderer();
    const distanceMatrix = new this.google.maps.DistanceMatrixService();
    directionsDisplay.setMap(map);


    const waypoints = this.data.map(item =>{
      return{
        location: {lat: item.lat, lng:item.lng},
        stopover: true
      }
    })
    const origin = waypoints.shift().location;
    const destination = waypoints.pop().location;


    directionsService.route({
      origin: origin,
      destination: destination,
      waypoints:waypoints,
      travelMode: 'DRIVING'
    }, (response, status) => {
      if (status === 'OK') {
        directionsDisplay.setDirections(response);
      } else {
        window.alert('Directions request failed due to ' + status);
      }
    });

    distanceMatrix.getDistanceMatrix({
      origins: [origin],
      destinations: [destination],
      travelMode: 'DRIVING',
      avoidHighways: false,
      avoidTolls: false,
      unitSystem: window.google.maps.UnitSystem.IMPERIAL
    }, (response, status) => {
      console.log("Distance status is ", status," Response is ", response)
      if (status === 'OK') {
        console.log(response.rows[0].elements[0].distance.text)
        this.setState({mileage:response.rows[0].elements[0].distance.text})
        this.props.handleMile(response.rows[0].elements[0].distance.text)
      } else {
        window.alert('Directions request failed due to ' + status);
      }
    })
  }

  render() {
    return (
      <>
      <Map google={this.props.google} initialCenter={ {lat: -33.847927,
        lng: 150.6517938}} zoom={14} onReady={this.handleMapReady}>
 
        <Marker onClick={this.onMarkerClick}
                name={'Current location'} />
 
        <InfoWindow onClose={this.onInfoWindowClose}>
            <div >
              {/* <h1>{this.state.selectedPlace.name}</h1> */}
            </div>
        </InfoWindow>
      </Map>
     </>
    );
  }
}
 
export default GoogleApiWrapper({
  apiKey: ("AIzaSyBxP_tL24fzdEqNKA5kicip7vyAExtNdPE")
})(MapContainer)