import React from 'react';
import {Row,Col,Container} from 'react-bootstrap';
import Image from 'react-bootstrap/Image'
import about1 from '../../img/service.png'
import about2 from '../../img/7.jpg'
import img1 from '../../img/leaf.png'
import img2 from '../../img/money.png'
import img3 from '../../img/truck.png'
import img4 from '../../img/smily_face.png'
import Card from 'react-bootstrap/Card'
import CardGroup from 'react-bootstrap/CardGroup'
import CardDeck from 'react-bootstrap/CardDeck'
import CardColumns from 'react-bootstrap/CardColumns'
import AOS from 'aos';
import about3 from "./../../img/price.png";
import about11 from "./../../img/get.png";
import 'aos/dist/aos.css';

class AboutUs extends React.Component {
  constructor(props) {
    super(props);
    AOS.init();

    
  }
    render ()
      {  
          return(
            <>
               <Container className="pt-5 pb-5">
               <Col xs={12}>
              <br />
              <h2 className="title1">About Us</h2>
            
            </Col>
          <Row className="pt-5">
            <div
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
            >
              <Image src={about11} style={{borderRadius:"10px"}} width="100%" />
            </div>

            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              className="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9"
            >
              <h2 className="text-blue">
              Who are we?
              </h2>
              <p className="text-justify">
           
GetMovers is an accomplished removal and storage agency with hundreds of satisfied customers online. It was established by a group of experienced professionals with over 20 years of experience in the said industry. 
We have multiple storage units throughout the United Kingdom, and our partnerships with local clients make getting more affordable rates for your move easy. GetMovers caters to both residential and commercial customers alike. We do everything the standard removal and storage service does, just better and at more affordable rates! 

              </p>
             
            </div>
            <Row>
              <Container className="pt-5 pb-5">
                <Row>
                  <div
                    data-aos="flip-left"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000"
                    className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
                  >
                    <Image src={about1} style={{borderRadius:"10px"}} className="float-right" width="100%" />
                  </div>
                  <div
                    data-aos="fade-up"
                    data-aos-duration="3000"
                    className="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9"
                  >
                    <h2 className="text-blue">Our Story</h2>
                    <p className="text-justify">
                    Here at GetMovers, we realise that the removal and storage industry is in dire need of change. Most, if not, all of the time, the prices for even a small move can be OUTRAGEOUS, and we get that. 
                    </p>
                    <p className="text-justify">One eventful evening, our founding team at GetMovers, finally decided to become the change instead of waiting for it, and the result? Hundreds of satisfied customers online! GetMovers takes immense pride in providing the absolute best removal services at the most affordable rates without compromising on any front. Be it customer service, security of goods or the overall planning of the move, we strive to deliver the best you can think of! </p>
                  
                  </div>
                </Row>
              </Container>
            </Row>
          </Row>
        </Container>

        <Container className="pt-5 pb-5">
          <Row>
            <div
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
              className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
            >
              <Image src={about3} style={{borderRadius:"10px"}} width="100%" />
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              className="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9"
            >
              <h2 className="text-blue">How much can a removal with GetMovers cost?</h2>
              <p className="text-justify">
              Every move is made up of unique factors in its simple way. Each of these factors, such as the pickup floor, the number of items or the nature of the items, can affect the total cost of the move. For more accurate pricing information specific to your relocation, we’d recommend getting a few quotes or contacting us directly at (0800 6358888).
              </p>
             
            </div>
          </Row>
        </Container>
            </>
          )
      }

}

export default AboutUs;
